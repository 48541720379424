import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AsideNav from "./root/AsideNav";
import { IoIosLogOut } from "react-icons/io";
import { toast } from "react-toastify";
import "./AgencyDashboard.css"
const AgencyDashboard = () => {
  const move = useNavigate();
  const logoutHandle = () => {
    toast.success("Logout Successfull", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    localStorage.removeItem("sundorbonBookingUserDetails");
    localStorage.removeItem("token");
    move("/");
  };
  return (
    <div className="bg-[#FFFDE7] ">
      <button onClick={logoutHandle} className="flex absolute z-30 top-3 right-5 items-center gap-2 font-medium lg: mt-4 text-lg bg-gradient-to-tr from-[#6D4C41] rounded-[8px] h-[47px] px-3 to-[#424242] text-white">
        Logout <IoIosLogOut className="text-xl" />
      </button>
      <div className="min-h-screen lg:pt-6 gap-x-[50px] flex lg:flex-row flex-col items-start">
        
          <AsideNav></AsideNav>
        
        <div className="w-full relative z-10 lg:w-[80%] pt-[100px] lg:px-[0px] min-h-screen">
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
};

export default AgencyDashboard;

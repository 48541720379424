import React, { useState } from "react";
import "./Login.css";
import TextAreaComponents from "../sharedComponents/TextAreaComponents/TextAreaComponents";
import { ToastContainer, toast } from "react-toastify";
import { loginApi } from "../../api/loginApi/LoginApi";
import "react-toastify/dist/ReactToastify.css";
import LoginBanner from "../../assets/images/message.jpg"
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from "axios";
// import { jwtDecode } from "jwt-decode";
import { default as jwtDecode } from 'jwt-decode'; 

const Login = () => {
  const changeRoute = useNavigate()
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const UserName = form.userName.value;
    const Password = form.password.value;
    // console.log("form: ", form, UserName, Password);

    setUserName(UserName);
    setPassword(Password);

    var isvalid = true;
    if (userName == "" || userName == undefined) {
      toast.error("User name is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      isvalid = false;
    }
    if (password == "" || password == undefined) {
      toast.error("Password is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      isvalid = false;
    }
    if (isvalid) {
      var res = await loginApi(userName, password, 1);
      if (res == "User Not Found") {
        toast.error(res, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.success("Login Successfull", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        sessionStorage.setItem("sundorbonToken", res);
      }
    }
  };

  // login .
  // const apiAddress = process.env.REACT_APP_API_URL;

  // const loginHandle = (e) => {
  //   e.preventDefault()
  //   const form = e.target
  //   const userName = form.userName.value
  //   const password = form.password.value
  //   const userType = form.userType.value
   


  //   axios.get(`${apiAddress}/api/Login?userName=${userName}&password=${password}&userType=${userType}`)
  //     .then(res => {
  //       const{userId}=res.data
  //       if(!userId){
          
  //         console.log(" show error")
  //         toast.error("Invalid user credentials!", {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //       }else{
  //       localStorage.setItem("userId",userId)
  //       if(userType==="User"){
  //         changeRoute("/user-dashboard")
  //       } else{
  //         changeRoute("/dashboard")
  //       }
  //     }
  //       // console.log(userId," form fornt bo back.")
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // }

  const apiAddress = process.env.REACT_APP_API_URL;

  const loginHandle = (e) => {
    e.preventDefault();
    const form = e.target;
    const userName = form.userName.value;
    const password = form.password.value;
   

    axios
  .post(
    `https://bookingrolesandpermissions.azurewebsites.net/api/UserLogin/loginAsync?userName=${userName}&password=${password}`
  )
  .then((res) => {
    console.log(res.data)
    const { token } = res.data;
console.log(res.data,"response saiful.")
    console.log(res);
    console.log('Data: ' + token);
    localStorage.setItem('token', token);

    const decodedToken = jwtDecode(token);
    const userTypeFromToken = decodedToken.RoleName; 
    const userIdFromToken = decodedToken.UserId; 
    const userEmailFromToken = decodedToken.UserEmail; 
    console.log(userTypeFromToken);
    console.log('User ID: ' + userIdFromToken);

    const userDetails = {
      Id: userIdFromToken,
      Name: userName,
      Type: userTypeFromToken,
      Email:userEmailFromToken
    };
    localStorage.setItem(
      "sundorbonBookingUserDetails",
      JSON.stringify(userDetails)
    );
    toast.success("Login Successful.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
   
  });
    if (userTypeFromToken === "User") {
      changeRoute("/user-dashboard");
    } else if (userTypeFromToken === "Agent") {
      changeRoute("/agent-dashboard");
    } else if (userTypeFromToken === "Admin") {
      changeRoute("/admin-dashboard");
    }
  })
  .catch((err) => {
    toast.error("Invalid User Name Or Password.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  });
};

  return (
    <div>
      <ToastContainer />
      {/* <div className="loginCard">
        <div className="">
          <TextAreaComponents
            id={6}
            label={"User Name"}
            placeholder={"User Name"}
            value={(props) => {
              setUserName(props);
            }}
          />
        </div>
        <div className="" style={{ marginTop: "1rem" }}>
          <TextAreaComponents
            id={7}
            label={"Password"}
            placeholder={"Password"}
            value={(props) => {
              setPassword(props);
            }}
          />
        </div>
        <div style={{ marginTop: "1rem" }}>
          <button
            className="btn btn-login w-100"
            onClick={() => {
              onSubmit();
            }}
          >
            Login
          </button>
        </div>
      </div> */}

      <div className="flex">
        <div className="image-container relative">
          <div className="w-full h-full absolute top-0 left-0 bg-gradient-to-r from-[#353535bb] to-[#0000002f]"></div>
          <img src={LoginBanner} alt="loginImage" />
        </div>

        <div className="form-container relative">
          <div className="mx-auto text-center mt-16">
            <h1 className="text-4xl font-semibold">Login</h1>
            <div className="mt-3">Don't have an account? <Link to="/signup" className="text-blue-500">Signup</Link></div>
          </div>

          <form className="flex flex-col justify-center items-center absolute" onSubmit={loginHandle}>
            <div className="flex flex-col  w-full">
              <label className="text-gray-400 mb-2">User Name</label>
              <input required type="text" placeholder="User Name" name="userName" />
            </div>


            <div className="flex flex-col w-full my-7">
              <label className="text-gray-400 mb-2">Password</label>
              <div className='flex items-center password-field' title={showPassword ? "Hide password" : "Show password"}>
                <input required type={showPassword ? "text" : "password"} placeholder="Password" name="password" />
                <div onClick={() => setShowPassword(!showPassword)} className="cursor-pointer">
                  {
                    showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />
                  }
                </div>
              </div>
            </div>
{/* Admin, Agent, Client */}
            {/* <div className="flex flex-col w-full mb-6">
              <label className="text-gray-400 mb-2">User Type</label>
              <select className="text-white focus:outline-none bg-[#2a2a3c] py-2" name="userType" >
                <option value="" selected hidden disabled>Select one</option>
                <option className="bg-gray-800" value="User">User</option>
                <option className="bg-gray-800" value="Agent">Agent</option>
                <option className="bg-gray-800" value="Client">Client</option>
              </select>
            </div> */}

            <input type="submit" value="Login" className="w-full" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useContext, useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";
import formateText from "../../../utils/Formate";
import { MdPreview } from "react-icons/md";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

const HistoryTable = ({
  headers,
  fetchedData,
  clearActiveRow,
  row,
  editCallBack,
  notEditAble,
  noSearch,
  extraFields,
  notSearchAble,
  small,
}) => {
  const {
    companys,
    isAllDataFetched,
    branchs,
    measurementUnits,
    devices,
    cargoTypes,
    routes,
    functions,
    agent,
    boxName,
    measurementUnit,
    device,
    scanningPoint,
    scanningPerson,
    parcelStatus,
    allUser,
  } = useContext(contextProvider);

  // main array of object.
  const [data, setData] = useState(null);

  // set array of object data.
  useEffect(() => {
    if (fetchedData) setData(fetchedData);
  }, [fetchedData]);

  const typeValidation = (field, fetchedObject) => {
    if (
      !fetchedObject[field] &&
      field !== "boxAssignPreviousMonth" &&
      field !== "limitCurrentMonth" &&
      field !== "boxAssignCurrentMonth" &&
      field !== "limitPreviousMonth" &&
      field !== "isAgent" &&
      field !== "boxQty" &&
      field !== "isHub" &&
      field !== "isOffice" &&
      field !== "isActive" &&
      field !== "isExpressService" &&
      field !== "receiveQty" &&
      field !== "adjustmentQty" &&
      field !== "issueQty"
    ) {
      return <span className="text-red-400">Not Available</span>;
    }

    if (field === "isActive" && fetchedObject[field] === true) return "Active";
    if (field === "isActive" && fetchedObject[field] === false)
      return "Inactive";

    if (
      (field === "isExpressService" ||
        field === "isHub" ||
        field === "isOffice" ||
        field === "isAgent") &&
      fetchedObject[field] === true
    )
      return "Yes";
    if (
      (field === "isExpressService" ||
        field === "isHub" ||
        field === "isOffice" ||
        field === "isAgent") &&
      fetchedObject[field] === false
    )
      return "No";

    if (field === "parcelStatusId") {
      let data = parcelStatus?.find(
        (item) => item.id === fetchedObject[field]
      )?.parcelStatusName;

      if (!data) return <span className="text-red-400">Not Available</span>;
      return data;
    }
    if (field === "scanningPersonId") {
      let data = scanningPerson?.find(
        (item) => item.userId === fetchedObject[field]
      )?.userName;
      if (!data) return <span className="text-red-400">Not Available</span>;
      return data;
    }
    if (field === "scanningPointId") {
      let data = scanningPoint?.find(
        (item) => item.id === fetchedObject[field]
      )?.scanningPointName;
      if (!data) return <span className="text-red-400">Not Available</span>;
      return data;
    }
    if (field === "measurementUnitId") {
      let data = measurementUnit?.find(
        (item) => item.id === fetchedObject[field]
      )?.unitDescription;
      if (!data) return <span className="text-red-400">Not Available</span>;
      return data;
    }
    if (field === "deviceId") {
      let data = device?.find(
        (item) => item.id === fetchedObject[field]
      )?.deviceName;
      if (!data) return <span className="text-red-400">Not Available</span>;
      return data;
    }
    if (field === "dimensionId") {
      let data = boxName?.find(
        (item) => item.id === fetchedObject[field]
      )?.dimensionName;
      if (!data) return <span className="text-red-400">Not Available</span>;
      return data;
    }
    if (field === "agentId" || field === "transportAgentId") {
      let data = agent?.find((item) => item.id === fetchedObject[field])?.name;
      if (!data) return <span className="text-red-400">Not Available</span>;
      return data;
    }
    if (field === "companyId") {
      let data = companys?.find(
        (item) => item.id === fetchedObject[field]
      )?.name;
      if (!data) return <span className="text-red-400">Not Available</span>;
      return data;
    }
    if (field === "routeId") {
      let data = routes?.find(
        (item) => item.id === fetchedObject[field]
      )?.routeName;
      if (!data) return <span className="text-red-400">Not Available</span>;
      return data;
    }
    if (field === "cargoId") {
      let data = cargoTypes?.find(
        (item) => item.id === fetchedObject[field]
      )?.cargoTypeName;
      if (!data) return <span className="text-red-400">Not Available</span>;
      return data;
    }
    if (field === "MeasurementUnitId") {
      // need to update.
      let data = measurementUnits?.find(
        (item) => item.id === fetchedObject[field]
      )?.cargoTypeName;
      if (!data) return <span className="text-red-400">Not Available</span>;
      return data;
    }
    if (field === "issuedBy") {
      // need to update.
      let data = allUser?.find(
        (item) => item.id === fetchedObject[field]
      )?.roleName;

      if (!data) return <span className="text-red-400">Not Available</span>;
      return data;
    }
    if (
      field === "startDate" ||
      field === "paymentDate" ||
      field === "issueDate" ||
      field === "transactionDate"
    ) {
      const date = new Date(fetchedObject[field]);

      let data = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;
      if (!data) return <span className="text-red-400">Not Available</span>;
      return data;
    }
    if (field === "endDate") {
      const date = new Date(fetchedObject[field]);
      let data = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;
      if (!data) return <span className="text-red-400">Not Available</span>;
      return data;
    }
    if (field === "discountedDate") {
      const date = new Date(fetchedObject[field]);
      let data = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;
      if (!data) return <span className="text-red-400">Not Available</span>;
      return data;
    }

    return fetchedObject[field];
  };

  const [activeRow, setActiveRow] = useState(null);

  useEffect(() => {
    if (clearActiveRow) setActiveRow(null);
  }, [clearActiveRow]);

  useEffect(() => {
    setActiveRow(null);
  }, [fetchedData]);

  // pageination logics. every page have 10 row.

  // per page data limit.
  const limit = 10;

  // get array of pagination page number.
  const getbuttonNumbersArray = (start, end) => {
    const numbersArray = [];
    for (let i = start; i <= end; i++) {
      numbersArray.push(i);
    }
    return numbersArray;
  };

  // split data.
  const splitData = (pageNo) => {
    const startingIndex = (pageNo - 1) * limit;
    const endingIndex = pageNo * limit;
    const splitedData = fetchedData?.slice(startingIndex, endingIndex);
    setData(splitedData);
  };

  // call array spliting function.
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    splitData(pageNo);
  }, [pageNo, fetchedData]);

  const totalPaginationPage = Math.ceil(fetchedData?.length / limit);
  const [PaginationButtonNumbers, setPaginationButtonNumbers] = useState([]);

  const [reArange, setRearange] = useState(false);
  useEffect(() => {
    if (totalPaginationPage < 4)
      return setPaginationButtonNumbers(
        getbuttonNumbersArray(1, totalPaginationPage)
      );
    return setPaginationButtonNumbers(getbuttonNumbersArray(1, 4));
  }, [fetchedData, reArange]);

  const skipPaginationNumber = (start, end) => {
    setPaginationButtonNumbers(getbuttonNumbersArray(start, end));
  };

  // modal controll.
  const [showModal, setShowMOdal] = useState(null);
  const [modalData, setModalData] = useState(null);

  const TableSearchHandle = (e) => {
    e.preventDefault();
    const searchAbleText = e.target.searchText.value;
    if (!fetchedData) return;

    const isInclued = (values) => {
      for (let item of values) {
        if (item !== null && item !== undefined) {
          const preferedItem = item.toString().toLowerCase();
          const preferedSearchText = searchAbleText.toString().toLowerCase();
          if (preferedItem.includes(preferedSearchText)) return true;
        }
      }
      return false;
    };

    const filteredData = fetchedData.filter((item) => {
      const itemValuesArray = Object.values(item);
      return isInclued(itemValuesArray);
    });

    setData(filteredData);
  };

  // clear search .
  const clearSearch = () => {
    setData(fetchedData);
    setSearchText("");
  };

  const [searchText, setSearchText] = useState("");

  return (
    <div className={small ? "scale-95 text-end" : "text-end"}>
      {!notSearchAble && (
        <form
          onSubmit={TableSearchHandle}
          className={
            noSearch
              ? "hidden"
              : "lg:w-[30%] w-full inline-flex items-center gap-x-2 justify-end mb-4"
          }
        >
          <input
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            required
            className="w-1/2 lg:w-full border-2 lg:border-[3px] pl-2  border-[#83430D] h-12 outline-none rounded-lg bg-transparent"
            type="text"
            name="searchText"
            placeholder="You text here"
          />
          <button
            className={`font-medium  text-lg bg-gradient-to-tr from-[#6D4C41] to-[#424242] rounded-[8px] h-[47px] px-6  text-white`}
          >
            Search
          </button>
          <button
            onClick={clearSearch}
            type="button"
            className={`font-medium  text-lg bg-gradient-to-tr from-[#6D4C41] to-[#424242] rounded-[8px] h-[47px] px-6  text-white`}
          >
            Clear
          </button>
        </form>
      )}
      <div className=" w-full  overflow-x-auto rounded-lg overflow-hidden bg-[#fceee5]">
        <div className="">
          <table className="w-full">
            <thead className="">
              <tr className="  w-full bg-[#f5cfb5] text-lg text-center">
                {!notSearchAble && <th className="py-4 px-4">S/N</th>}
                {headers?.map((item) => (
                  <th key={item} className=" py-4 px-4  w-max">
                    <span className="w-max inline-block">
                      {formateText(item)}
                    </span>
                  </th>
                ))}
                {!notSearchAble && (
                  <th className={notEditAble ? "hidden" : "py-4 px-12"}></th>
                )}
              </tr>
            </thead>
            <tbody>
              {isAllDataFetched &&
                data?.map((item, index) => {
                  // return tr.
                  const fetchedObject = item;

                  const editFuncData = {
                    id: fetchedObject?.id,
                    modificationDate: new Date(),
                  };
                  extraFields?.forEach(
                    (field) => (editFuncData[field] = fetchedObject[field])
                  );

                  const td = row?.map((field, idx) => {
                    editFuncData[field] = fetchedObject[field];

                    return (
                      <td
                        key={idx}
                        className="text-center font-semibold  py-3 px-4 w-max"
                      >
                        <span className="w-max inline-block">
                          {typeValidation(field, fetchedObject)}
                        </span>
                      </td>
                    );
                  }); // return td.

                  return (
                    <tr
                      key={index}
                      className={
                        activeRow === index
                          ? "bg-[#ee9d67]"
                          : index % 2 !== 0
                          ? "bg-[#fce8db] "
                          : "bg-[#fcf4ef]"
                      }
                    >
                      {!notSearchAble && (
                        <td className="text-center py-3 font-semibold">
                          {(pageNo - 1) * limit + (index + 1)}
                        </td>
                      )}

                      {td}
                      <td className="flex justify-center items-center gap-2">
                        <button
                          className={
                            notEditAble
                              ? "hidden"
                              : "text-center py-3 font-semibold flex justify-center items-center text-xl text-[#c26040]"
                          }
                          onClick={() => {
                            editCallBack(editFuncData);
                            setActiveRow(index);
                          }}
                        >
                          <FiEdit />
                        </button>

                        <button
                          className={
                            notEditAble
                              ? "hidden"
                              : "text-center py-3 font-semibold flex justify-center items-center text-xl text-[#c26040]"
                          }
                          onClick={() => {
                            // editCallBack(editFuncData);
                            setActiveRow(index);
                          }}
                        >
                          <MdPreview />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className={
          fetchedData?.length <= limit
            ? "hidden"
            : "flex items-center gap-x-1 m-auto justify-center mb-11 mt-4"
        }
      >
        <button
          onClick={() => {
            if (
              pageNo === PaginationButtonNumbers[0] &&
              (pageNo < totalPaginationPage) & (pageNo > 1)
            ) {
              if (totalPaginationPage >= pageNo - 4 && pageNo - 4 >= 1)
                skipPaginationNumber(pageNo - 4, pageNo);
              else if (1 > pageNo - 4) skipPaginationNumber(1, pageNo);
            }
            if (1 < pageNo) {
              setPageNo((prev) => prev - 1);
            }
          }}
          className={
            "font-medium  text-base bg-gradient-to-tr from-[#6D4C41] to-[#424242] rounded-[3px]  px-4 py-[10px] text-white"
          }
        >
          <MdOutlineArrowBackIosNew className="text-xl" />
        </button>

        <button
          onClick={() => {
            if ((pageNo <= totalPaginationPage) & (pageNo > 1)) {
              if (totalPaginationPage >= pageNo - 4 && pageNo - 4 >= 1) {
                skipPaginationNumber(pageNo - 4, pageNo);
                setPageNo((prev) => prev - 4);
              } else if (1 > pageNo - 4) {
                skipPaginationNumber(1, pageNo);
                setPageNo((prev) => prev - (pageNo - 1));
              }
            }
          }}
          className={"font-medium  text-2xl  rounded-[3px]  px-4 text-black"}
        >
          ...
        </button>

        {PaginationButtonNumbers?.map((item) => {
          return (
            <button
              onClick={() => setPageNo(item)}
              className={`font-medium  text-base bg-gradient-to-tr ${
                pageNo === item
                  ? "from-[#b9b89b] to-[#b3b3ae]"
                  : "from-[#6D4C41] to-[#424242]"
              } rounded-[3px]  px-5 py-2 text-white`}
              key={item}
            >
              {item}
            </button>
          );
        })}

        <button
          onClick={() => {
            if (pageNo < totalPaginationPage) {
              if (totalPaginationPage >= pageNo + 4) {
                skipPaginationNumber(pageNo, pageNo + 4);
                setPageNo((prev) => prev + 4);
              } else if (totalPaginationPage < pageNo + 4) {
                skipPaginationNumber(pageNo, totalPaginationPage);
                setPageNo((prev) => prev + totalPaginationPage - pageNo);
              }
            }
          }}
          className={"font-medium  text-2xl  rounded-[3px]  px-4 text-black"}
        >
          ...
        </button>
        <button
          onClick={() => {
            if (
              pageNo ===
                PaginationButtonNumbers[PaginationButtonNumbers.length - 1] &&
              pageNo < totalPaginationPage
            ) {
              if (totalPaginationPage >= pageNo + 4)
                skipPaginationNumber(pageNo, pageNo + 4);
              else if (totalPaginationPage < pageNo + 4)
                skipPaginationNumber(pageNo, totalPaginationPage);
            }
            if (totalPaginationPage > pageNo) {
              setPageNo((prev) => prev + 1);
            }
          }}
          className={
            "font-medium  text-base bg-gradient-to-tr from-[#6D4C41] to-[#424242] rounded-[3px]  px-4 py-[10px] text-white"
          }
        >
          <MdOutlineArrowForwardIos className="text-xl" />
        </button>
      </div>

      {/* You can open the modal using document.getElementById('ID').showModal() method */}
      {/* <button
        className="btn"
        onClick={() => document.getElementById("my_modal_3").showModal()}
      >
        open modal
      </button> */}
      <dialog id="my_modal_3" className="modal">
        <div className="modal-box">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <h3 className="font-bold text-lg">Hello!</h3>
          <p className="py-4">Press ESC key or click on ✕ button to close</p>
        </div>
      </dialog>
    </div>
  );
};

export default HistoryTable;

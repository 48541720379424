import React, { useContext, useEffect, useState } from "react";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupTittle from "../SharedComponents/SetupTittle";
import Box from "../SharedComponents/Box";
import SetupInput from "../SharedComponents/SetupInput";
import SetupDropDown from "../SharedComponents/SetupDropdown";
import SetupRadio from "../SharedComponents/SetupRadio";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import HistoryTable from "../SharedComponents/HistoryTable";
import SetupAutoComplete from "../SharedComponents/SetupAutoComplete";
import axios from "axios";
import Swal from "sweetalert2";
import GetLocalStorageUserId from "../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";

const UserScan = () => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(`${backendUrl}ScanningPoint`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // get local storage data.
  const localdata = localStorage.getItem("sundorbonBookingUserDetails");
  const localStorageObj = JSON.parse(localdata);


  // input fields handle.
  const initialValue = {
    userId: localStorageObj?.Id,
    name: "",
    scanningPointName: "",
    parcelStatusId:"",
    parcelStatusName:"",
    deviceId: "",
    isActive: "",
    isAgent: localStorageObj.Name==="Admin"?true:false,
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    branchId: 0,
    branchName: "string",
    subBranchId: 0,
    subBranchName: "",
    userName: "string",
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };
  const { handleDataReload } = useContext(contextProvider);
  const formHandle = (e) => {
    e.preventDefault();
    console.log(formData ,"thisi is form data....");

    if (!willUpdate) {
      axios
        .post(`${backendUrl}ScanningPoint`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            Swal.fire({
              title: `Scanning Point Created Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err.message));
    }

    if (willUpdate) {
      console.log(formData);
      axios
        .put(`${backendUrl}ScanningPoint/${formData.id}`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 204) {
            Swal.fire({
              title: `Scanning Point Updated Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err.message));
    }
  };

  return (
    <div>
      <Box top>
        <SetupTittle text="Scanning Point" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              {/* <SetupInput
                name="Name"
                type="text"
                placeHolder="Name"
                allTimeValue={formData.name}
                valueUpdate={(e) => update({ name: e.target.value })}
              /> */}
              <SetupAutoComplete
                valueUpdate={(_, value) => {
                  update({ name: value?.userName });
                }}
                queryField="userName"
                placeHolder="Name"
                dataOf="employee"
                allTimeValue={formData.name}
              />
              <SetupInput
                name="ScanningPointName"
                type="text"
                placeHolder="Scanning point name"
                allTimeValue={formData.scanningPointName}
                valueUpdate={(e) =>
                  update({ scanningPointName: e.target.value })
                }
              />
              <SetupDropDown
                dataOf="device"
                allTimeValue={formData.deviceId}
                valueUpdate={(e) => update({ deviceId: e.target.value })}
                placeHolder="Device name"
                name="DeviceId"
              ></SetupDropDown>
              <SetupDropDown // need to change
                dataOf="branch"
                allTimeValue={formData.branchId}
                valueUpdate={(e) => update({ branchId: e.target.value })}
                placeHolder="Branch"
                name="branchId"
              ></SetupDropDown>
              <SetupAutoComplete
                valueUpdate={(_, value) => {
                  update({ subBranchId: value.id });
                }}
                queryField="id"
                placeHolder="Sub-Branch"
                dataOf="subBranch"
                allTimeValue={formData.subBranchId}
              />

              <SetupAutoComplete
                valueUpdate={(_, value) => {
                  update({ parcelStatusId: value.id,parcelStatusName:value.parcelStatusName });
                }}
                queryField="id"
                placeHolder="Parcel Status Name"
                dataOf="parcelStatus"
                allTimeValue={formData.parcelStatusId}
              />

              <SetupRadio
                name="isActive"
                placeHolder="Scanning Point status?"
                allTimeValue={formData.isActive}
                valueUpdate={(data) => update({ isActive: data })}
              />
            </div>
            <SubmitBtn text={willUpdate ? "Update" : "Create"} />
            <SubmitBtn
              onClick={() => {
                setWillUpdate(false);
                update(initialValue);
              }}
              styleClass={!willUpdate && "hidden"}
              text="Cancel"
            />
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            editCallBack={(data) => {
              update(data);
              setWillUpdate(true);
            }}
            fetchedData={fetchedData}
            headers={[
              "Name",
              "Scanning point name",
              "Device name",
              "Branch",
              "Sub-Branch",
              "parcel Status Name",
              "Agent",
              "Status",
            ]}
            extraFields={["branchId", "subBranchId","parcelStatusId"]}
            row={[
              "name",
              "scanningPointName",
              "deviceId",
              "branchName",
              "subBranchName",
              "parcelStatusName",
              "isAgent",
              "isActive",
            ]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default UserScan;

import "./PaymentForm.css";
import FormWrapper from '../FormWrapper/FormWrapper';
import DropDown from "../../Shared_components/DropDown";

const PaymentMethod = ({
    paymentType,
    updateFields,
    fetchedFormData
}) => {

    // const paymentMethod=[
    //     "Credit Card","Visa Card","Master Card","Cash"
    // ]

    return (
        <FormWrapper title="Payment Method">

            <div className=' w-full lg:w-[40%] mx-auto flex justify-center items-center'>
                <DropDown required={true} alltimeValue={paymentType} valueUpdate={e => updateFields({ paymentTypeId: e.target.value })} placeHolder={"Select Payment Method"}>
                    { fetchedFormData?.paymentMethod?.map(item => <option value={item?.id} key={item}>{item?.name}</option>)}
                </DropDown>
            </div>


        </FormWrapper>
    );
};

export default PaymentMethod;
import React from "react";
import SetupTittle from "../SharedComponents/SetupTittle";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupInput from "../SharedComponents/SetupInput";
import SetupRadio from "../SharedComponents/SetupRadio";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import Box from "../SharedComponents/Box";

const BarcodeDetails = () => {

  const formHandle=(e)=>{
    e.preventDefault()
    const form=e.target
    const BarcodestatusId=form.barcodeStatusId.value
    const ScannerPersonId=form.scannerPersonId.value
    const IsActive=form.isActive.value
    console.log({BarcodestatusId,ScannerPersonId,IsActive})
  }

    return (
        <div>
      <Box top>
        <SetupTittle text="Barcode status details" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupInput
                name="barcodeStatusId"
                type="text"
                placeHolder="Barcode status id"
              />
              <SetupInput
                name="scannerPersonId"
                type="text"
                placeHolder="Scanner person id"
              />
             
              <SetupRadio
                name="isActive"
                options={["Yes", "No"]}
                placeHolder="Barcode status?"
              />
            </div>
            <SubmitBtn text="Create" />
          </form>
        </SetupContainer>
      </Box>
    </div>
    );
};

export default BarcodeDetails;
import React from "react";
import { PieChart , Pie, Sector, Cell } from "recharts";
import formateText from "../../../../utils/Formate";

const PieChartComponenet = ({data,tittle}) => {
  
  const COLORS = [];

  data?.forEach(item=>COLORS.push(item.color))


  const RADIAN = Math.PI / 180;
  const customizedLevel=({ cx, cy, midAngle, innerRadius, outerRadius, percent, index })=>{
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
  }
 
  return (
    <div className="h-full shadow-xl  p-[20px]  w-full  border-[#FE7F29] bg-gradient-to-tr from-[#E6F7F3] to-[#FAEBE9] rounded-[23px]">
      <h1  className="text-[24px] font-normal ">{formateText(tittle)}</h1>
      <div className="flex justify-center items-center flex-col">
      <PieChart  width={300} height={300}>
        <Pie
          data={data}
          innerRadius={0}
          outerRadius={120}
          fill="#8884d8"
          label={customizedLevel}
          paddingAngle={0}
          dataKey="value"
        >
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      <div className=" min-h-16 w-full flex justify-start flex-col gap-4">
        
        {
          data?.map((item,idx)=>{
            return <div className="gap-x-5 flex items-center justify-start"><span style={{background:item.color}} className={`w-[20px]  h-[20px] rounded-full inline-block`}></span><span className="text-base font-bold">{formateText(item.name)} ({item.value})</span></div>
          })
        }
        
      </div>
      </div>
    </div>
  );
};

export default PieChartComponenet;

import React from "react";
import formateText from "../../../utils/Formate";

const SetupInput = ({
  placeHolder,
  name,
  type,
  allTimeValue,
  valueUpdate,
  disabled,
}) => {
  const defaultVAlueFormate = (data) => {
    if (!allTimeValue) return "";
    if (type === "date") return data;

    if (type === "date") {
      const DateObj = new Date(data);
      return `${DateObj.getFullYear()}/${
        DateObj.getMonth() + 1
      }/${DateObj.getDate()}`;
    }
    return data;
  };

  return (
    <div className=" w-full min-h-20 border-black">
      <label className=" font-semibold" htmlFor={placeHolder}>
        {formateText(placeHolder)}
      </label>

      <input
        disabled={disabled}
        value={defaultVAlueFormate(allTimeValue)}
        required
        min={1}
        maxLength={name==="barcodeNumber"?16:2147483647}
        type={type}
        id={placeHolder}
        onChange={valueUpdate}
        name={name}
        className="w-full border-2 lg:border-[3px] pl-2 mt-3 border-[#83430D] h-12 outline-none rounded-lg bg-transparent"
      />
    </div>
  );
};

export default SetupInput;

import React from 'react';
import formateText from '../../../../utils/Formate';

const AdminPageDataTable = ({
    headers,
    fetchedData,
    row,
    notEditAble,
    extraFields
  }) => {


    const typeValidation = (field, fetchedObject) => {
       
        if (field === "discountedDate") {
          const date = new Date(fetchedObject[field]);
          return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        }
    
        return fetchedObject[field];
      };
    return (
        <div className=" w-full  overflow-x-auto bg-[#fceee5] ">
        <div className="  rounded-[8px] ">
          <table className=" w-full">
            <thead>
              <tr className="  w-full bg-[#f5cfb5] text-lg text-center">
                <th className="py-4 px-4">S/N</th>
                {headers?.map((item) => (
                  <th key={item} className=" py-4 px-4  w-max">
                    <span className="w-max inline-block">
                      {formateText(item)}
                    </span>
                  </th>
                ))}
                
              </tr>
            </thead>
            <tbody>
              {
                fetchedData?.map((item, index) => {
                  // return tr.
                  const fetchedObject = item;

                  const editFuncData = {
                    id: fetchedObject.id,
                    modificationDate: new Date(),
                  };
                  extraFields?.forEach(field=>editFuncData[field] = fetchedObject[field])

                  const td = row?.map((field, idx) => {
                    editFuncData[field] = fetchedObject[field];

                    return (
                      <td
                        key={idx}
                        className="text-center font-semibold  py-3 px-4 w-max"
                      >
                        <span className="w-max inline-block">
                          {typeValidation(field, fetchedObject)}
                        </span>
                      </td>
                    );
                  }); // return td.

                  return (
                    <tr
                      key={index}
                      className={ index % 2 !== 0
                          ? "bg-[#fce8db] "
                          : "bg-[#fcf4ef]"
                      }
                    >
                      <td className="text-center py-3 font-semibold">
                        { (index + 1)}
                      </td>
                      {td}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
};

export default AdminPageDataTable;
import React from 'react';
import formateText from '../../../utils/Formate';

const SetupTittle = ({text}) => {
    return (
        <h1 className="text-5xl font-bold  mb-[64px]">{formateText(text)}</h1>
    );
};

export default SetupTittle;

import React, { useEffect, useState } from "react";
import { SlCalender } from "react-icons/sl";
import { MdOutlineTextFields } from "react-icons/md";
import { LiaSortAmountUpAltSolid } from "react-icons/lia";
import { GoGitPullRequest } from "react-icons/go";
import { FaCommentAlt } from "react-icons/fa";
import { LuTicket } from "react-icons/lu";
import formateText from "../../../../utils/Formate";
import { IoLocationSharp } from "react-icons/io5";
import { HiPhone } from "react-icons/hi2";
import { MdEmail } from "react-icons/md";
import { BsBank } from "react-icons/bs";
import { MdOutlinePriceChange } from "react-icons/md";
import { MdCallReceived } from "react-icons/md";
import { GoArrowUpRight } from "react-icons/go";
import { FaUserTie } from "react-icons/fa6";
import { MdNumbers } from "react-icons/md";
import { FaFileInvoice } from "react-icons/fa6";
import { MdOutlinePayments } from "react-icons/md";
import { CiBarcode } from "react-icons/ci";
import { TbLocation } from "react-icons/tb";
import { IoMdPerson } from "react-icons/io";
import { GrInProgress } from "react-icons/gr";
import { BsCreditCard2FrontFill } from "react-icons/bs";


const AgencyDashboardInput = ({
  placeHolder,
  name,
  type,
  allTimeValue,
  valueUpdate,
  iconType,
  disabled,
}) => {
  const iconFormate = () => {
    if (iconType === "requisition") return <GoArrowUpRight />;
    if (iconType === "receiver") return <FaUserTie />;
    if (iconType === "receive") return <MdCallReceived />;
    if (iconType === "name") return <MdOutlineTextFields />;
    if (iconType === "quantity") return <LiaSortAmountUpAltSolid />;
    if (iconType === "price") return <MdOutlinePriceChange />;
    if (iconType === "date") return <SlCalender />;
    if (iconType === "remark") return <FaCommentAlt />;
    if (iconType === "issue") return <LuTicket />;
    if (iconType === "location") return <IoLocationSharp />;
    if (iconType === "phone") return <HiPhone />;
    if (iconType === "email") return <MdEmail />;
    if (iconType === "bank") return <BsBank />;
    if (iconType === "hash") return <MdNumbers />;
    if (iconType === "invoice") return <FaFileInvoice />;
    if (iconType === "paymentMethod") return <MdOutlinePayments />;
    if (iconType === "barcode") return <CiBarcode />;
    if (iconType === "pointLocation") return <TbLocation />;
    if (iconType === "person") return <IoMdPerson />;
    if (iconType === "status") return <GrInProgress />;
    if (iconType === "paymentMethod") return <BsCreditCard2FrontFill />;
  };

  const defaultVAlueFormate = (data) => {
    // if (type === "date") {
    //   const DateObj = new Date(data);
    //   return `${DateObj.getFullYear()}/${
    //     DateObj.getMonth() + 1
    //   }/${DateObj.getDate()}`;
    // }

    return data;
  };

  const [isClicked, setIsClicked] = useState(false);
  const [fieldData, setFieldData] = useState(null);

  // field onclidk handle.
  const onClickHandle = (e) => {
    e.stopPropagation();
    setIsClicked(true);
  };

  // window.addEventListener("click", () => {
  //    console.log(fieldData,"form window")
  // });
  // useEffect to handle window click
  useEffect(() => {
    const handleWindowClick = () => {
      if (!fieldData) {
        setIsClicked(false);
      }
    };

    window.addEventListener("click", handleWindowClick);

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [fieldData]);

  useEffect(() => {
    if (allTimeValue?.length === 0 || !allTimeValue) setIsClicked(false);
  }, [allTimeValue]);

  return (
    <div className=" w-full  flex items-end   ">
      {/* <label className=" font-semibold" htmlFor={placeHolder}>
          {formateText(placeHolder)}
        </label> */}

      <div className="min-h-4 text-[#83430D] text-2xl p-2 shadow-xl">
        {iconFormate()}
      </div>

      <div className="w-full relative">
        {console.log(allTimeValue, "&&&&&&&&&&&", placeHolder)}
        <h1
          onClick={onClickHandle}
          className={`absolute duration-75 font-semibold  ${
             (isClicked && !disabled) || allTimeValue 
              ? type === "textArea"
                ? "-top-5 left-0"
                : "-top-5 left-0"
              : "top-3 left-2"
          }`}
        >
          {formateText(placeHolder)}
        </h1>
        {type === "textArea" ? (
          <textarea
            rows={1}
            onClick={onClickHandle}
            value={defaultVAlueFormate(allTimeValue)}
            required
            type={type}
            id={placeHolder}
            // onChange={valueUpdate}
            onChange={(e) => {
              setFieldData(e.target.value);
              valueUpdate(e.target.value);
            }}
            name={name}
            className="w-full border-b-2 pl-2  py-2 pb-3  border-[#83430D]  outline-none  bg-transparent"
          />
        ) : (
          <input
            disabled={disabled}
            onClick={onClickHandle}
            value={defaultVAlueFormate(allTimeValue)}
            required
            min={1}
            type={
              type === "date"
                ? isClicked || allTimeValue
                  ? "date"
                  : "text"
                : type
            }
            // type="text"
            id={placeHolder}
            // onChange={valueUpdate}
            onChange={(e) => {
              setFieldData(e.target.value);
              valueUpdate(e.target.value);
            }}
            name={name}
            className="w-full border-b-2 pl-2   border-[#83430D] h-12 outline-none  bg-transparent"
          />
        )}
      </div>
    </div>
  );
};

export default AgencyDashboardInput;

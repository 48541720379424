import React, { useContext, useEffect, useState } from "react";
import SetupTittle from "../SharedComponents/SetupTittle";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupInput from "../SharedComponents/SetupInput";
import SetupRadio from "../SharedComponents/SetupRadio";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import SetupDropDown from "../SharedComponents/SetupDropdown";
import SetupAutoComplete from "../SharedComponents/SetupAutoComplete";
import axios from "axios";
import HistoryTable from "../SharedComponents/HistoryTable";
import Box from "../SharedComponents/Box";
import Swal from "sweetalert2";
import GetLocalStorageUserId from "../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";

const Device = () => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(`${backendUrl}Device`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // input fields handle.
  const initialValue = {
    id: 0,
    deviceName: "",
    branchName: "string",
    ipAddress: "",
    isActive: "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    branchId: "",
    subBranchId: "",
    subBranchName: ""
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };
  const { handleDataReload } = useContext(contextProvider);
  const formHandle = (e) => {
    e.preventDefault();
    console.log({ ...formData });

    if (!willUpdate) {
      axios
        .post(`${backendUrl}Device`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            Swal.fire({
              title: `Device Created Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err.message));
    }

    if (willUpdate) {
      console.log(formData);
      axios
        .put(`${backendUrl}Device/${formData.id}`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 204) {
            Swal.fire({
              title: `Device Updated Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err.message));
    }
  };

  return (
    <div>
      <Box top>
        <SetupTittle text="Device" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupInput
                type="text"
                placeHolder="Device name"
                allTimeValue={formData.deviceName}
                valueUpdate={(e) => update({ deviceName: e.target.value })}
              />
              <SetupInput
                type="text"
                placeHolder="Ip address"
                allTimeValue={formData.ipAddress}
                valueUpdate={(e) => update({ ipAddress: e.target.value })}
              />
              <SetupDropDown // need to change
                dataOf="branch"
                allTimeValue={formData.branchId}
                valueUpdate={(e) => update({ branchId: parseInt(e.target.value) })}
                placeHolder="Branch"
                name="branchId"
              ></SetupDropDown>

              <SetupAutoComplete
                valueUpdate={(_, value) => {
                
                  update({ subBranchId: value?.id });
                }}
                placeHolder="Sub-Branch"
                dataOf="subBranch"
                allTimeValue={formData?.subBranchId}
                queryField='id'
              />

              <SetupRadio
                name="isActive"
                placeHolder="Device status?"
                allTimeValue={formData.isActive}
                valueUpdate={(data) => update({ isActive: data })}
              />
            </div>

            <SubmitBtn text={willUpdate ? "Update" : "Create"} />
            <SubmitBtn
              onClick={() => {
                setWillUpdate(false);
                update(initialValue);
              }}
              styleClass={!willUpdate && "hidden"}
              text="Cancel"
            />
          </form>
        </SetupContainer>
      </Box>

      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            editCallBack={(data) => {
           
              update(data);
              setWillUpdate(true);
            }}
            fetchedData={fetchedData}
            headers={["Device name", "Ip address", "Branch", "Sub-Branch", "Status"]}
            row={["deviceName", "ipAddress", "branchName", "subBranchName", "isActive"]}
            extraFields={["branchId", "subBranchId"]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default Device;

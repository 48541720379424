import { useContext, useEffect, useState } from "react";
import Box from "../../../setupPage/SharedComponents/Box";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import SetupInput from "../../../setupPage/SharedComponents/SetupInput";
import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
import SubmitBtn from "../../../setupPage/SharedComponents/SubmitBtn";
import axios from "axios";
import GetLocalStorageUserId from "../../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";

const ChangePasswordAdmin = () => {
    let demoData = [
        {
          id: 3,
          companyId: 1,
          itemName: "ProhibitedItemName3",
          isActive: true,
          creatorId: "creator_id_1",
          creationDate: "2024-06-28T09:18:52.58",
          modifierId: "modifier_id_1",
          modificationDate: "2024-06-28T09:18:52.58",
        },
        {
          id: 4,
          companyId: 1,
          itemName: "test",
          isActive: true,
          creatorId: "creator_Id_1",
          creationDate: "2024-07-09T10:19:41.01",
          modifierId: "creator_Id_1",
          modificationDate: "2024-07-09T10:19:41.01",
        },
        {
          id: 5,
          companyId: 1,
          itemName: "string",
          isActive: true,
          creatorId: "creator_id_1",
          creationDate: "2024-07-09T12:43:57.103",
          modifierId: "creator_id_1",
          modificationDate: "2024-07-09T12:43:57.103",
        },
        {
          id: 6,
          companyId: 1,
          itemName: "string",
          isActive: true,
          creatorId: "creator_id_1",
          creationDate: "2024-07-09T12:44:35.93",
          modifierId: "creator_id_1",
          modificationDate: "2024-07-09T12:44:35.93",
        },
        {
          id: 7,
          companyId: 1,
          itemName: "string",
          isActive: true,
          creatorId: "creator_Id_1",
          creationDate: "2024-07-09T12:46:13.667",
          modifierId: "creator_Id_1",
          modificationDate: "2024-07-09T12:46:13.667",
        },
        {
          id: 8,
          companyId: 1,
          itemName: "Box",
          isActive: true,
          creatorId: "creator_id_1",
          creationDate: "2024-07-09T17:18:22.527",
          modifierId: "creator_id_1",
          modificationDate: "2024-07-09T17:18:22.527",
        },
        {
          id: 9,
          companyId: 0,
          itemName: "string",
          isActive: true,
          creatorId: "string",
          creationDate: "2024-07-10T10:26:22.153",
          modifierId: "string",
          modificationDate: "2024-07-10T10:26:22.153",
        },
      ];
    
      const backendUrl = process.env.REACT_APP_API_URL;
      const [fetchedData, setFetchedData] = useState(demoData);
      useEffect(() => {
        axios
          .get(`${backendUrl}ProhibitedItem`)
          .then((res) => setFetchedData(res.data))
          .catch((err) => console.log(err.message));
      }, [backendUrl]);
    
      // input fields handle.
      const initialValue = {
        companyId: "",
        itemName: "",
        isActive: "",
        creatorId: GetLocalStorageUserId(),
        creationDate: new Date(),
        modifierId: GetLocalStorageUserId(),
        modificationDate: new Date(),
        branchId: "",
      };
    
      const [formData, setFormData] = useState(initialValue);
      const [willUpdate, setWillUpdate] = useState(false);
    
      const update = (data) => {
        setFormData({ ...formData, ...data });
      };
      const{handleDataReload}=useContext(contextProvider)
      const formHandle = (e) => {
        e.preventDefault();
        console.log({ ...formData });
    
        axios
          .post(`${backendUrl}ProhibitedItem`, { ...formData })
    
          .then((res) => {
            console.log(res);
            setWillUpdate(false);
            handleDataReload()
          })
          .catch((err) => console.log(err.message));
      };
    
      return (
        <div>
          <Box top>
            <SetupTittle text="Change password" />
            <SetupContainer>
              <form onSubmit={formHandle} className="">
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-y-[35px] lg:gap-x-[125px]">

                  
                  <SetupInput
                    type="text"
                    placeHolder="Name"
                    // allTimeValue={formData.itemName}
                    // valueUpdate={(e) => update({ itemName: e.target.value })}
                  />
                  <SetupInput
                    type="email"
                    placeHolder="E-mail"
                    // allTimeValue={formData.itemName}
                    // valueUpdate={(e) => update({ itemName: e.target.value })}
                  />
                  <SetupInput
                    type="text"
                    placeHolder="Password"
                    // allTimeValue={formData.itemName}
                    // valueUpdate={(e) => update({ itemName: e.target.value })}
                  />
                  <SetupInput
                    type="number"
                    placeHolder="Phone number"
                    // allTimeValue={formData.itemName}
                    // valueUpdate={(e) => update({ itemName: e.target.value })}
                  />
                  <SetupInput
                    type="text"
                    placeHolder="Address"
                    // allTimeValue={formData.itemName}
                    // valueUpdate={(e) => update({ itemName: e.target.value })}
                  />
                  
                 
                </div>
                <SubmitBtn text="save"/>
              </form>
            </SetupContainer>
          </Box>
        </div>
      );
    };
export default ChangePasswordAdmin;
import React from "react";
import Box from "../SharedComponents/Box";
import SetupTittle from "../SharedComponents/SetupTittle";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupInput from "../SharedComponents/SetupInput";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import SetupDropDown from "../SharedComponents/SetupDropdown";
import axios from "axios";

const Department = () => {
  const formHandle=(e)=>{
    e.preventDefault()
    const form=e.target
    const DepartmentName=form.departmentName.value
    const CompanyId=form.companyId.value
    const BranchId=form.branchId.value


    const backendUrl=process.env.REACT_APP_API_URL
    axios.post(`${backendUrl}Department`,{DepartmentName,CompanyId,BranchId})
    .then(res=>console.log(res)).catch(err=>console.log(err.message))
  }
  return (
    <div>
      <Box top>
        <SetupTittle text="Add a department" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupInput
                name="departmentName"
                type="text"
                placeHolder="Department name"
              />
                <SetupDropDown options={["item-1","item-2"]} placeHolder="Company id" name="companyId"></SetupDropDown>
              <SetupInput name="branchId" type="text" placeHolder="Branch id" />
            </div>
            <SubmitBtn text="Create" />
          </form>
        </SetupContainer>
      </Box>
    </div>
  );
};

export default Department;

import React, { useEffect, useState } from "react";

import box from "../../../../assets/Icons/box.png";
import bundle from "../../../../assets/Icons/bundle.png";
import email from "../../../../assets/Icons/email.png";
import BookingRemaining from "../SharedComponents/BookingRemaining";
import OverviewCard from "../SharedComponents/OverviewCard";
import PieChartComponenet from "../SharedComponents/PieChartComponenet";
import BookingCard from "../SharedComponents/BookingCard";
import PaymentHistoryTable from "../SharedComponents/PaymentHistoryTable";

// import icon for heading.
import booking from "../../../../assets/images/dashboard/icon/booking.png";
import payment from "../../../../assets/images/dashboard/icon/paymentHistory.png";
import UserBookingRemaining from "../SharedComponents/UserBookingRemaining";
import VerticalProgressBar from "../SharedComponents/VerticalProgressBar";
import Box from "../../../setupPage/SharedComponents/Box";
import DashboardPageTitle from "../SharedComponents/DashboardPageTitle";
import axios from "axios";

const UserSubRoot = () => {
  // demo data.

  const overViewCard = [
    {
      name: "Shipped",
      value: "1,254",
    },
    {
      name: "Pending",
      value: "152",
    },
    {
      name: "Paid",
      value: "578 $",
    },
    {
      name: "Client",
      value: "8,965",
    },
  ];

  const backendUrl = process.env.REACT_APP_API_URL;
  let userId = localStorage.getItem("sundorbonBookingUserDetails");
  const localStorageObj = JSON.parse(userId);

  const [fetchedData, setFetchedData] = useState(null);

  useEffect(() => {
    if (localStorageObj?.Id) {
      axios
        .get(`${backendUrl}ParcelBookingInformation/${localStorageObj?.Id}`)
        .then((res) => setFetchedData(res.data))
        .catch((err) => console.log(err.message));
    }
  }, [backendUrl]);

  return (
    <Box top>
      <DashboardPageTitle text="User Dashboard" />

      {/* top 3 cards. */}
      <div className="flex gap-5 lg:mt-0 mt-5 lg:gap-[70px] lg:px-0 px-8 justify-between flex-col lg:flex-row  items-center">
        {/* single card. */}
        {/* <BookingRemaining max={60} remaining={17} packageId={1} />
        <BookingRemaining max={40} remaining={37} packageId={2} />
        <BookingRemaining max={90} remaining={85} packageId={3} /> */}
        <UserBookingRemaining id={1} name={"Shipped"} value={21} />
        <UserBookingRemaining id={2} name={"Pending"} value={18} />
        <UserBookingRemaining id={3} name={"Paid"} value={"2145 $"} />
      </div>

      {/* booking card. */}
      {/* payment history table */}
      {/* <div className="font-bold text-[40px] mb-10 mt-16 flex items-center gap-x-3">
        {" "}
        <div className="w-[40px] bg-[#ff6f0c] h-[40px] rounded-[8px]">
          <img className="w-[90%] h-full object-contain" src={booking} alt="" />{" "}
        </div>{" "}
        Bookings
      </div> */}
      {/* <div className=" grid grid-cols-3 gap-7">
        <BookingCard></BookingCard>
        <BookingCard></BookingCard>
        <BookingCard></BookingCard>
        <BookingCard></BookingCard>
        <BookingCard></BookingCard>
        <BookingCard></BookingCard>
      </div> */}

      {/* progress bar. */}

      {/* <Progress></Progress> */}

      <div className="px-5 lg:px-0 mt-16 ">
        <VerticalProgressBar></VerticalProgressBar>
      </div>

      {/* payment history table */}
      <div className="font-bold text-[40px] mb-10 mt-16 flex items-center gap-x-3">
        {" "}
        <div className="w-[40px] bg-[#83430d] h-[40px] rounded-[8px]">
          <img className="w-[90%] h-full object-contain" src={payment} alt="" />{" "}
        </div>{" "}
        Payment History
      </div>

      <div className="px-5 lg:px-0 ">
        <PaymentHistoryTable
          // editCallBack={(data) => {
          //   update(data);
          //   setWillUpdate(true);
          // }}
          fetchedData={fetchedData}
          headers={[
            "parcel No",
            "parcel Creation Date",
            "sender Name",
            "sender Email",
            "receiver Name",
            "receiver Email",
            "route Name",
            "cargo Type Name",
            "shipping Service Name",
            "weight Description",
            "dimension Name",
            "dimension Cost",
            "subTotal",
          ]}
          row={[
            "parcelNo",
            "parcelCreationDate",
            "senderName",
            "senderEmail",
            "receiverName",
            "receiverEmail",
            "routeName",
            "cargoTypeName",
            "shippingServiceName",
            "weightDescription",
            "dimensionName",
            "dimensionCost",
            "subTotal",
          ]}
        ></PaymentHistoryTable>
      </div>
    </Box>
  );
};

export default UserSubRoot;

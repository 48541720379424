import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Box from "../SharedComponents/Box";
import SetupTittle from "../SharedComponents/SetupTittle";
import SetupContainer from "../SharedComponents/SetupContainer";
import HistoryTable from "../SharedComponents/HistoryTable";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import SetupDropDown from "../SharedComponents/SetupDropdown";
import SetupInput from "../SharedComponents/SetupInput";
import SetupRadio from "../SharedComponents/SetupRadio";
import GetLocalStorageUserId from "../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";

const Company = () => {
  let demoData = [
    {
      id: 1,
      name: "google",
      companyWebsite: "string",
      bin: "string",
      tin: "string",
      vatRegNo: "string",
      isActive: true,
      creatorId: "string",
      creationDate: "2024-07-18T12:41:04.613",
      modifierId: "string",
      modificationDate: "2024-07-18T12:41:04.613",
    },
    {
      id: 2,
      name: "apple",
      companyWebsite: "string",
      bin: "string",
      tin: "string",
      vatRegNo: "string",
      isActive: true,
      creatorId: "string",
      creationDate: "2024-07-18T12:41:04.613",
      modifierId: "string",
      modificationDate: "2024-07-18T12:41:04.613",
    },
  ];

  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState(demoData);
  useEffect(() => {
    axios
      .get(`${backendUrl}company`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl]);

  // input fields handle.
  const initialValue = {
    id: 0,
    name: "",
    companyWebsite: "",
    bin: "",
    tin: "",
    vatRegNo: "",
    isActive: "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    branchId:""
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };
  const{handleDataReload}=useContext(contextProvider)
  const formHandle = (e) => {
    e.preventDefault();
    console.log({ ...formData });

    axios
      .post(`${backendUrl}company`, { ...formData })

      .then((res) => {
        console.log(res);
        setWillUpdate(false);
        handleDataReload()
      })
      .catch((err) => console.log(err.message));
  };
  return (
    <div>
      <Box top>
        <SetupTittle text="Company" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupInput
                name="packagingDescription"
                type="text"
                placeHolder="Company name"
                allTimeValue={formData.name}
                valueUpdate={(e) => update({ name: e.target.value })}
              />
              <SetupInput
                type="text"
                placeHolder="Company website"
                allTimeValue={formData.companyWebsite}
                valueUpdate={(e) => update({ companyWebsite: e.target.value })}
              />
              <SetupInput
                type="text"
                placeHolder="Bin"
                allTimeValue={formData.bin}
                valueUpdate={(e) => update({ bin: e.target.value })}
              />
              <SetupInput
                type="text"
                placeHolder="Tin"
                allTimeValue={formData.tin}
                valueUpdate={(e) => update({ tin: e.target.value })}
              />
              <SetupInput
                type="text"
                placeHolder="Vat registration number"
                allTimeValue={formData.vatRegNo}
                valueUpdate={(e) => update({ vatRegNo: e.target.value })}
              />

              <SetupDropDown // need to change
                dataOf="branch"
                allTimeValue={formData.branchId}
                valueUpdate={(e) => update({ branchId: e.target.value })}
                placeHolder="Branch"
                name="branchId"
              ></SetupDropDown>

              <SetupRadio
                name="isActive"
                placeHolder="Company status?"
                allTimeValue={formData.isActive}
                valueUpdate={(data) => update({ isActive: data })}
              />
            </div>
            <SubmitBtn text={willUpdate ? "Update" : "Create"} />
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            editCallBack={(data) => {
              update(data);
              setWillUpdate(true);
            }}
            fetchedData={fetchedData}
            headers={[
              "Company name",
              "Company website",
              "Bin",
              "Tin",
              "Vat registration number",
              "Branch",
              "Status",
            ]}
            row={[
              "name",
              "companyWebsite",
              "bin",
              "tin",
              "vatRegNo",
              "branchId",
              "isActive",
            ]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default Company;

/* eslint-disable no-lone-blocks */
import React, { useContext, useEffect, useState } from "react";
import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";
import formateText from "../../../../utils/Formate";

const PaymentHistoryTable = ({
  headers,
  fetchedData,
  clearActiveRow,
  row,
  editCallBack,
  notEditAble,
}) => {
  const {
    companys,
    isAllDataFetched,
    branchs,
    measurementUnits,
    devices,
    cargoTypes,
    routes,
    functions,
    boxName
  } = useContext(contextProvider);


  // main array of object.
  const [data, setData] = useState(null);

  // set array of object data.
  useEffect(() => {
    if (fetchedData) setData(fetchedData);
  }, [fetchedData]);

  const typeValidation = (field, fetchedObject) => {

    if (
      !fetchedObject[field] &&
      field !== "boxAssignPreviousMonth" &&
      field !== "limitCurrentMonth" &&
      field !== "boxAssignCurrentMonth" &&
      field !== "limitPreviousMonth" &&
      field !== "isAgent" &&
      field !== "boxQty" &&
      field !== "isHub" &&
      field !== "isOffice" &&
      field !== "isActive" &&
      field !== "isExpressService" &&
      field !== "receiveQty" &&
      field !== "adjustmentQty" &&
      field !== "issueQty"
    ) {
      return <span className="text-red-400">Not Available</span>;
    }






    if (field === "isActive" && fetchedObject[field] === true) return "active";
    if (field === "isActive" && fetchedObject[field] === false)
      return "inactive";
    if (field === "dimensionId") {
      return boxName?.find((item) => item.id === fetchedObject[field])?.dimensionName;
    }
    if (field === "companyId") {
      return companys?.find((item) => item.id === fetchedObject[field])?.name;
    }
    if (field === "routeId") {
      return routes?.find((item) => item.id === fetchedObject[field])
        ?.routeName;
    }
    if (field === "cargoId") {
      return cargoTypes?.find((item) => item.id === fetchedObject[field])
        ?.cargoTypeName;
    }
    if (field === "MeasurementUnitId") {
      // need to update.
      return measurementUnits?.find((item) => item.id === fetchedObject[field])
        ?.cargoTypeName;
    }
    if (field === "startDate"||field === "paymentDate"|| field === "parcelCreationDate" || field==="requisitionDate"||field==="receiveDate") {
      const date = new Date(fetchedObject[field]);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
    if (field === "endDate") {
      const date = new Date(fetchedObject[field]);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
    if (field === "discountedDate") {
      const date = new Date(fetchedObject[field]);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }

    return fetchedObject[field];
  };

  const [activeRow, setActiveRow] = useState(null);

  useEffect(() => {
    if (clearActiveRow) setActiveRow(null);
  }, [clearActiveRow]);

  // pageination logics. every page have 10 row.

  // per page data limit.
  const limit = 10;

  // get array of pagination page number.
  const getbuttonNumbersArray = (number) => {
    const numbersArray = [];
    for (let i = 1; i <= number; i++) {
      numbersArray.push(i);
    }
    return numbersArray;
  };

  // split data.
  const splitData = (pageNo) => {
    const startingIndex = (pageNo - 1) * limit;
    const endingIndex = pageNo * limit;
    const splitedData = fetchedData?.slice(startingIndex, endingIndex);
    setData(splitedData);
  };

  // call array spliting function.
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    splitData(pageNo);
  }, [pageNo, fetchedData]);

  let PaginationButtonNumbers = getbuttonNumbersArray(
    Math.ceil(fetchedData?.length / limit)
  );

  const TableSearchHandle = (e) => {
    e.preventDefault();
    const searchAbleText = e.target.searchText.value;
    if (!fetchedData) return;

    const isInclued = (values) => {
      for (let item of values) {
        if (item !== null && item !== undefined) {
          const preferedItem = item.toString().toLowerCase();
          const preferedSearchText = searchAbleText.toString().toLowerCase();
          if (preferedItem.includes(preferedSearchText)) return true;
        }
      }
      return false;
    };

    const filteredData = fetchedData.filter((item) => {
      const itemValuesArray = Object.values(item);
      return isInclued(itemValuesArray);
    });

    setData(filteredData);

  };

  // clear search .
  const clearSearch = () => {
    setData(fetchedData);
    setSearchText("")
  };

  const[searchText,setSearchText]=useState("")


  return (
    <div className="text-end ">
       <form
        onSubmit={TableSearchHandle}
        className={"lg:w-[30%] w-full inline-flex items-center gap-x-2 justify-end mb-4"}
      >
        <input
        onChange={(e)=>setSearchText(e.target.value)}
        value={searchText}
          required
          className="w-1/2 lg:w-full border-2 lg:border-[3px] pl-2  border-[#83430D] h-12 outline-none rounded-lg bg-transparent"
          type="text"
          name="searchText"
          placeholder="You text here"
        />
        <button
          className={`font-medium  text-lg bg-gradient-to-tr from-[#6D4C41] to-[#424242] rounded-[8px] h-[47px] px-6  text-white`}
        >
          Search
        </button>
        <button onClick={clearSearch} type="button"
          className={`font-medium  text-lg bg-gradient-to-tr from-[#6D4C41] to-[#424242] rounded-[8px] h-[47px] px-6  text-white`}
        >
          Clear
        </button>
      </form>
    <div className="lg:w-full lg:mb-10  overflow-hidden">
       <div className=" min-h-32  rounded-[8px]  overflow-scroll bg-gradient-to-tr from-[#E6F7F3] to-[#FAEBE9]">
        <table className=" w-full">
          <thead>
            <tr className="  w-full bg-[#f57b00ad] text-xl text-center ">
              <th className="py-4 px-4">S/N</th>
              {headers?.map((item) => (
                <th key={item} className=" py-4 px-4  w-max">
                  <span className="w-max inline-block">
                    {formateText(item)}
                  </span>
                </th>
              ))}
              {/* <th className={notEditAble ? "hidden" : "py-4 px-12"}></th> */}
            </tr>
          </thead>
          <tbody>
            {isAllDataFetched &&
              data?.map((item, index) => {
                // return tr.
                const fetchedObject = item;

                const editFuncData = {
                  id: fetchedObject.id,
                  modificationDate: new Date(),
                };

                const td = row.map((field, idx) => {
                  editFuncData[field] = fetchedObject[field];

                  return (
                    <td
                      key={idx}
                      className="text-center font-semibold  py-3 px-4 w-max"
                    >
                      <span className="w-max inline-block">
                        {typeValidation(field, fetchedObject)}
                      </span>
                    </td>
                  );
                }); // return td.

                return (
                  <tr
                    key={index}
                    className={
                      activeRow === index
                        ? "bg-[#c0a037]"
                        : index % 2 !== 0
                        ? "bg-transparent"
                        : "bg-gray-200"
                    }
                  >
                    <td className="text-center py-3 font-semibold">
                      {(pageNo - 1) * limit + (index + 1)}
                    </td>
                    {td}
                    {/* <td className={notEditAble?"hidden":"text-center py-3 font-semibold flex justify-center items-center"}>
                      <button onClick={() =>{
                        editCallBack(editFuncData)
                        setActiveRow(index)
                      }}>
                        <FiEdit className="text-xl text-[#c26040]" />
                      </button>
                    </td> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div
        className={
          fetchedData?.length <= limit
            ? "hidden"
            : "flex items-center gap-x-1 m-auto justify-center mb-11 mt-4"
        }
      >
        {PaginationButtonNumbers?.map((item) => {
          return (
            <button
              onClick={() => setPageNo(item)}
              className={`font-medium  text-base bg-gradient-to-tr ${
                pageNo === item
                  ? "from-[#b9b89b] to-[#b3b3ae]"
                  : "from-[#ff6d0cc5] to-[#ff6f0c]"
              } rounded-[3px]  px-5 py-2 text-white`}
              key={item}
            >
              {item}
            </button>
          );
        })}
      </div>
    </div>
    </div>
  );
};

export default PaymentHistoryTable;

{
  /* <div className='flex w-[max-content] lg:w-full justify-between items-center bg-white font-bold text-base py-3 rounded-xl px-3'>
                <span className='w-[140px]'>Order</span>
                <span className='w-[140px]'>Name</span>
                <span className='w-[150px]'>Payment Received</span>
                <span className='w-[150px]'>Payment Due</span>
                <span className='w-[190px]'>E-mail</span>
                <span className='w-[170px]'>Received At</span>
            </div>

            <div className='flex w-[max-content] lg:w-full mt-2 justify-between items-center bg-white font-normal text-base py-3 rounded-xl px-3'>
                <span className='w-[140px]'>#8765445</span>
                <span className='w-[140px]'>Martin Luthar</span>
                <span className='w-[150px]'>254 $</span>
                <span className='w-[150px] '>254 $</span>
                <span className='w-[190px] '>Luthar23@gmail.com</span>
                <span className='w-[170px] font-medium bg-[#F7B696] rounded-[3px]'>12/5/2024  @ 9:45 pm  </span>
            </div> */
}


import React from "react";

const SetupRadio = ({ name, placeHolder,valueUpdate,allTimeValue,yesNO }) => {



  const shouldChecked=(data)=>{
    if(allTimeValue===true && data==="active") return true
    if(allTimeValue===false && data==="inactive") return true
    return false
  }

   // placeholder text formate.
   const formateText = (text) => {
    const wordArray = text.split(" ");
    const formatedWordArray = wordArray.map((item) => {
      const [firstL, ...rest] = item;
      rest.unshift(firstL.toUpperCase());
      return rest.join("");
    });
    return formatedWordArray?.join(" ")
  };
  
  return (
    <div className="w-full mb-3">
      <label
        className="font-semibold w-full flex flex-col"
        htmlFor={"routeStatus"+placeHolder}
      >
        {formateText(placeHolder)}
        <div className="mt-[18px] flex gap-8 ">
         
          <label className="font-medium" htmlFor={"active"+placeHolder}>
                <input checked={shouldChecked("active")} onChange={()=>valueUpdate(true)} required id={"active"+placeHolder} type="radio" name={name} value={true} /> {yesNO?"Yes":"Active"} 
              </label>
              <label className="font-medium" htmlFor={"inactive"+placeHolder}>
                <input checked={shouldChecked("inactive")} onChange={()=>valueUpdate(false)} required id={"inactive"+placeHolder} type="radio" name={name} value={false} /> {yesNO?"No":"Inactive"}
              </label>
        </div>
      </label>
    </div>
  );
};

export default SetupRadio;

import React, { useContext, useEffect, useState } from "react";
import Box from "../SharedComponents/Box";
import SetupTittle from "../SharedComponents/SetupTittle";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupInput from "../SharedComponents/SetupInput";
import SetupDropDown from "../SharedComponents/SetupDropdown";
import SetupRadio from "../SharedComponents/SetupRadio";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import HistoryTable from "../SharedComponents/HistoryTable";
import axios from "axios";
import Swal from "sweetalert2";
import SetupAutoComplete from "../SharedComponents/SetupAutoComplete";
import SetupAutoClompleteMultiSelect from "../SharedComponents/SetupAutoClompleteMultiSelect";
import GetLocalStorageUserId from "../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";

const SubBranch = () => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(`${backendUrl}SubBranch`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // input fields handle.
  const initialValue = {
    id: 0,
    subBranchName: "",
    isHub: "",
    isOffice: "",
    isAgent: "",
    agentId: [],
    agentName: "string",
    employeId: [],
    employeName: "string",
    countryId: "",
    countryName: "string",
    cityId: "",
    cityName: "string",
    address: "",
    isActive: "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
  };
  const [showAgentDropDown, setShowAgentDropDown] = useState(false);
  const [hideAgentAdnemployee, setHideAgentAdnemployee] = useState(false);
  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  console.log(formData, "this is form data...");

  const update = (data) => {
    console.log("update is heeeeeeeeeeetinggggg.",data)
    setFormData({ ...formData, ...data });
  };
  const { handleDataReload } = useContext(contextProvider);
  const formHandle = (e) => {
    e.preventDefault();
    console.log(formData);
    if (!willUpdate) {
      axios
        .post(`${backendUrl}SubBranch`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            Swal.fire({
              title: `Sub-Branch Created Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            setHideAgentAdnemployee(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err.message));
    }

    if (willUpdate) {
      axios
        .put(`${backendUrl}SubBranch/${formData.id}`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 204) {
            Swal.fire({
              title: `Sub-Branch Updated Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
            setHideAgentAdnemployee(false);
          }
        })
        .catch((err) => console.log(err.message));
    }
  };

  return (
    <div>
      <Box top>
        <SetupTittle text="Sub-Branch" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupInput
                name="subBranchName"
                type="text"
                placeHolder="Sub-Branch name"
                allTimeValue={formData.subBranchName}
                valueUpdate={(e) => update({ subBranchName: e.target.value })}
              />
              <SetupInput
                name="Address"
                type="text"
                placeHolder="Address"
                allTimeValue={formData.address}
                valueUpdate={(e) => update({ address: e.target.value })}
              />

              <SetupAutoComplete
                dataOf="city"
                valueUpdate={(_, value) => {
                  update({ cityId: value?.id });
                }}
                allTimeValue={formData?.cityId}
                placeHolder="City Name"
                queryField="id"
              />
              <SetupAutoComplete
                dataOf="country"
                valueUpdate={(_, value) => {
                  update({ countryId: value?.id });
                }}
                allTimeValue={formData?.countryId}
                placeHolder="Country Name"
                queryField="id"
              />
              <SetupRadio
                yesNO
                name="isHub"
                placeHolder="is Hub?"
                allTimeValue={formData?.isHub}
                valueUpdate={(data) => update({ isHub: data })}
              />
              <SetupRadio
                yesNO
                name="isOffice"
                placeHolder="is Office?"
                allTimeValue={formData.isOffice}
                valueUpdate={(data) => update({ isOffice: data })}
              />
              <SetupRadio
                name="isActive"
                placeHolder="Sub-Branch status?"
                allTimeValue={formData.isActive}
                valueUpdate={(data) => update({ isActive: data })}
              />
              {console.log(formData.isAgent, "is this agent or not.")}
              <SetupRadio
                yesNO
                name="isAgent"
                placeHolder="is Agent?"
                allTimeValue={formData.isAgent}
                valueUpdate={(data) => {
                  update({ isAgent: data });

                  console.log(data, "form entar the field.");
                  if(willUpdate&&!data&&formData.isAgent) {
                    console.log("fired......",formData.agentId,"agent id...")
                    update({  agentId:0,isAgent: data })
                    
                  }
                  if(willUpdate&&data&&!formData.isAgent) {
                    console.log("fired......",formData.agentId,"imployee id...")
                    update({  employeId:0,isAgent: data })
                    
                  }
                  
                  setHideAgentAdnemployee(true);
                  setShowAgentDropDown(data);
                }}
              />
              <div className={hideAgentAdnemployee ? "block" : "hidden"}>
                {showAgentDropDown ? (
                  willUpdate ? (
                    <SetupAutoComplete
                      dataOf="agent"
                      valueUpdate={(_, value) => {
                        update({ agentId: value?.id });
                      }}
                      allTimeValue={formData.agentId}
                      placeHolder="agent Name"
                      queryField="id"
                    />
                  ) : (
                    <SetupAutoClompleteMultiSelect
                      dataOf="agent"
                      valueUpdate={(_, value) => {
                        const agentId = [];
                        value?.forEach((item) => agentId.push(item?.id));
                        update({ agentId: agentId });
                      }}
                      allTimeValue={formData.agentId}
                      placeHolder="agent Name"
                    />
                  )
                ) : willUpdate ? (
                  <SetupAutoComplete
                    dataOf="employee"
                    valueUpdate={(_, value) => {
                      console.log(value);
                      update({ employeId: value?.id });
                    }}
                    allTimeValue={formData.employeId}
                    placeHolder="employe Name"
                    queryField="id"
                  />
                ) : (
                  <SetupAutoClompleteMultiSelect
                    dataOf="employee"
                    valueUpdate={(_, value) => {
                      const employeId = [];
                      value?.forEach((item) => employeId.push(item?.id));
                      update({ employeId: employeId });
                    }}
                    allTimeValue={formData.employeId}
                    placeHolder="employe Name"
                  />
                )}
              </div>
            </div>
            <SubmitBtn text={willUpdate ? "Update" : "Create"} />
            <SubmitBtn
              onClick={() => {
                setWillUpdate(false);
                update(initialValue);
                setHideAgentAdnemployee(false);
              }}
              styleClass={!willUpdate && "hidden"}
              text="Cancel"
            />
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            clearActiveRow={!willUpdate ? true : false}
            editCallBack={(data) => {
              setHideAgentAdnemployee(true);
              if (data?.isAgent) {
                setShowAgentDropDown(true);
              } else {
                setShowAgentDropDown(false);
              }
              update(data);
              setWillUpdate(true);
            }}
            fetchedData={fetchedData}
            headers={[
              "Sub-Branch Name",
              "address",
              "Agent",
              "agent Name",
              "employe Name",
              "country Name",
              "city Name",
              "address",
              "Hub",
              "Office",
              "Status",
            ]}
            row={[
              "subBranchName",
              "address",
              "isAgent",
              "agentName",
              "employeName",
              "countryName",
              "cityName",
              "address",
              "isHub",
              "isOffice",
              "isActive",
            ]}
            extraFields={["agentId", "employeId", "countryId", "cityId"]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default SubBranch;

import React from "react";
import Box from "../SharedComponents/Box";
import SetupTittle from "../SharedComponents/SetupTittle";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupInput from "../SharedComponents/SetupInput";
import SetupRadio from "../SharedComponents/SetupRadio";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import SetupDropDown from "../SharedComponents/SetupDropdown";
import axios from "axios";

const DefOperation = () => {

  const formHandle=(e)=>{
    e.preventDefault()
    const form=e.target
    const DepartmentId=form.departmentId.value
    const OperationId=form.operationId.value
    const CompanyId=form.companyId.value
    const HasAccess=form.hasAccess.value

        
    const backendUrl=process.env.REACT_APP_API_URL
    axios.post(`${backendUrl}DepartmentalOperation`,{DepartmentId,CompanyId,OperationId,HasAccess})
    .then(res=>console.log(res)).catch(err=>console.log(err.message))
  }
  return (
    <div>
      <Box top>
        <SetupTittle text="Add a departmental operation" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupInput
                name="departmentId"
                type="text"
                placeHolder="Department id"
              />
              <SetupInput
                name="operationId"
                type="text"
                placeHolder="Operation id"
              />
              <SetupDropDown options={["item-1","item-2"]} placeHolder="Company id" name="companyId"></SetupDropDown>
              <SetupRadio
                name="hasAccess"
                options={["Yes", "No"]}
                placeHolder="Has access ?"
              />
            </div>
            <SubmitBtn text="Create" />
          </form>
        </SetupContainer>
      </Box>
    </div>
  );
};

export default DefOperation;

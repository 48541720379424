import React from "react";
import { NavLink } from "react-router-dom";
import { FaDirections } from "react-icons/fa";
import { MdDeliveryDining } from "react-icons/md";
import { RiProhibitedLine } from "react-icons/ri";
import { MdMergeType } from "react-icons/md";
import { FaCity } from "react-icons/fa6";
import { FaFlag } from "react-icons/fa";
import { MdOutlineDisplaySettings } from "react-icons/md";
import { HiUserGroup } from "react-icons/hi2";
import { MdOutlineDevices } from "react-icons/md";
import { RxDimensions } from "react-icons/rx";
import { MdLocalOffer } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";
import { LuPackagePlus } from "react-icons/lu";
import { TbCategory2 } from "react-icons/tb";
import { MdOutlineCategory } from "react-icons/md";
import { IoIosBarcode } from "react-icons/io";
import { FaSitemap } from "react-icons/fa6";
import { IoPerson } from "react-icons/io5";
import { FaMoneyBillWave } from "react-icons/fa6";
import { FaBarcode } from "react-icons/fa6";
import "./AsideNav.css";
const AsideNav = () => {
  const Routes = [
    {
      to: "routes",
      name: "Routes",
      icon: <FaDirections />,
    },
    {
      to: "agent",
      name: "Agent",
      icon: <IoPerson />,
    },
    {
      to: "aditional-cost",
      name: "Additional cost",
      icon: <FaMoneyBillWave />,
    },
    {
      to: "barcode",
      name: "Barcode",
      icon: <FaBarcode />,
    },
    {
      to: "barcode-details",
      name: "Barcode details",
      icon: <IoIosBarcode />,
    },
    
    {
      to: "prohibited-item",
      name: "Prohibited item",
      icon: <RiProhibitedLine />,
    },
    {
      to: "shiping",
      name: "Shiping",
      icon: <MdDeliveryDining />,
    },
    {
      to: "cargo-type",
      name: "Cargo types",
      icon: <MdMergeType />,
    },
    {
      to: "city",
      name: "City",
      icon: <FaCity />,
    },
    {
      to: "country",
      name: "Country",
      icon: <FaFlag />,
    },
    {
      to: "departmental-operation",
      name: "Departmental operation",
      icon: <MdOutlineDisplaySettings />,
    },
    {
      to: "department",
      name: "Department",
      icon: <HiUserGroup />,
    },
    {
      to: "device",
      name: "Device",
      icon: <MdOutlineDevices />,
    }
    ,
    {
      to: "dimension",
      name: "Dimension",
      icon: <RxDimensions />,
    }
    ,
    {
      to: "offer",
      name: "Offer",
      icon: <MdLocalOffer />,
    }
    ,
    {
      to: "offer-details",
      name: "Offer details",
      icon: <BiSolidOffer />,
    }
    ,
    {
      to: "extra-packaging",
      name: "Extra packaging",
      icon: <LuPackagePlus />,
    }
    ,
    {
      to: "item-category",
      name: "Item category",
      icon: <MdOutlineCategory />,
    }
    ,
    {
      to: "function",
      name: "Function",
      icon: <TbCategory2 />,
    }
    ,
    {
      to: "/setup",
      name: "Active item type",
      icon: <FaSitemap />,
    }
    
  ];

  const sortedRoute=Routes?.sort((a,b)=>{
    if(a.name>b.name) return 1
    if(a.name<b.name) return -1
    return 0
  })

  return (
    <div className="h-full">
      <h1 className="font-normal lg:text-[48px] text-4xl lg:mt-7 lg:ml-4  m-1 text-white">
        Set-up
      </h1>
      <ul className="lg:mt-[50px] lg:h-[81%] scrollbar lg:overflow-y-scroll lg:pr-5 lg:pl-3 lg:flex flex-col gap-y-1 lg:text-base text-lg text-gray-400">
        {sortedRoute?.map((item) => {
          return (
            <NavLink
              end={item.to === "/setup"}
              className="flex items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
              to={item.to}
            >
              {item.icon}
              {item.name}
            </NavLink>
          );
        })}
      </ul>
    </div>
  );
};

export default AsideNav;

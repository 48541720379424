import React, { useContext, useEffect, useState } from "react";
import Box from "../../../setupPage/SharedComponents/Box";
import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import GetLocalStorageUserId from "../../../sharedComponents/GetLocalStorageuserId";
import axios from "axios";
import Swal from "sweetalert2";
import SetupInput from "../../../setupPage/SharedComponents/SetupInput";
import SubmitBtn from "../../../setupPage/SharedComponents/SubmitBtn";
import HistoryTable from "../../../setupPage/SharedComponents/HistoryTable";
import SetupAutoComplete from "../../../setupPage/SharedComponents/SetupAutoComplete";
import SetupTextArea from "../../../setupPage/SharedComponents/SetupTextArea";
import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";
import { toast } from "react-toastify";
import "./cashPayment.css"
const CashPayment = () => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(`${backendUrl}Payment/GetAllPayments`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // input fields handle.
  const initialValue = {
    id: 1,
    paymentMethodId: "",
    paymentMethodName: "",
    parcelOderNo: "",
    orderAmount: "",
    description: "",
    paymentDate: "",
    paymentInvoiceNo: "",
    recordSerialNo: "", //
    parcelNoWithRecordSerialNo: "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  console.log(formData,"this si sformdataaaaaaaaaaaaaaaaaaaaa.")
  const update = (data) => {
    setFormData({ ...formData, ...data });
  };
  const{handleDataReload}=useContext(contextProvider)
  const formHandle = (e) => {
    e.preventDefault();
    formData.issueDate = new Date(formData.issueDate);
    console.log(formData,"this si innnnnnnnnnnnnn.")
    if (!willUpdate) {
      axios
        .post(`${backendUrl}Payment/CreatePayment`, { ...formData })

        .then((res) => {
          if(res.status===200) toast.error("Payment Already Completed For This Parcel Number.");
          if (res.status === 201) {
            Swal.fire({
              title: `Cash Payment Created Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload()
          }
        })
        .catch((err) => console.log(err));
    }

    if (willUpdate) {
      console.log(formData);
      axios
        .put(`${backendUrl}Payment/CreatePayment/${formData.id}`, {
          ...formData,
        })

        .then((res) => {
          console.log(res,);
          if (res.status === 204) {
            Swal.fire({
              title: `Additional Cost Updated Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload()
          }
        })
        .catch((err) => console.log(err.message));
    }
  };

  return (
    <div>
      <Box top>
        <SetupTittle text="Payment" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupInput
                type="date"
                placeHolder="Payment Date"
                name="IssueDate"
                allTimeValue={formData.paymentDate}
                valueUpdate={(e) => update({ paymentDate: e.target.value })}
              />
              <SetupAutoComplete
                dataOf="GetAllAdminParcel"
                valueUpdate={(_, value) => {
                  console.log(value, "this si valieee");
                  if (value?.parcelNo)
                    axios
                      .get(
                        `${backendUrl}ParcelDetails/GetParcelDetailsByParcelNo/${value?.parcelNo}`
                      )
                      .then((res) => {
                        console.log(
                          res.data,
                          
                          "this is responsssssssssssse."
                        );
                        update({
                          parcelOderNo: parseInt(value?.parcelNo),
                          description: res.data?.description,
                          orderAmount: res.data?.orderAmount,
                          paymentMethodId: res.data?.paymentTypeMethodId,
                          paymentMethodName:
                            res.data?.paymentMethodName || "Not Available",
                          parcelNoWithRecordSerialNo:
                            value?.recordSerialNoWithParcelNo,
                          recordSerialNo: value?.recordSerialNo||"",
                          paymentInvoiceNo: "",
                        });
                      })
                      .catch((err) => console.log(err));
                  else
                    update({
                      parcelOderNo: "",
                      description: "",
                      orderAmount: "",
                      paymentMethodId: "",
                      paymentMethodName: "",
                    });
                }}
                queryField="parcelNo"
                allTimeValue={parseInt(formData.parcelOderNo)}
                placeHolder="Parcel Number"
              />
              {/* <SetupAutoComplete
                dataOf="paymentMethod"
                valueUpdate={(_, value) => {
                  console.log(value)
                  update({ paymentMethodId:value?.id,paymentMethodName:value?.name })
                }}
                allTimeValue={formData.paymentMethodId}
                placeHolder="payment Method"
              /> */}

              <SetupInput
                disabled
                name="paymentMethod"
                type="text"
                placeHolder="payment Method"
                allTimeValue={formData.paymentMethodName}
                // valueUpdate={(e) =>
                //   update({ orderAmount: parseInt(e.target.value) })
                // }
              />

              <SetupInput
                disabled
                name="orderAmount"
                type="number"
                placeHolder="order Amount"
                allTimeValue={formData.orderAmount}
                valueUpdate={(e) =>
                  update({ orderAmount: parseInt(e.target.value) })
                }
              />

              <SetupTextArea
                placeHolder="description"
                name="description"
                allTimeValue={formData.description}
                valueUpdate={(e) => update({ description: e.target.value })}
              />
              {formData?.paymentMethodName !== "Cash" && (
                <SetupInput
                  name="paymentInvoiceNo"
                  type="text"
                  placeHolder="payment Invoice Number"
                  allTimeValue={formData.paymentInvoiceNo}
                  valueUpdate={(e) =>
                    update({ paymentInvoiceNo: e.target.value })
                  }
                />
              )}
            </div>
            <SubmitBtn text={willUpdate ? "Update" : "Save"} />
            <SubmitBtn
              onClick={() => {
                setWillUpdate(false);
                update(initialValue);
              }}
              styleClass={!willUpdate && "hidden"}
              text="Cancel"
            />
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <div className="flex justify-end mb-4"></div>
          <HistoryTable
            // editCallBack={(data) => {
            //   update(data);
            //   setWillUpdate(true);
            // }}
            notEditAble
            fetchedData={fetchedData}
            headers={[
              "payment Date",
              "parcel order number",
              // "record Serial Number",
              // "payment Invoice No",
              "order Amount",
              "description",
              "payment Method Name",
            ]}
            row={[
              "paymentDate",
              "parcelNoWithRecordSerialNo",
              // "recordSerialNo",
              // "",
              "orderAmount",
              "description",
              "paymentMethodName",
            ]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default CashPayment;

import React from 'react';
import formateText from '../../../../utils/Formate';
import { BsFillBoxFill } from "react-icons/bs";

const AdminBookingCard = ({name,value}) => {
    return (
        <div className="bg-gradient-to-tr min-h-[160px] from-[#E6F7F3] to-[#FAEBE9] rounded-lg shadow-xl w-full p-3">
        <div className="flex w-full justify-between items-start gap-11">
          <div>
            <h1 className="text-lg font-medium">{formateText(name)}</h1>
            <h1 className="text-6xl mt-3 font-semibold">{value}</h1>
          </div>
          <BsFillBoxFill className="text-4xl object-contain" />
        </div>
      </div>
    );
};

export default AdminBookingCard;
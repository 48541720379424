import React from 'react';

const SetupTextArea = ({
    placeHolder,
    name,
    type,
    allTimeValue,
    valueUpdate
  }) => {

      // placeholder text formate.
   const formateText = (text) => {
    const wordArray = text?.split(" ");
    const formatedWordArray = wordArray?.map((item) => {
      const [firstL, ...rest] = item;
      rest.unshift(firstL?.toUpperCase());
      return rest?.join("");
    });
    return formatedWordArray?.join(" ")
  };
    return (
        <div className=" w-full min-h-20 border-black">
        <label className=" font-semibold" htmlFor={placeHolder}>
        {formateText(placeHolder)}
        </label>

        <textarea
       
        value={allTimeValue}
          required
          min={1}
          type={type}
          id={placeHolder}
          onChange={valueUpdate}
          name={name}
          className="w-full border-2 lg:py-2 lg:border-[3px] pl-2 mt-3 border-[#83430D] h-12 outline-none rounded-lg bg-transparent"
        />
      </div>
    );
};

export default SetupTextArea;
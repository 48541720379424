import React from "react";
import box from "../../../../assets/Icons/box.png";
import bundle from "../../../../assets/Icons/bundle.png";
import email from "../../../../assets/Icons/email.png";
const BookingRemaining = ({ data }) => {
  console.dir(data);
  const packageDetails = [
    {
      img: email,
      id: "A4_Envelope",
      name: "A4 Envelope",
      size: "12 x 9 x 1 in",
    },
    {
      img: bundle,
      id: "Small_Box",
      name: "Small_Box",
      size: "18 x 15 x 13 in",
    },
    {
      img: box,
      id: "Moving_Box",
      name: "Moving Box",
      size: "30 x 18 x 18 in",
    },
  ];
  const packageDataProvider = () => {
    const filteredData = packageDetails.find(
      (item) => item.id === data.boxName
    );
    console.dir(filteredData, "filtered data.");
    if (filteredData)
      return { ...filteredData, bookingCount: data.boxAssignCurrentMonth };
    return { img: "", name: "", size: "", bookingCount: "" };
  };

  const { img, name, size, bookingCount } = packageDataProvider();

  return (
    <div className="bg-gradient-to-tr from-[#E6F7F3] to-[#FAEBE9] rounded-lg shadow-xl w-full p-3">
      <div className="flex w-full justify-between items-start gap-11">
        <div>
          <h1 className="text-lg font-medium">{name}</h1>
          <h1 className="text-xs font-bold">{size}</h1>
          <h1 className="text-6xl mt-3 font-semibold">{bookingCount}</h1>
        </div>
        <img className="w-[50px] object-contain" src={img} alt="" />
      </div>
      <div className="flex items-center gap-8 mt-3">
        <span className="font-bold">
          {isNaN(parseInt((bookingCount / data?.limitCurrentMonth) * 100))
            ? 0
            : parseInt((bookingCount / data?.limitCurrentMonth) * 100)}
          %
        </span>
        <div className="relative w-full border border-b-8 border-gray-200 rounded-full">
          <div
            style={{
              width: `${
                isNaN(parseInt((bookingCount / data?.limitCurrentMonth) * 100))
                  ? 0
                  : parseInt((bookingCount / data?.limitCurrentMonth) * 100)
              }%`
            }}
            className={ isNaN(parseInt((bookingCount / data?.limitCurrentMonth) * 100))?"hidden":"absolute top-0 left-0 border border-b-4 border-green-600  rounded-full"}
          ></div>
        </div>
        <span className="font-bold">
          {bookingCount}/{data?.limitCurrentMonth}
        </span>
      </div>
    </div>
  );
};

export default BookingRemaining;

import React, { useState } from "react";
import Box from "../../../setupPage/SharedComponents/Box";
import DashboardPageTitle from "../../UserDashboard/SharedComponents/DashboardPageTitle";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import AgencyDashboardInput from "../SharedComponent/AgencyDashboardInput";
import AutoCompleteBottomBorder from "../../SharedComponents/AutoCompleteBottomBorder";
import SubmitBtn from "../../../setupPage/SharedComponents/SubmitBtn";
import { MdCallReceived } from "react-icons/md";
import GetLocalStorageUserId from "../../../sharedComponents/GetLocalStorageuserId";

const ChangePasswordAgent = () => {
  let demoData = {
    id: "1",
    companyId: 1,
    name: "Saiful",
    address: "shahrasti",
    email: "saifulislam104500i5@gmail.com",
    mobileNo: "01741690970",
    tin: "8",
    bin: "8",
    bankAccountInfo: "0142541,dhaka",
    comissionPercentage: 8,
    fixedCommisionAmount: 8,
    isActive: true,
    creatorId: "creator_id_1",
    creationDate: "2024-07-05T10:48:31.817",
    modifierId: GetLocalStorageUserId(),
    modificationDate: "2024-07-30T14:05:25.483",
  };

  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState(demoData);
  // useEffect(() => {
  //   axios
  //     .get(`${backendUrl}Device`)
  //     .then((res) => setFetchedData(res.data))
  //     .catch((err) => console.log(err.message));
  // }, [backendUrl]);

  // input fields handle.
  const initialValue = {
    companyId: 1,
    name: "Saiful",
    address: "shahrasti",
    email: "saifulislam104500i5@gmail.com",
    mobileNo: "01741690970",
    tin: "8",
    bin: "8",
    bankAccountInfo: "0142541,dhaka",
    comissionPercentage: 8,
    fixedCommisionAmount: 8,
    isActive: true,
    creatorId: "creator_id_1",
    creationDate: "2024-07-05T10:48:31.817",
    modifierId: GetLocalStorageUserId(),
    modificationDate: "2024-07-30T14:05:25.483",
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };

  const formHandle = (e) => {
    e.preventDefault();
    console.log({ ...formData });

    // axios
    //   .post(`${backendUrl}Device`, { ...formData })

    //   .then((res) => {
    //     console.log(res);
    //     setWillUpdate(false);
    //   })
    //   .catch((err) => console.log(err.message));
  };

  const defaultProps = {
    options: [],
    getOptionLabel: (option) => option.title,
  };

  return (
    <div className="">
      <Box top>
        <DashboardPageTitle text="Change password" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <AgencyDashboardInput
                type="text"
                iconType="name"
                placeHolder="Name"
                // allTimeValue={formData.itemName}
                // valueUpdate={(e) => update({ itemName: e.target.value })}
              />
              <AgencyDashboardInput
                type="text"
                iconType="location"
                placeHolder="address"
                // allTimeValue={formData.itemName}
                // valueUpdate={(e) => update({ itemName: e.target.value })}
              />
              <AgencyDashboardInput
                type="email"
                iconType="email"
                placeHolder="e-mail"
                // allTimeValue={formData.itemName}
                // valueUpdate={(e) => update({ itemName: e.target.value })}
              />
              <AgencyDashboardInput
                type="number"
                iconType="phone"
                placeHolder="mobile number"
                // allTimeValue={formData.itemName}
                // valueUpdate={(e) => update({ itemName: e.target.value })}
              />
              <div className="flex gap-6">
              <AgencyDashboardInput
                type="number"
                iconType="hash"
                placeHolder="Tin"
                // allTimeValue={formData.itemName}
                // valueUpdate={(e) => update({ itemName: e.target.value })}
              />
              <AgencyDashboardInput
                type="number"
                iconType="hash"
                placeHolder="Bin"
                // allTimeValue={formData.itemName}
                // valueUpdate={(e) => update({ itemName: e.target.value })}
              />
              </div>

              <AgencyDashboardInput
                type="number"
                iconType="bank"
                placeHolder="bank Account Info"
                // allTimeValue={formData.itemName}
                // valueUpdate={(e) => update({ itemName: e.target.value })}
              />


             
            </div>
           

            <SubmitBtn
              page="agentDashboard"
              text={willUpdate ? "Update" : "Save"}
            />
          </form>
        </SetupContainer>
      </Box>

      {/* <Box>
            <SetupTittle text="History" />
            <SetupContainer>
              <HistoryTable
                editCallBack={(data) => {
                  update(data);
                  setWillUpdate(true);
                }}
                // fetchedData={fetchedData}
                headers={["Issue no", "Received by","Receive date","Received price", "Received qty"]}
                // row={["deviceName", "ipAddress", "branchId", "isActive"]}
              />
            </SetupContainer>
          </Box> */}
    </div>
  );
};
export default ChangePasswordAgent;

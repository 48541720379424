import React, { useContext, useRef, useState } from "react";
import Box from "../../../setupPage/SharedComponents/Box";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
import barcodeImg from "../../../../assets/images/dashboard/barcode.png";
import SetupDropDown from "../../../setupPage/SharedComponents/SetupDropdown";
import SubmitBtn from "../../../setupPage/SharedComponents/SubmitBtn";
import { useReactToPrint } from "react-to-print";
import { ReactBarcode } from "react-jsbarcode";
import { Autocomplete, TextField } from "@mui/material";
import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";
import "./PrintBarCode.css";

const PrintBarCode = () => {
  // print handle.
  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: "booking barcode",
    removeAfterPrint: true,
    bodyClass: "scale-[0.5] origin-top-center margin-custom",
  });

  // generate barcode.
  const initialValue = {
    barcode: "",
  };

  const [formData, setFormData] = useState(initialValue);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };

  const { currentUserBarcodeParcelnumber } = useContext(contextProvider);

  // parcel number handle.
  const [barcodeNumber, setBarcodeNumber] = useState([]);
  const [routeName, setRouteName] = useState(null);
  const [parcelNo, setParcelNO] = useState(null);

  console.log(routeName);
  // const ParcelNumberHandle = (event, value) => {
  //   console.log("Here");
  //   let list = barcodeNumber;
  //   value = parseInt(value);
  //   setParcelNO(value);
  //   const barcodeNumber = parcelNumber?.find(
  //     (item) => item.percelNumber === value
  //   )?.barcode;
  //   const RouteName = parcelNumber?.find(
  //     (item) => item.percelNumber === value
  //   )?.routeName;
  //   list.push(barcodeNumber);
  //   console.log(barcodeNumber);
  //   setBarcodeNumber(list);
  //   setRouteName(RouteName);
  //   setShowBarcode(false);
  // };

  const [parcelNumbers, setParcelNumbers] = useState([]); // State to hold selected parcel numbers
  const [barcodeNumbers, setBarcodeNumbers] = useState([]); // State to hold barcode numbers
  const [routeNames, setRouteNames] = useState([]); // State to hold route names

  const ParcelNumberHandle = (event, values) => {
    console.log(values,"valuesssss.")
    setBarcodeNumbers(values);
    setShowBarcode(false)
  };

  // barcode generate btn handle.
  const [showBarcode, setShowBarcode] = useState(false);
  return (
    <div>
      <Box top>
        <SetupTittle text="Print Barcode" />
        <SetupContainer>
          <div className="flex justify-center ">
            <div className="flex w-full items-center justify-center flex-col gap-8 left">
              <div className="flex items-center justify-center w-full  lg:flex-row flex-col gap-4">
                <div className="lg:w-[500px] w-full">
                  <Autocomplete
                    multiple
                    onChange={ParcelNumberHandle} // Use onChange instead of onInputChange for selecting multiple values
                    options={currentUserBarcodeParcelnumber || []}
                    getOptionLabel={(option) => option?.recordSerialNoWithParcelNo?option?.recordSerialNoWithParcelNo:"Not Available"}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Parcel Number" />
                    )}
                  />
                </div>
                <div className="flex gap-x-2">
                  <button
                    onClick={() => {
                      setShowBarcode(true);
                      console.log(barcodeNumbers);
                    }}
                    className=" btn bg-[#544642] text-white text-lg "
                  >
                    Generate Barcode
                  </button>
                  <button
                    onClick={() =>
                      handlePrint(null, () => contentToPrint.current)
                    }
                    className={`${
                      showBarcode && barcodeNumbers
                        ? "btn bg-[#544642] text-white text-lg"
                        : "hidden"
                    }`}
                  >
                    Print
                  </button>
                </div>
              </div>

              <div className="w-full flex justify-center items-center overflow-auto ">
                <div
                  className={`${
                    showBarcode && barcodeNumbers
                      ? "block overflow-x-auto  bg-white "
                      : "hidden"
                  } `}
                >
                  <div ref={contentToPrint}>
                    {barcodeNumbers.map((dt, i) => (
                      <div key={i} className="print-content page-break  flex justify-center">
                        <div className="inline-flex justify-center flex-col items-center">
                          <ReactBarcode value={dt.barcode ? dt.barcode : 0} />
                          {/* <h1 className="text-base font-semibold text-center">
                            {dt.recordSerialNoWithParcelNo}
                          </h1> */}
                          <h1 className="text-base font-semibold text-center">
                            {dt.routeName}, Pr No: {dt.percelNumber}
                          </h1>
                          <h1 className="text-base font-semibold text-center">
                            Receiver Address: {dt.receiverAddress}
                          </h1>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <div className={${formData.barcode===""?"hidden":" py-9 bg-[#a09f973d]  rounded-lg flex flex-col justify-center items-center mx-auto"}}>
                <SubmitBtn   text="Print"></SubmitBtn>
              </div> */}
              </div>
            </div>
          </div>
        </SetupContainer>
      </Box>
    </div>
  );
};

export default PrintBarCode;

// import React, { useContext, useRef, useState } from "react";
// import Box from "../../../setupPage/SharedComponents/Box";
// import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
// import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
// import barcodeImg from "../../../../assets/images/dashboard/barcode.png";
// import SetupDropDown from "../../../setupPage/SharedComponents/SetupDropdown";
// import SubmitBtn from "../../../setupPage/SharedComponents/SubmitBtn";
// import { useReactToPrint } from "react-to-print";
// import { ReactBarcode } from "react-jsbarcode";
// import { Autocomplete, TextField } from "@mui/material";
// import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";
// import "./PrintBarCode.css";

// const PrintBarCode = () => {
//   // print handle.
//   const contentToPring = useRef(null);
//   const handlePrint = useReactToPrint({
//     documentTitle: "booking barcode",
//     removeAfterPrint: true,
//     bodyClass: "scale-[0.5] origin-top-center",
//   });

//   // generate barcode.
//   const initialValue = {
//     barcode: "",
//   };

//   const [formData, setFormData] = useState(initialValue);

//   const update = (data) => {
//     setFormData({ ...formData, ...data });
//   };

//   const { parcelNumber } = useContext(contextProvider);

//   // parcel number handle.
//   const [barcodeNumber, setBarcodeNumber] = useState(null);
//   const [routeName, setRouteName] = useState(null);
//   const [parcelNo, setParcelNO] = useState(null);

//   console.log(routeName);
//   const ParcelNumberHandle = (event, value) => {
//     value = parseInt(value);
//     setParcelNO(value);
//     const barcodeNumber = parcelNumber?.find(
//       (item) => item.percelNumber === value
//     )?.barcode;
//     const RouteName = parcelNumber?.find(
//       (item) => item.percelNumber === value
//     )?.routeName;
//     setBarcodeNumber(barcodeNumber);
//     setRouteName(RouteName);
//     setShowBarcode(false);
//   };

//   // barcode generate btn handle.
//   const [showBarcode, setShowBarcode] = useState(false);
//   return (
//     <div>
//       <Box top>
//         <SetupTittle text="Print Barcode" />
//         <SetupContainer>
//           <div className="flex justify-center ">
//             <div className="flex w-full items-center justify-center flex-col gap-8 left">
//               <div className="flex items-center justify-center w-full  lg:flex-row flex-col gap-4">
//                 <div className="lg:w-[500px] w-full">
//                   <Autocomplete
//                     onInputChange={ParcelNumberHandle}
//                     options={parcelNumber || []}
//                     getOptionLabel={(option) => option.percelNumber.toString()}
//                     sx={{ width: "100%" }}
//                     renderInput={(params) => (
//                       <TextField {...params} label="Parcel Number" />
//                     )}
//                   />
//                 </div>
//                 <div className="flex gap-x-2">
//                   <button
//                     onClick={() => setShowBarcode(true)}
//                     className=" btn bg-[#544642] text-white text-lg "
//                   >
//                     Generate Barcode
//                   </button>
//                   <button
//                     onClick={() =>
//                       handlePrint(null, () => contentToPring.current)
//                     }
//                     className={`${
//                       showBarcode && barcodeNumber
//                         ? "btn bg-[#544642] text-white text-lg"
//                         : "hidden"
//                     }`}
//                   >
//                     Print
//                   </button>
//                 </div>
//               </div>

//               <div className="w-full flex justify-center items-center overflow-auto ">
//                 <div
//                   className={`${
//                     showBarcode && barcodeNumber
//                       ? "block overflow-x-auto bg-white "
//                       : "hidden"
//                   } `}
//                 >
//                   <div
//                     ref={contentToPring}
//                     className=" inline-flex justify-center flex-col items-center print-content"
//                   >
//                     <ReactBarcode
//                       className=" "
//                       value={barcodeNumber ? barcodeNumber : 0}
//                     />
//                     <h1 className="text-base font-semibold text-center inline-block ">
//                       {routeName}, Pr No: {parcelNo}
//                     </h1>
//                     {/* <h1 className="text-base font-semibold text-center inline-block "></h1> */}
//                   </div>
//                 </div>
//                 {/* <div className={`${formData.barcode===""?"hidden":" py-9 bg-[#a09f973d]  rounded-lg flex flex-col justify-center items-center mx-auto"}`}>
               
                
                
//                 <SubmitBtn   text="Print"></SubmitBtn>
//               </div> */}
//               </div>
//             </div>
//           </div>
//         </SetupContainer>
//       </Box>
//     </div>
//   );
// };

// export default PrintBarCode;

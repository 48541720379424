import React, { useState } from "react";
import FormWrapper from "../FormWrapper/FormWrapper";
import "./ShippingInformation.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const ShippingInformation = ({
  updateFields,
  fetchedFormData,
  selectedShipmentArrivalDate,
  cargoType,
  shipmentType,
  transitCost,
  shipmentArrivalDate,
}) => {
  const { Cargotype } = fetchedFormData;

  console.dir(fetchedFormData);
  //shipment process.
  const [shipmentProcess, setShipmentProcess] = useState(null);

  // current tab contant id.
  const [currentId, setCurrentId] = useState(1);

  const extractDateInfo = (date) => {
    return {
      day: dayjs(date).format("dddd"), // Full weekday name
      date: dayjs(date).date(), // Day of the month
      month: dayjs(date).format("MMMM"), // Full month name
    };
  };

  // datePicker handle.
  // const [shipmentArrivalDate,setShipmentArrivalDate]=useState(nul)
  const DatePickerHandle = (value) => {
    console.log(value);
    updateFields({
      selectedShipmentArrivalDate: dayjs(value).format("YYYY-MM-DD"),
    });
    updateFields({ shipmentArrivalDate: extractDateInfo(value) });
    // setArrivalDate(extractDateInfo(value));
    // setCurrentId(id)
    // const currentIdData = arrivalDateInfo.find(item => item.id === id)
    // setcurrentTabData(currentIdData)
  };

  const [datePickerValue, setDatePickerValue] = useState(null);

  // load button handle.
  const loadBtttonHandle = () => {
    if (!cargoType) return toast.error("Please select Cargotype.");
    if (!datePickerValue)
      return toast.error("Please select shipment arrival Date.");
    DatePickerHandle(datePickerValue);
  };

  return (
    <FormWrapper title={"Shipping Information"}>
      <div className="">
        <div className="mb-16 mt-4 inline-block">
          <h1 className="text-3xl font-semibold">Shipment Process</h1>
          <div className=" min-h-4 mt-4 flex items-center justify-start gap-x-11">
            {Cargotype?.map((item, idx) => {
              return (
                <button
                  onClick={() => updateFields({ cargoType: item.id })}
                  type="button"
                  className={`${
                    cargoType === item.id
                      ? "text-white bg-[#E57C22]"
                      : "text-[#E57C22] bg-transparent"
                  } font-normal border rounded-lg text-xl px-3 py-1 border-[#E57C22]`}
                  key={idx}
                >
                  {item.cargoTypeName}
                </button>
              );
            })}
          </div>
          {/* <select value={cargoType}  onChange={(e) => updateFields({ cargoType: e.target.value })} className='py-2 px-4 outline-none rounded-lg'>
                        <option value="" disabled hidden selected>Select cargo type</option>
                        {
                            Cargotype?.map(item => <option value={item.cargoTypeName} key={item.id}>{item.cargoTypeName}</option>)
                        }
                    </select> */}
        </div>

        <div className="">
          <h1 className="text-3xl font-semibold ">
            Select Shipment Arrival Date
          </h1>
          <div className="mt-4">
            <div className="flex gap-x-4 my-4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disablePast={true}
                  disableHighlightToday={true}
                  onAccept={(value) => setDatePickerValue(value)}
                  label={"day/month/year"}
                  value={dayjs(selectedShipmentArrivalDate)}
                  sx={{ width: "40%", borderRadius: "0" }}
                />
              </LocalizationProvider>
              <button
                onClick={loadBtttonHandle}
                type="button"
                className=" bg-[#E57C22] font-semibold py-2 px-4 text-white  rounded-lg"
              >
                Load
              </button>
            </div>
            {/* <div className=" flex lg:flex-row flex-col justify-start items-center ">
              

              <button
                type="button"
                onClick={() => updateFields({ shipmentType: "Express" })}
                className={`w-full shadow-xl h-[56px] ${
                  shipmentType === "Express"
                    ? "bg-[#E57C22] text-white font-semibold"
                    : "bg-white"
                }`}
              >
                Express
              </button>
              <button
                type="button"
                onClick={() => updateFields({ shipmentType: "Standard" })}
                className={`w-full shadow-xl h-[56px] ${
                  shipmentType === "Standard"
                    ? "bg-[#E57C22] text-white font-semibold"
                    : "bg-white"
                }`}
              >
                Standard
              </button>

              <button
                type="button"
                onClick={() => updateFields({ shipmentType: "Regular" })}
                className={`w-full shadow-xl h-[56px] ${
                  shipmentType === "Regular"
                    ? "bg-[#E57C22] text-white font-semibold"
                    : "bg-white"
                }`}
              >
                Regular
              </button>
            </div> */}
            <div className=" py-8 lg:px-6 ">
              <div className="flex justify-evenly flex-col gap-7 lg:flex-row  items-center px-4 lg:py-0  py-10 rounded-lg bg-white border lg:h-[290px] shadow-2xl relative">
                {/* <span className="absolute top-[20px] left-0 px-3 py-1 bg-[#1ABC9C] text-white rounded-r-md">
                  Fastest {console.log(transitCost[0])}
                </span> */}
                <div className="flex flex-col justify-center items-center min-h-36 w-full">
                  {/* <span className="font-normal text-[40px] ">January</span>
                  <span className="font-bold text-[55px]">20</span>
                  <span className="font-normal text-[32px]">sunday</span> */}
                  <span className="font-normal text-[40px] ">
                    {shipmentArrivalDate?.month}
                  </span>
                  <span className="font-bold text-[55px]">
                    {shipmentArrivalDate?.date}
                  </span>
                  <span className="font-normal text-[32px]">
                    {shipmentArrivalDate?.day}
                  </span>
                </div>
                {/* <span className='font-thin text-gray-400'>End of Day</span> */}
                <div className="flex flex-col justify-center items-center w-full text-center">
                  <span className="font-bold text-[28px]">Total Cost</span>
                  <span className="font-normal text-gray-500 text-[28px]">
                    {transitCost[0]?.totalCost}£
                  </span>
                  <span className="font-bold text-[19px]">Discount Cost</span>
                  <span className="font-normal text-gray-500 text-[19px]">
                    {transitCost[0]?.discountAmount}£
                  </span>
                  <span className="font-bold text-[19px]">
                    Discount Percentage Amount
                  </span>
                  <span className="font-normal text-gray-500 text-[19px]">
                    {transitCost[0]?.disCountedPercentageAmount}£
                  </span>

                  <span className="font-bold text-[19px]">
                    Total Cost After Discount
                  </span>
                  <span className="font-normal text-gray-500 text-[19px]">
                    {transitCost[0]?.afterDiscountCost}£
                  </span>
                </div>
                {/* <button type='button' className='text-white p-2 rounded-lg bg-green-600 font-bold'>Select</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

export default ShippingInformation;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import box from "../../../../assets/Icons/box.png";
import bundle from "../../../../assets/Icons/bundle.png";
import email from "../../../../assets/Icons/email.png";
import BookingRemaining from "../SharedComponents/BookingRemaining";
import OverviewCard from "../SharedComponents/OverviewCard";
import PieChartComponenet from "../SharedComponents/PieChartComponenet";
import BookingCard from "../SharedComponents/BookingCard";
import PaymentHistoryTable from "../SharedComponents/PaymentHistoryTable";

// import icon for heading.
import booking from "../../../../assets/images/dashboard/icon/booking.png";
import payment from "../../../../assets/images/dashboard/icon/paymentHistory.png";
import axios from "axios";
import BarChartComponent from "../SharedComponents/BarChartComponent";
import DashboardPageTitle from "../SharedComponents/DashboardPageTitle";
import Box from "../../../setupPage/SharedComponents/Box";

const Subroot = () => {
  const apiAddress = process.env.REACT_APP_API_URL;
  let userId = localStorage.getItem("sundorbonBookingUserDetails");
  userId = JSON.parse(userId);

  const [fetchedData, setFetchedData] = useState(null);
  const [boxData, setBoxData] = useState(null);
  useEffect(() => {
    if (userId?.Id) {
      axios
        .get(`${apiAddress}ParcelBookingInformation/agent/${userId.Id}`)
        .then((res) => {
          setFetchedData(res.data);
        })
        .catch((err) => {
          console.log(err.message);
        });

      axios
        .get(`${apiAddress}AgentBoxAssign/AgentBoxAssignDetailsByAgentId/${userId.Id}`)
        .then((res) => {
          setBoxData(res.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);



  let piChartData = [
    { name: "Limit", value: 30, color: "#FFA76A" },
    { name: "Remaining", value: 20, color: "#fed049" },
    { name: "going", value: 40, color: "#CE8552" },
  ];

  return (
    <div>
      <Box top>
        <DashboardPageTitle text="Agent Dashboard" />

        <div className="flex lg:items-start items-center justify-center lg:justify-between gap-7 flex-col lg:flex-row  ">
          <div className="w-full">
            <div className="flex gap-3 lg:gap-7 w-full lg:flex-wrap xl:flex-nowrap lg:mt-0 mt-5  lg:px-0 px-8 justify-center flex-col lg:flex-row  items-center">
              {boxData?.map((item, idx) => (
                <BookingRemaining key={idx} data={item} />
              ))}
            </div>
            <div className="mt-7">
              <BarChartComponent />
            </div>
          </div>
          <div className=" h-full">
            <PieChartComponenet
              limit={100}
              remaining={20}
              data={piChartData}
              tittle="Monthly Booking Limit"
            />
          </div>
        </div>

        {/* secont top 2 boxes. */}
        <div className="flex justify-between  flex-col lg:flex-row lg:px-0 px-8 lg:gap-[100px] gap-5 mt-16 items-center">
          <div className="w-1/2"></div>

          {/* <OverviewCard data={overViewCard} /> */}
        </div>

        {/* booking card. */}
        {/* payment history table */}
        {/* <div className="font-bold text-[40px] mb-10 mt-16 flex items-center gap-x-3">
  {" "}
  <div className="w-[40px] bg-[#ff6f0c] h-[40px] rounded-[8px]">
    <img className="w-[90%] h-full object-contain" src={booking} alt="" />{" "}
  </div>{" "}
  Bookings
</div>
<div className=" grid lg:grid-cols-2 xl:grid-cols-3 grid-cols-1  lg:px-0 px-5 gap-5 lg:gap-7">
  <BookingCard></BookingCard>
  <BookingCard></BookingCard>
  <BookingCard></BookingCard>
  <BookingCard></BookingCard>
  <BookingCard></BookingCard>
  <BookingCard></BookingCard>
</div> */}
      </Box>

      <Box>
        {/* payment history table */}
        <div className="font-bold text-[40px] mb-10 mt-16 flex items-center gap-x-3">
          {" "}
          <div className="w-[40px] bg-[#83430D] h-[40px] rounded-[8px]">
            <img
              className="w-[90%] h-full object-contain"
              src={payment}
              alt=""
            />{" "}
          </div>{" "}
          Booking History
        </div>

        <PaymentHistoryTable // editCallBack={(data) => {
          //   update(data);
          //   setWillUpdate(true);
          // }}
          notEditAble
          fetchedData={fetchedData}
          headers={[
            "sender Name",
            "parcel Creation Date",
            "sender Email",
            "receiver Name",
            "receiver Email",
            "route Name",
            "cargo Type Name",
            "shipping Service Name",
            "weight Description",
            "dimension Name",
            "dimension Cost",
            "commision Percentage",
            "fixed Commision Amount",
            "agent Commision Payable",
            "sub Total",
          ]}
          row={[
            "parcelCreationDate",
            "senderName",
            "senderEmail",
            "receiverName",
            "receiverEmail",
            "routeName",
            "cargoTypeName",
            "shippingServiceName",
            "weightDescription",
            "dimensionName",
            "dimensionCost",
            "commisionPercentage",
            "fixedCommisionAmount",
            "agentCommisionPayable",
            "subTotal",
          ]}
        />
      </Box>
    </div>
  );
};

export default Subroot;

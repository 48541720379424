import React from "react";
import { Route, Router, Routes } from "react-router-dom";
import Login from "../components/login/Login";
import Auth from "../hooks/auth/Auth";
import Signup from "../components/signup/Signup";
import Home from "../components/home/Home";
import MainLayout from "../components/layout/MainLayout";
import Bookings from "../components/bookings/Bookings";
import AgencyDashboard from "../components/Dashboard/UserDashboard/AgencyDashboard";
import Subroot from "../components/Dashboard/UserDashboard/subComponenets/Subroot";
import UserDashboard from "../components/Dashboard/UserDashboard/UserDashboard";
import UserSubRoot from "../components/Dashboard/UserDashboard/UserComponent/UserSubRoot";
import SetupPage from "../components/setupPage/SetupPage";
import RouteSetup from "../components/setupPage/RouteSetup/RouteSetup";
import AllUsers from "../components/setupPage/AllUsers/AllUsers";
import ProhibitedItem from "../components/setupPage/prohibitedItem/ProhibitedItem";
import Shiping from "../components/setupPage/shiping/Shiping";
import CargoType from "../components/setupPage/cargoType/CargoType";
import City from "../components/setupPage/city/City";
import Country from "../components/setupPage/Country/Country";
import DefOperation from "../components/setupPage/DefOperation/DefOperation";
import Department from "../components/setupPage/Department/Department";
import Device from "../components/setupPage/Device/Device";
import Dimension from "../components/setupPage/Dimension/Dimension";
import OfferDetails from "../components/setupPage/OfferDetails/OfferDetails";
import ExtraPackaging from "../components/setupPage/extraPackaging/ExtraPackaging";
import ItmeCategory from "../components/setupPage/ItemCategory/ItmeCategory";
import Function from "../components/setupPage/Function/Function";
import ActiveItemType from "../components/setupPage/ActiveItemType/ActiveItemType";
import Offer from "../components/setupPage/Offer/Offer";
import Agent from "../components/setupPage/Agent/Agent";
import AdditionalCost from "../components/setupPage/AdditionalCost/AdditionalCost";
import Barcode from "../components/setupPage/Barcode/Barcode";
import BarcodeDetails from "../components/setupPage/BarcodeDetails/BarcodeDetails";
import AdminDashboard from "../components/Dashboard/AdminDashboard/AdminDashboard";
import OverView from "../components/Dashboard/AdminDashboard/Dashboard/OverView";
import PrintBarCode from "../components/Dashboard/AdminDashboard/PrintBarcode/PrintBarCode";
import Pay from "../components/Dashboard/AdminDashboard/Pay/Pay";
import Company from "../components/setupPage/Company/Company";
import MeasurementUnit from "../components/setupPage/MeasurementUnit/MeasurementUnit";
import Pickup from "../components/setupPage/Pickup/Pickup";
import ScaningPerson from "../components/setupPage/ScaningPerson/ScaningPerson";
import VatConfig from "../components/setupPage/VatConfig/VatConfig";
import {} from "react-icons/bs";
import WeightSetup from "../components/setupPage/WeightSetup/WeightSetup";
import SetupPagesContextApi from "../components/setupPage/SetupPagesContextApi/SetupPagesContextApi";
import UserScan from "../components/setupPage/UserScan/UserScan";
import Branch from "../components/setupPage/Branch/Branch";
import Issue from "../components/Dashboard/AdminDashboard/Issue/Issue";
import Requisition from "../components/Dashboard/UserDashboard/Requisition/Requisition";
import Receive from "../components/Dashboard/UserDashboard/Receive/Receive";
import PrintBarCodeAgency from "../components/Dashboard/AgentDashboard/PrintBarcode/PrintBarCodeAgency";
import PrintBoxSerialBarCode from "../components/Dashboard/AdminDashboard/PrintBoxSerialBarCode/PrintBoxSerialBarCode";
import AgentBoxAssign from "../components/Dashboard/AdminDashboard/AgentBoxAssign/AgentBoxAssign";
import ChangePasswordAgent from "../components/Dashboard/AgentDashboard/ChangePassword/ChangePasswordAgent";
import ChangePasswordUser from "../components/Dashboard/UserDashboard/ChangePasswordUser/ChangePasswordUser";
import ChangePasswordAdmin from "../components/Dashboard/AdminDashboard/ChangePassword/ChangePasswordAdmin";
import ParcelStatus from "../components/setupPage/ParcelStatus/ParcelStatus";
import AgentTransport from "../components/setupPage/AgentTransport/AgentTransport";
import ParcelStatusScan from "../components/Dashboard/AdminDashboard/ParcelStatusScan/ParcelStatusScan";
import AgentCost from "../components/Dashboard/AdminDashboard/AgentCost/AgentCost";
import SubBranch from "../components/setupPage/SubBranch/SubBranch";
import EmployeePrivateRoute from "../PrivateRoute/EmployeePrivateRoute";
import ParcelSetupCost from "../components/Dashboard/AdminDashboard/ParcelSetupCost/ParcelSetupCost";
import CourierCurrentStock from "../components/setupPage/CourierCurrentStock/CourierCurrentStock";
import CashPayment from "../components/Dashboard/AdminDashboard/CashPayment/CashPayment";
import CashPaymentAgent from "../components/Dashboard/AgentDashboard/CashPayment/CashPaymentAgent";
import AgentPayment from "../components/Dashboard/AgentDashboard/AgentPayment/AgentPayment";
import AgentParcelStatusScan from "../components/Dashboard/AgentDashboard/AgentParcelStatusScan/AgentParcelStatusScan";

function Pages(props) {
  return (
    // <Router>
    <Routes>
      {/* <Route path={"/"} element={<Auth child={<Login />} />} /> */}
      {/* <Route path={"/"} element={<Home />} /> */}
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Home />} />
        <Route path={"/bookings"} element={<Bookings tittle={"Booking"} />} />
        <Route
          path={"/agency-bookings"}
          element={<Bookings tittle={"Agency Booking"} />}
        />
        <Route
          path={"/admin-bookings"}
          element={<Bookings tittle={"Admin Booking"} />}
        />
      </Route>

      <Route
        path="/agent-dashboard"
        element={
          <SetupPagesContextApi>
            <AgencyDashboard />
          </SetupPagesContextApi>
        }
      >
        <Route index path="" element={<Subroot></Subroot>}></Route>
        <Route path="requisition" element={<Requisition />}></Route>
        <Route path="change-password" element={<ChangePasswordAgent />}></Route>
        <Route path="receive" element={<Receive />}></Route>
        <Route path="payment" element={<AgentPayment />}></Route>
        <Route path="parcel-status-scan" element={<AgentParcelStatusScan />}></Route>
        <Route path="cash-payment" element={<CashPaymentAgent />}></Route>
        
        <Route path="print-barcode" element={<PrintBarCodeAgency />}></Route>
      </Route>
      <Route path="/user-dashboard" element={<UserDashboard />}>
        <Route index element={<UserSubRoot />}></Route>
        <Route path="change-password" element={<ChangePasswordUser />}></Route>
      </Route>

      <Route
        path={"/admin-dashboard"}
        element={
          <EmployeePrivateRoute>
            <SetupPagesContextApi>
              <AdminDashboard />
            </SetupPagesContextApi>
          </EmployeePrivateRoute>
        }
      >
        <Route index element={<OverView></OverView>}></Route>

        <Route
          path={"setup/courier-current-stock"}
          element={<CourierCurrentStock />}
        ></Route>
        <Route path={"setup/parcel-status"} element={<ParcelStatus />}></Route>
        <Route path={"setup/route"} element={<RouteSetup></RouteSetup>}></Route>
        <Route
          path={"setup/prohibited-item"}
          element={<ProhibitedItem></ProhibitedItem>}
        ></Route>
        <Route path={"setup/shiping"} element={<Shiping></Shiping>}></Route>
        <Route
          path={"setup/cargo-type"}
          element={<CargoType></CargoType>}
        ></Route>
        <Route path={"setup/sub-branch"} element={<SubBranch />}></Route>
        <Route path={"setup/city"} element={<City></City>}></Route>
        <Route path={"setup/branch"} element={<Branch></Branch>}></Route>
        <Route path={"setup/country"} element={<Country></Country>}></Route>
        <Route
          path={"setup/scanning-point"}
          element={<UserScan></UserScan>}
        ></Route>
        <Route
          path={"setup/departmental-operation"}
          element={<DefOperation></DefOperation>}
        ></Route>
        <Route
          path={"setup/agent-transport"}
          element={<AgentTransport />}
        ></Route>
        <Route
          path={"setup/department"}
          element={<Department></Department>}
        ></Route>
        <Route path={"setup/device"} element={<Device />}></Route>
        <Route
          path={"setup/dimension"}
          element={<Dimension></Dimension>}
        ></Route>
        <Route path={"setup/offer"} element={<Offer />}></Route>
        <Route
          path={"setup/offer-details"}
          element={<OfferDetails></OfferDetails>}
        ></Route>
        <Route
          path={"setup/extra-packaging"}
          element={<ExtraPackaging></ExtraPackaging>}
        ></Route>
        <Route
          path={"setup/item-category"}
          element={<ItmeCategory></ItmeCategory>}
        ></Route>
        <Route path={"setup/function"} element={<Function></Function>}></Route>
        <Route
          path={"setup/item-type"}
          element={<ActiveItemType></ActiveItemType>}
        ></Route>
        <Route path={"setup/agent"} element={<Agent></Agent>}></Route>
        <Route
          path={"setup/aditional-cost"}
          element={<AdditionalCost></AdditionalCost>}
        ></Route>
        <Route path={"setup/barcode"} element={<Barcode></Barcode>}></Route>
        <Route
          path={"setup/barcode-details"}
          element={<BarcodeDetails></BarcodeDetails>}
        ></Route>

        <Route path={"setup/company"} element={<Company></Company>}></Route>
        <Route
          path={"setup/measurement-unit"}
          element={<MeasurementUnit></MeasurementUnit>}
        ></Route>
        <Route path={"setup/pickup"} element={<Pickup></Pickup>}></Route>
        <Route path={"setup/vat-configuration"} element={<VatConfig />}></Route>
        <Route
          path={"setup/weight"}
          element={<WeightSetup></WeightSetup>}
        ></Route>

        <Route path={"issue"} element={<Issue></Issue>}></Route>
        <Route path={"pay"} element={<Pay></Pay>}></Route>
        <Route path={"payment"} element={<CashPayment />}></Route>
        <Route
          path={"parcel-status-scan"}
          element={<ParcelStatusScan />}
        ></Route>
        <Route path={"parcel-setup-cost"} element={<ParcelSetupCost />}></Route>
        <Route
          path={"transport-agent-assigning"}
          element={<AgentCost />}
        ></Route>
        <Route path={"agent-box-ssign"} element={<AgentBoxAssign />}></Route>
        <Route
          path={"print-box-serial-barcode"}
          element={<PrintBoxSerialBarCode />}
        ></Route>
        <Route
          path={"change-password"}
          element={<ChangePasswordAdmin />}
        ></Route>
        <Route
          path={"print-barcode"}
          element={<PrintBarCode></PrintBarCode>}
        ></Route>
      </Route>

      <Route
        path={"/setup"}
        element={
          <SetupPagesContextApi>
            <SetupPage />
          </SetupPagesContextApi>
        }
      >
        <Route path={"routes"} element={<RouteSetup></RouteSetup>}></Route>
        <Route
          path={"prohibited-item"}
          element={<ProhibitedItem></ProhibitedItem>}
        ></Route>
        <Route path={"shiping"} element={<Shiping></Shiping>}></Route>
        <Route path={"cargo-type"} element={<CargoType></CargoType>}></Route>
        <Route path={"city"} element={<City></City>}></Route>
        <Route path={"country"} element={<Country></Country>}></Route>
        <Route
          path={"departmental-operation"}
          element={<DefOperation></DefOperation>}
        ></Route>
        <Route path={"department"} element={<Department></Department>}></Route>
        <Route path={"device"} element={<Device />}></Route>
        <Route path={"dimension"} element={<Dimension></Dimension>}></Route>
        <Route path={"offer"} element={<Offer />}></Route>
        <Route
          path={"offer-details"}
          element={<OfferDetails></OfferDetails>}
        ></Route>
        <Route
          path={"extra-packaging"}
          element={<ExtraPackaging></ExtraPackaging>}
        ></Route>
        <Route
          path={"item-category"}
          element={<ItmeCategory></ItmeCategory>}
        ></Route>
        <Route path={"function"} element={<Function></Function>}></Route>
        <Route index element={<ActiveItemType></ActiveItemType>}></Route>
        <Route path={"agent"} element={<Agent></Agent>}></Route>
        <Route
          path={"aditional-cost"}
          element={<AdditionalCost></AdditionalCost>}
        ></Route>
        <Route path={"barcode"} element={<Barcode></Barcode>}></Route>
        <Route
          path={"barcode-details"}
          element={<BarcodeDetails></BarcodeDetails>}
        ></Route>
      </Route>

      <Route path={"/signup"} element={<Signup />} />
      <Route path={"/login"} element={<Login />} />
    </Routes>
    // </Router>
  );
}

export default Pages;

import React, { useContext, useEffect, useState } from "react";
import Box from "../../../setupPage/SharedComponents/Box";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
import PaymentHistoryTable from "../../UserDashboard/SharedComponents/PaymentHistoryTable";
import AgencyDashboardInput from "../SharedComponent/AgencyDashboardInput";
import { toast } from "react-toastify";
import axios from "axios";
import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";
import GetLocalStorageUserId from "../../../sharedComponents/GetLocalStorageuserId";
import "./agentParcel.css"
const AgentParcelStatusScan = () => {
  const backendUrl = process.env.REACT_APP_API_URL;

  const [fetchedData, setFetchedData] = useState(null);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(`${backendUrl}BarcodeScan/GetAgentBarcodeScan/${GetLocalStorageUserId()}`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // input fields handle.
  const initialValue = {
    id: 1,
    parcelNo: "",
    barcodeNo: "",
    isActive: true,
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    barcodeScanId: 0,
    scanningPointId: "",
    scanningPersonId: "",
    parcelStatusId: "",
    scanningPointName: "",
    parcelStatusName: "",
    scanningPersonName: "",
  };

  const [formData, setFormData] = useState(initialValue);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };

  const [scanningPointAndPersonName, setScanningPointAndPersonName] =
    useState(null);
  // get local storage data.
  const localdata = localStorage.getItem("sundorbonBookingUserDetails");
  const localStorageObj = JSON.parse(localdata);

  const { scanningPoint, scanningPerson } = useContext(contextProvider);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${backendUrl}ScanningPoint/GetScanningPointByUserId/${
  //         localStorageObj?.Id || ""
  //       }`
  //     )

  //     .then((res) => {
  //       const userName = res.data?.userName;
  //       const scanningPointName = res.data?.scanningPointName;
  //       const scanningPointId = scanningPoint.find(
  //         (item) => item.scanningPointName === scanningPointName
  //       )?.id;
  //       const scanningPersonId = scanningPerson.find(
  //         (item) => item.userName === userName
  //       )?.userId;
  //       update({
  //         scanningPointId: scanningPointId,
  //         scanningPersonId: scanningPersonId,
  //       });

  //       setScanningPointAndPersonName({ userName, scanningPointName });
  //     })
  //     .catch((err) => console.log(err.message));
  // }, [scanningPoint, scanningPerson, refetch]);

  useEffect(() => {
    axios
      .get(
        `${backendUrl}ScanningPoint/GetScanningPointByUserId/${
          localStorageObj?.Id || ""
        }`
      )

      .then((res) => {
        const data = res.data;

        update({
          scanningPointName: data?.scanningPointName,
          parcelStatusName: data?.parcelStatusName,
          scanningPersonName: data?.userName,
          scanningPointId: data?.id,
          scanningPersonId: data?.userId,
          parcelStatusId: data?.parcelStatusId,
        });
      })
      .catch((err) => console.log(err.message));
  }, [refetch]);

  // form submithandle.

  const formHandle = (e) => {
    // e.preventDefault();

    console.log(formData);
    axios
      .post(`${backendUrl}BarcodeScan`, { ...formData })

      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          toast.success("Parcel Status Scan Created Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setFormData(initialValue);
          setRefetch((prev) => !prev);
        }
      })
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    if (formData.barcodeNo && formData.parcelNo) {
      formHandle();
    }
  }, [formData]);

  // handle barcode number.
  const handleBarcodeNumber = (number) => {
    update({ barcodeNo: number });
    console.log(number.length);
    if (number.length === 16) {
      axios
        .get(`${backendUrl}Parcel/CheckParcelBarcode/${number}`)
        .then((res) => {
          console.log(res.data, "ths is responssssssssssssssssse");
          if (res.data.barcodeStatus === "Barcode Matched.") {
            update({ barcodeNo: number, parcelNo: res.data?.parcelNo });
          }
          if (res.data.barcodeStatus === "Barcode already Scanned") {
            toast.warning("Barcode already Scanned.");
          }
          if (res.data.barcodeStatus === "Barcode does not exist") {
            toast.error("Barcode Does Not Match.");
          }
        })
        .catch((err) => console.log(err.message));
    }
  };

  return (
    <div>
      <Box top>
        <SetupTittle text="Parcel Status Scan" />
        <SetupContainer>
          <form onSubmit={formHandle} className="">
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
             

              <AgencyDashboardInput
                
                type="text"
                iconType="barcode"
                placeHolder="parcel barcode number"
                allTimeValue={formData?.barcodeNo}
                valueUpdate={(e) => handleBarcodeNumber(e)}
              />

              <AgencyDashboardInput
                disabled
                type="text"
                iconType="pointLocation"
                placeHolder="Scanning Point Name"
                allTimeValue={formData?.scanningPointName}
                valueUpdate={(e) => e}
              />

              <AgencyDashboardInput
                disabled
                type="text"
                iconType="person"
                placeHolder="Scanning Person Name"
                allTimeValue={formData?.scanningPersonName}
                valueUpdate={(e) => e}
              />

              <AgencyDashboardInput
                disabled
                type="text"
                iconType="status"
                placeHolder="Parcel Status Name"
                allTimeValue={formData?.parcelStatusName}
                valueUpdate={(e) => e}
              />
            </div>

            {/* <SubmitBtn text="save" /> */}
          </form>
        </SetupContainer>
      </Box>

      <Box>
        <SetupContainer>
          <SetupTittle text="history" />
          <div className="text-end mb-6"></div>
          <PaymentHistoryTable // editCallBack={(data) => {
            //   update(data);
            //   setWillUpdate(true);
            // }}
            notEditAble
            fetchedData={fetchedData}
            headers={[
              "parcel No",
              "parcel Status name",
              "scanning Person name",
              "scanning Point name",
              "barcode No",
              "Status",
            ]}
            row={[
              "parcelNo",
              "parcelStatusName",
              "scanningPersonName",
              "scanningPointName",
              "barcodeNo",
              "isActive",
            ]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default AgentParcelStatusScan;

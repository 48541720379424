import React from "react";
import formateText from "../../../utils/Formate";
import { Link } from "react-router-dom";

const SubmitBtn = ({ text, onClick, styleClass, page, type, routeing,route }) => {
  const background =
    page === "agentDashboard"
      ? "bg-[#83430D]"
      : "bg-gradient-to-tr from-[#83430D] to-[#83430D]";
  return routeing ? (
    <Link to={route}> <button
      type="button"
      onClick={onClick}
      className={`font-medium lg:mt-7  lg: mt-4 text-lg ${
        text === "Cancel" ? " bg-red-600 ml-4" : background
      } rounded-[8px] h-[47px]  text-white ${styleClass}`}
    >
      {formateText(text)}
    </button></Link>
  ) : (
    <button
      type={text === "Cancel" || type === "button" ? "button" : "submit"}
      onClick={onClick}
      className={`font-medium lg:mt-7  lg: mt-4 text-lg ${
        text === "Cancel" ? " bg-red-600 ml-4" : background
      } rounded-[8px] h-[47px] px-6 text-white ${styleClass}`}
    >
      {formateText(text)}
    </button>
  );
};

export default SubmitBtn;

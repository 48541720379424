import React from 'react';

const Box = ({children,top}) => {

    return (
        <div className={`w-full  px-4 ${!top&&"mt-[100px]"}`}>
            {children}
        </div>
    );
};

export default Box;
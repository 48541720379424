import React from "react";
import box from "../../../../../src/assets/Icons/box.png";
import "./Payment.css";
import cardLogo from "../../../../assets/images/booking/visa.png";
const Payment = () => {
  return (
    <div className="flex justify-center items-start min-h-[60vh] py-4 gap-20 w-full ">
      <div className="w-[60%] h-full">
        <h1 className="text-2xl font-semibold">Payment Summary</h1>
        <div className="mt-10">
          <div>
            <h1 className="text-lg">
              <span className="font-semibold">Paying Useing :</span> Visa card
            </h1>

            <form className="flex flex-col gap-9 mt-5">
              <div className="flex justify-center items-start flex-col gap-2">
                <label className="text-base" htmlFor="cardNumber">
                  Card Number
                </label>
                <div className="w-full relative">
                  <input
                    className="w-full outline-none border-b-2 pb-2"
                    type="number"
                  />
                  <img
                    className="w-[40px] absolute bottom-0 right-1"
                    src={cardLogo}
                    alt=""
                  />
                </div>
              </div>

              <div className="flex justify-center items-start flex-col gap-2">
                <label className="text-base" htmlFor="cardNumber">
                  Name
                </label>

                <input
                  className="w-full outline-none border-b-2 pb-2"
                  type="text"
                />
              </div>

              <div className="flex gap-28">
                <div className="flex justify-center items-start flex-col gap-2 w-full">
                  <label className="text-base" htmlFor="cardNumber">
                    Expiration Date
                  </label>

                  <input
                    className="w-full outline-none border-b-2 pb-2"
                    type="date"
                  />
                </div>

                <div className="flex justify-center items-start flex-col gap-2 w-full">
                  <label className="text-base" htmlFor="cardNumber">
                    CVV
                  </label>

                  <input
                    className="w-full outline-none border-b-2 pb-2"
                    type="number"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="w-[30%] h-full">
        <h1 className="text-2xl font-semibold">Booking Summary</h1>

        <div className="px-4 bg-[#f5f5f5] pt-8 pb-3 rounded-xl mt-10">
          <div className="flex flex-col justify-center items-center mt-6">
            <div className="bg-white shadow-lg w-[80px] rounded-xl h-[80px] flex justify-center items-center">
              <img className="w-[50px]" src={box} alt="" />
            </div>
            <h1 className="text-base font-normal mt-2">Moving box</h1>
            <h1 className="text-xl font-bold">500 €</h1>
          </div>

          <div className="flex gap-6 flex-col mt-4">
            <div className="flex flex-col gap-3">
              <h1 className="flex justify-between items-center">
                <span className="text-gray-400 font-normal text-[17px]">
                  Delivery Time:
                </span>{" "}
                <span className="text-base font-medium">
                  11 jan 2022, 11.00 am
                </span>
              </h1>
              <h1 className="flex justify-between items-center">
                <span className="text-gray-400 font-normal text-[17px]">
                  Comisson:
                </span>{" "}
                <span className="text-base font-medium"> -12.5€</span>
              </h1>
              <h1 className="flex justify-between items-center">
                <span className="text-gray-400 font-normal text-[17px]">
                  Invoice:
                </span>{" "}
                <span className="text-base font-medium">
                  000-1234-A4ENV-001
                </span>
              </h1>
              <div className="border border-b border-gray-500"></div>
            </div>

            <div className="flex flex-col gap-3">
              <h1 className="flex justify-between items-center">
                <span className="text-gray-400 font-normal text-[17px]">
                  Discount:
                </span>{" "}
                <span className="text-base font-medium"> 10%</span>
              </h1>
              <h1 className="flex justify-between items-center">
                <span className="text-gray-400 font-normal text-[17px]">
                  Subtotal:
                </span>{" "}
                <span className="text-base font-medium">1800 €</span>
              </h1>
              <div className="border border-b border-gray-500"></div>
            </div>

            <div className="flex flex-col gap-3">
              <h1 className="flex justify-between items-center">
                <span className="text-green-400 font-semibold text-[17px]">
                  Total:
                </span>{" "}
                <span className="text-base font-medium">1800 €</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;

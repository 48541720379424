import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/sundorban.png";
import { FaBars } from "react-icons/fa";
import ToggleButton from "./sub_component/ToggleButton";
import { useState } from "react";
import dashboardImg from "../../../assets/images/sundorban-tranparent.png";
import { HiDotsVertical } from "react-icons/hi";
import { toast } from "react-toastify";
import GetLocalStorageUserId from "../GetLocalStorageuserId";
import "./Navbar.css"


const Navbar = () => {
  const [showAside, setShowAside] = useState(false);
  const ToggleEventHandler = (e) => {
    e.stopPropagation();
    setShowAside(e.target.checked);
  };
  //    window.addEventListener("click",()=>setShowAside(false))

  //   const navLinks = (
  //     <>
  //       <li className="font-semibold mr-12 hover:underline">
  //         <Link to={"/"}>Home</Link>
  //       </li>
  //       <li className="font-semibold mr-12 hover:underline">
  //         <a>News</a>
  //       </li>
  //       <li className="font-semibold mr-12 hover:underline">
  //         <a>Contact Us</a>
  //       </li>
  //       <li className="font-semibold mr-12 hover:underline">
  //         <a>Order</a>
  //       </li>
  //       <li className="font-semibold text-base hover:underline">
  //         <a>Sectors</a>
  //       </li>
  //       <li className="font-semibold text-base hover:underline">
  //         <Link to="/bookings">Bookings</Link>
  //       </li>
  //     </>
  //   );
  const navLinksRight = (
    <>
      <li className=" mr-12 hover:underline">
        <Link to={""}>Sectors</Link>
      </li>
      <li className=" mr-12 hover:underline">
        <Link to={"/bookings"}>Booking</Link>
      </li>
      <li className=" mr-12 hover:underline">
        <Link to={""}>Contact Us</Link>
      </li>
    </>
  );
  const navLinksLeft = (
    <>
      <li className=" mr-12 hover:underline">
        <Link to={"/"}>Home</Link>
      </li>
      <li className=" mr-12 hover:underline">
        <Link to={""}>News</Link>
      </li>
      <li className=" mr-12 hover:underline">
        <Link to={""}>Order</Link>
      </li>
    </>
  );
  const navLinksMobile = (
    <>
      <li className=" mr-12 hover:underline">
        <Link to={"/"}>Home</Link>
      </li>
      <li className=" mr-12 hover:underline">
        <Link to={""}>News</Link>
      </li>
      <li className=" mr-12 hover:underline">
        <Link to={""}>Order</Link>
      </li>
      <li className=" mr-12 hover:underline">
        <Link to={""}>Sectors</Link>
      </li>
      <li className=" mr-12 hover:underline">
        <Link to={"/bookings"}>Booking</Link>
      </li>
      <li className=" mr-12 hover:underline">
        <Link to={""}>Contact Us</Link>
      </li>
    </>
  );

  const move = useNavigate();
  const logoutHandle = () => {
    toast.success("Logout Successfull", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    localStorage.removeItem("sundorbonBookingUserDetails");
    localStorage.removeItem("token");
    move("/");
  };

  return (
    <div className="lg:w-full mx-auto sticky top-0 left-0 z-50 text-white lg:left-0">
      {/* for desktop view. */}
      <div className="hidden lg:flex  justify-center relative h-[110px] items-end p-[17px]  text-white lg:left-0 lg:w-full lg:px-20 mx-auto bg-gradient-to-r from-[#E67E22] to-[#C8102E]">
        <button className="absolute top-[57%] right-5 text-white font-bold text-[20px]">
          {GetLocalStorageUserId() ? (
            <button onClick={logoutHandle} className="">
              Logout
            </button>
          ) : (
            <Link to={"/login"}>Login</Link>
          )}
        </button>
        {/* nav bar for desktop */}
        <div className="w-full">
          <ul className="flex justify-center text-[20px] font-normal items-center">
            {navLinksLeft}
          </ul>
        </div>
        <img
          className="rounded-full absolute left-[calc(52%-100px)] top-[20px] w-[130px] h-[130px]"
          src={logo}
          alt="logo"
        />
        <div className="w-full">
          <ul className="flex justify-center text-[20px] font-normal items-center">
            {navLinksRight}
          </ul>
        </div>
      </div>
      {/* for mobile view. */}
      <div className=" lg:hidden block z-50 text-white  w-full  relative ">
        <div className="relative z-50 flex items-center justify-between pr-2 bg-gradient-to-r from-[#E67E22] to-[#C8102E]">
          <ToggleButton
            showAside={showAside}
            eventHandle={ToggleEventHandler}
          />
          <img
            className="rounded-full absolute left-[44%] top-[10px] w-[65px] h-[65px]"
            src={logo}
            alt="logo"
          />

          {GetLocalStorageUserId() ? (
            <button onClick={logoutHandle} className="">
              Logout
            </button>
          ) : (
            <Link to={"/login"}>Login</Link>
          )}

          {/* <button className="">
            <Link to={"/login"}>Login</Link>
          </button> */}
        </div>

        <div
          className={`w-full bg-gradient-to-r from-[#E67E22] to-[#C8102E] absolute z-40 duration-300 ${
            showAside ? "translate-y-0" : "-translate-y-96"
          }`}
        >
          <ul className="flex flex-col pb-4 justify-center text-[20px] font-normal items-start pl-[40px]">
            {navLinksMobile}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

import { Autocomplete, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import formateText from "../../../utils/Formate";
import { contextProvider } from "../../setupPage/SetupPagesContextApi/SetupPagesContextApi";

const AutoCompleteBottomBorder = ({
  placeholder,
  readOnly,
  options,
  dataOf,
  valueUpdate,
  allTimeValue,
}) => {
  const { boxName, agent, issueNumber, parcelNumber,currentUserParcelNumber,GetAllAgentParcel } =
    useContext(contextProvider);
    
  //autocomplete options configuration.
  const optionsConfiguration = () => {
    if (dataOf === "GetAllAgentParcel") {
      return GetAllAgentParcel;
    }
    if (dataOf === "agent") {
      return agent;
    }
    if (dataOf === "issueNumber") {
      return issueNumber;
    }
    if (dataOf === "boxName") {
      return boxName;
    }
    if (dataOf === "reqNumber" || dataOf === "receiverNumber") {
      return [{ label: "55", id: 1 }];
    }
    if (dataOf === "parcelNumber") {
      return parcelNumber;
    }
    if (dataOf === "currentUserParcelNumber") {
      return currentUserParcelNumber;
    }

    return [];
  };

  const labelsConfiguratio = () => {
    if (dataOf === "issueNumber") {
      return (lebel) => lebel||"Not Available";
    }
    if (dataOf === "GetAllAgentParcel") {
      return (lebel) => lebel.recordSerialNoWithParcelNo;
    }
    if (dataOf === "agent") {
      return (lebel) => lebel.name;
    }
    if (dataOf === "boxName") {
      return (lebel) => lebel.dimensionName;
    }
    if (dataOf === "parcelNumber") {
      return (lebel) => lebel.percelNumber.toString();
    }
    if (dataOf === "currentUserParcelNumber") {
      return (lebel) => lebel.recordSerialNoWithParcelNo.toString();
    }
  };

  // const configuration = {};
  // // if (options) {
  // //   configuration.defaultValue = options[0];
  // // }

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");


  useEffect(() => {
    if (allTimeValue === "") {
      setValue(null);
      setInputValue("");
    }
  }, [allTimeValue]);

  return (
    <>
      {/* <button
        type="button"
        onClick={() => {
         
        }}
      >
        change
      </button> */}
      <Autocomplete
        // onChange={}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          valueUpdate(event, newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        sx={{
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.MuiAutocomplete-fullWidth": {
            borderBottom: "2px solid #83430D",
          },
        }}
        fullWidth={true}
        options={optionsConfiguration() || []}
        getOptionLabel={labelsConfiguratio()}
        id="disable-close-on-select"
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, readOnly: readOnly }}
            variant="outlined"
            placeholder={formateText(placeholder)}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
            }}
          />
        )}
      />
    </>
  );
};

export default AutoCompleteBottomBorder;

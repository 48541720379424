import React, { useContext, useEffect, useState } from "react";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupTittle from "../SharedComponents/SetupTittle";
import HistoryTable from "../SharedComponents/HistoryTable";
import Box from "../SharedComponents/Box";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import SetupInput from "../SharedComponents/SetupInput";
import SetupDropDown from "../SharedComponents/SetupDropdown";
import axios from "axios";
import Swal from "sweetalert2";
import SetupAutoComplete from "../SharedComponents/SetupAutoComplete";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";

const CourierCurrentStock = () => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(
        `${backendUrl}CurrentStockCurierService/GetAllCurrentStockCurierServices`
      )
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // input fields handle.
  const initialValue = {
    id: 0,
    dimentionId: "",
    boxName: "string",
    transactionDate: "",
    receiveQty: 0,
    issueQty: 0,
    adjustmentQty: 0,
    balanceQty: "",
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };
  const{handleDataReload}=useContext(contextProvider)
  const formHandle = (e) => {
    e.preventDefault();
    console.log(formData);
    if (!willUpdate) {
      axios
        .post(
          `${backendUrl}CurrentStockCurierService/CreateCurrentStockCurierService`,
          { ...formData }
        )

        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            Swal.fire({
              title: `Courier Current Stock Created Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload()
          }
        })
        .catch((err) => console.log(err.message));
    }

    if (willUpdate) {
      console.log(formData);
      axios
        .put(
          `${backendUrl}CurrentStockCurierService/UpdateCurrentStockCurierService/${formData.id}`,
          { ...formData }
        )

        .then((res) => {
          console.log(res);
          if (res.status === 204) {
            Swal.fire({
              title: `Courier Current Stock Updated Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload()
          }
        })
        .catch((err) => console.log(err.message));
    }
  };

  return (
    <div>
      <Box top>
        <SetupTittle text="Courier Current stock" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupInput
                name="transectionDate"
                type="date"
                placeHolder="transaction date"
                allTimeValue={formData.transactionDate}
                valueUpdate={(e) => update({ transactionDate: e.target.value })}
              />
              <SetupAutoComplete
                dataOf="boxName"
                queryField="id"
                valueUpdate={(_, value) => {
                  //   boxNameHandle(value?.id);
                  update({ dimentionId: value?.id });
                }}
                allTimeValue={formData.dimentionId}
                placeHolder="Dimension name"
              />
              <SetupInput
                name="cost"
                type="number"
                placeHolder="Balance Quantity"
                allTimeValue={formData.balanceQty}
                valueUpdate={(e) =>
                  update({ balanceQty: parseInt(e.target.value) })
                }
              />
            </div>
            <SubmitBtn text={willUpdate ? "Update" : "Create"} />
            <SubmitBtn
              onClick={() => {
                setWillUpdate(false);
                update(initialValue);
              }}
              styleClass={!willUpdate && "hidden"}
              text="Cancel"
            />
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            clearActiveRow={!willUpdate ? true : false}
            editCallBack={(data) => {
              console.log(data);
              const dateObject = new Date(data?.transactionDate)

              const year = dateObject.getFullYear();
              const month = String(dateObject.getMonth() + 1).padStart(2, "0");
              const day = String(dateObject.getDate()).padStart(2, "0");

              const formattedDate = `${year}-${month}-${day}`

              data.transactionDate=formattedDate
              update(data);
              setWillUpdate(true); 
            }}
            fetchedData={fetchedData}
            headers={[
              "Transaction Date",
              "Dimension Name",
              "Balance Quantity",
              "Receive Quantity",
              "Issue Quantity",
              "Adjustment Quantity",
            ]}
            row={[
              "transactionDate",
              "boxName",
              "balanceQty",
              "receiveQty",
              "issueQty",
              "adjustmentQty",
            ]}
            extraFields={["dimentionId"]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default CourierCurrentStock;

import React, { useContext, useEffect, useState } from "react";
import SetupTittle from "../SharedComponents/SetupTittle";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupInput from "../SharedComponents/SetupInput";
import SetupRadio from "../SharedComponents/SetupRadio";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import SetupDropDown from "../SharedComponents/SetupDropdown";
import axios from "axios";
import HistoryTable from "../SharedComponents/HistoryTable";
import Box from "../SharedComponents/Box";
import Swal from "sweetalert2";
import GetLocalStorageUserId from "../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";

const Dimension = () => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(`${backendUrl}Dimension`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // input fields handle.
  const initialValue = {
    id: 0,
    companyId: 0,
    dimensionName: "",
    length: "",
    width: "",
    height: "",
    measurementUnitId: "",
    price: "",
    isActive: "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    branchId: 0,
    branchName: "string",
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);
  console.log(formData);
  const update = (data) => {
    setFormData({ ...formData, ...data });
  };
  const { handleDataReload } = useContext(contextProvider);
  const formHandle = (e) => {
    e.preventDefault();
    if (!willUpdate) {
      axios
        .post(`${backendUrl}Dimension`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            Swal.fire({
              title: `Dimension Created Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err.message));
    }

    if (willUpdate) {
      console.log(formData);
      axios
        .put(`${backendUrl}Dimension/${formData.id}`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 204) {
            Swal.fire({
              title: `Dimension Updated Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err.message));
    }
  };

  return (
    <div>
      <Box top>
        <SetupTittle text="Dimension" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupInput
                type="text"
                placeHolder="Dimension name"
                allTimeValue={formData.dimensionName}
                valueUpdate={(e) => update({ dimensionName: e.target.value })}
              />
              <div className="grid grid-cols-3 gap-x-5">
                <SetupInput
                  type="number"
                  placeHolder="Length"
                  allTimeValue={formData.length}
                  valueUpdate={(e) => update({ length: e.target.value })}
                />
                <SetupInput
                  type="number"
                  placeHolder="Width"
                  allTimeValue={formData.width}
                  valueUpdate={(e) => update({ width: e.target.value })}
                />
                <SetupInput
                  type="number"
                  placeHolder="Height"
                  allTimeValue={formData.height}
                  valueUpdate={(e) => update({ height: e.target.value })}
                />
              </div>

              <SetupDropDown
                dataOf="measurementUnit"
                placeHolder="Measurement unit"
                allTimeValue={formData.measurementUnitId}
                valueUpdate={(e) =>
                  update({ measurementUnitId: parseInt(e.target.value) })
                }
              ></SetupDropDown>

              {/* <SetupDropDown
                dataOf="company"
                allTimeValue={formData.companyId}
                valueUpdate={(e) => update({ companyId: parseInt(e.target.value) })}
                name="companyName"
                placeHolder="Company name"
              ></SetupDropDown> */}
              <SetupDropDown // need to change
                dataOf="branch"
                allTimeValue={formData.branchId}
                valueUpdate={(e) => update({ branchId: e.target.value })}
                placeHolder="Branch"
                name="branchId"
              ></SetupDropDown>

              <SetupInput
                type="number"
                placeHolder="Cost"
                allTimeValue={formData.price}
                valueUpdate={(e) => update({ price: e.target.value })}
              />
              <SetupRadio
                name="isActive"
                placeHolder="Dimension status?"
                allTimeValue={formData.isActive}
                valueUpdate={(data) => update({ isActive: data })}
              />
            </div>
            <SubmitBtn text={willUpdate ? "Update" : "Create"} />
            <SubmitBtn
              onClick={() => {
                setWillUpdate(false);
                update(initialValue);
              }}
              styleClass={!willUpdate && "hidden"}
              text="Cancel"
            />
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            editCallBack={(data) => {
              update(data);
              setWillUpdate(true);
            }}
            fetchedData={fetchedData}
            headers={[
              "Dimension name",
              "Measurement unit",
              "Company name",
              "Length",
              "Width",
              "Height",
              "Branch",
              "Cost",
              "Status",
            ]}
            row={[
              "dimensionName",
              "measurementUnitId",
              "companyId",
              "length",
              "width",
              "height",
              "branchName",
              "price",
              "isActive",
            ]}
            extraFields={["branchId"]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default Dimension;

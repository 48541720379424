import React, { useContext, useEffect, useState } from 'react';
import Box from '../SharedComponents/Box';
import SetupTittle from '../SharedComponents/SetupTittle';
import SetupContainer from '../SharedComponents/SetupContainer';
import HistoryTable from '../SharedComponents/HistoryTable';
import SubmitBtn from '../SharedComponents/SubmitBtn';
import SetupRadio from '../SharedComponents/SetupRadio';
import SetupDropDown from '../SharedComponents/SetupDropdown';
import SetupAutoComplete from "../SharedComponents/SetupAutoComplete";
import SetupInput from '../SharedComponents/SetupInput';
import axios from 'axios';
import Swal from 'sweetalert2';
import GetLocalStorageUserId from '../../sharedComponents/GetLocalStorageuserId';
import { contextProvider } from '../SetupPagesContextApi/SetupPagesContextApi';

const ParcelStatus = () => {
    const backendUrl = process.env.REACT_APP_API_URL;
    const [fetchedData, setFetchedData] = useState([]);
    const [refetch, setRefetch] = useState(false);
    useEffect(() => {
      axios
        .get(`${backendUrl}ParcelStatus`)
        .then((res) => setFetchedData(res.data))
        .catch((err) => console.log(err.message));
    }, [backendUrl, refetch]);
  
    // input fields handle.
    const initialValue = {
      id: 0,
      parcelStatusName: "",
      isActive: "",
      creatorId: GetLocalStorageUserId(),
      creationDate: new Date(),
      modifierId: GetLocalStorageUserId(),
      modificationDate: new Date(),
      branchId: "",
      branchName: "string",
      subBranchId: "",
      subBranchName: ""
    };
  
    const [formData, setFormData] = useState(initialValue);
    const [willUpdate, setWillUpdate] = useState(false);
  
    const update = (data) => {
      setFormData({ ...formData, ...data });
    };
    const { handleDataReload } = useContext(contextProvider);
    const formHandle = (e) => {
      e.preventDefault();
  
      if (!willUpdate) {
        axios
          .post(`${backendUrl}ParcelStatus`, { ...formData })
  
          .then((res) => {
            console.log(res)
            if (res.status === 201) {
              Swal.fire({
                title: `Parcel Status Created Successfully`,
                icon: "success",
              });
              setRefetch((prev) => !prev);
              setFormData(initialValue);
              setWillUpdate(false);
              handleDataReload();
            }
            
          })
          .catch((err) => console.log(err.message));
      }
  
      if (willUpdate) {
        console.log(formData)
        axios
          .put(`${backendUrl}ParcelStatus/${formData.id}`, { ...formData })
  
          .then((res) => {
            console.log(res);
            if (res.status === 204) {
              Swal.fire({
                title: `Parcel Status Updated Successfully`,
                icon: "success",
              });
              setRefetch((prev) => !prev);
              setFormData(initialValue);
              setWillUpdate(false);
              handleDataReload();
            }
          })
          .catch((err) => console.log(err.message));
      }
    };
  
    return (
      <div>
        <Box top>
          <SetupTittle text="parcel Status" />
          <SetupContainer>
            <form className="" onSubmit={formHandle}>
              <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
                <SetupInput
                  name="additionalCostName"
                  type="text"
                  placeHolder="parcel status name"
                  allTimeValue={formData.parcelStatusName}
                  valueUpdate={(e) =>
                    update({ parcelStatusName: e.target.value })
                  }
                />
                
                <SetupDropDown
                  dataOf="branch"
                  allTimeValue={formData.branchId}
                  valueUpdate={(e) => update({ branchId: e.target.value })}
                  placeHolder="Branch"
                  name="branchId"
                ></SetupDropDown>
                  <SetupAutoComplete
                valueUpdate={(_, value) => {
                
                  update({ subBranchId: value?.id });
                }}
                placeHolder="Sub-Branch"
                dataOf="subBranch"
                allTimeValue={formData?.subBranchId}
                queryField='id'
              />
                <SetupRadio
                  name="isActive"
                  placeHolder="Parcel status?"
                  allTimeValue={formData.isActive}
                  valueUpdate={(data) => update({ isActive: data })}
                />
              </div>
              <SubmitBtn text={willUpdate ? "Update" : "Create"} />
              <SubmitBtn
                onClick={() => {
                  setWillUpdate(false);
                  update(initialValue);
                }}
                styleClass={!willUpdate && "hidden"}
                text="Cancel"
              />
            </form>
          </SetupContainer>
        </Box>
        <Box>
          <SetupTittle text="History" />
          <SetupContainer>
            <HistoryTable
              clearActiveRow={!willUpdate ? true : false}
              editCallBack={(data) => {
                console.log(data)
                update(data);
                setWillUpdate(true);
              }}
              fetchedData={fetchedData}
              headers={["Parcel Status Name", "Branch", "Sub-Branch", "Status"]}
              row={["parcelStatusName", "branchName",  "subBranchName", "isActive"]}
              extraFields={["branchId","subBranchId"]}
            />
          </SetupContainer>
        </Box>
      </div>
    );
  };

export default ParcelStatus;
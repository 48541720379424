import React, { useState } from 'react';
import Box from '../../../setupPage/SharedComponents/Box';
import DashboardPageTitle from '../SharedComponents/DashboardPageTitle';
import SetupContainer from '../../../setupPage/SharedComponents/SetupContainer';
import AgencyDashboardInput from '../../AgentDashboard/SharedComponent/AgencyDashboardInput';
import AutoCompleteBottomBorder from '../../SharedComponents/AutoCompleteBottomBorder';
import SubmitBtn from '../../../setupPage/SharedComponents/SubmitBtn';
import { MdCallReceived } from "react-icons/md";
import GetLocalStorageUserId from '../../../sharedComponents/GetLocalStorageuserId';

const ChangePasswordUser = () => {
    let demoData = [
        {
          id: 1,
          deviceName: "Mobile",
          ipAddress: "192.168.1.2",
          isActive: true,
          creatorId: GetLocalStorageUserId(),
          creationDate: new Date(),
          modifierId: GetLocalStorageUserId(),
          modificationDate: new Date(),
        },
      ];
    
      //   const backendUrl = process.env.REACT_APP_API_URL;
      //   const [fetchedData, setFetchedData] = useState(demoData);
      //   useEffect(() => {
      //     axios
      //       .get(`${backendUrl}Device`)
      //       .then((res) => setFetchedData(res.data))
      //       .catch((err) => console.log(err.message));
      //   }, [backendUrl]);
    
      // input fields handle.
      const initialValue = {
        id: "",
        deviceName: "",
        ipAddress: "",
        isActive: "",
        creatorId: GetLocalStorageUserId(),
        creationDate: new Date(),
        modifierId: GetLocalStorageUserId(),
        modificationDate: new Date(),
        branchId: "",
      };
    
      const [formData, setFormData] = useState(initialValue);
      const [willUpdate, setWillUpdate] = useState(false);
    
      const update = (data) => {
        setFormData({ ...formData, ...data });
      };
    
      const formHandle = (e) => {
        e.preventDefault();
        console.log({ ...formData });
    
        // axios
        //   .post(`${backendUrl}Device`, { ...formData })
    
        //   .then((res) => {
        //     console.log(res);
        //     setWillUpdate(false);
        //   })
        //   .catch((err) => console.log(err.message));
      };
    
      const defaultProps = {
        options: [],
        getOptionLabel: (option) => option.title,
      };
    
      return (
        <div className="">
          <Box top>
            <DashboardPageTitle text="Change password" />
            <SetupContainer>
              <form className="" onSubmit={formHandle}>
                <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
                  <AgencyDashboardInput
                    type="text"
                    iconType="name"
                    placeHolder="user name"
                    // allTimeValue={formData.itemName}
                    // valueUpdate={(e) => update({ itemName: e.target.value })}
                  />
                  <AgencyDashboardInput
                    type="email"
                    iconType="email"
                    placeHolder="User email"
                    // allTimeValue={formData.itemName}
                    // valueUpdate={(e) => update({ itemName: e.target.value })}
                  />
                  <AgencyDashboardInput
                    type="text"
                    iconType="email"
                    placeHolder="password"
                    // allTimeValue={formData.itemName}
                    // valueUpdate={(e) => update({ itemName: e.target.value })}
                  />
                  <AgencyDashboardInput
                    type="number"
                    iconType="phone"
                    placeHolder="mobile number"
                    // allTimeValue={formData.itemName}
                    // valueUpdate={(e) => update({ itemName: e.target.value })}
                  />
                  <AgencyDashboardInput
                    type="text"
                    iconType="location"
                    placeHolder="Address"
                    // allTimeValue={formData.itemName}
                    // valueUpdate={(e) => update({ itemName: e.target.value })}
                  />
               
                </div>
    
                <SubmitBtn
                  page="agentDashboard"
                  text={willUpdate ? "Update" : "Save"}
                />
              </form>
            </SetupContainer>
          </Box>
    
          {/* <Box>
            <SetupTittle text="History" />
            <SetupContainer>
              <HistoryTable
                editCallBack={(data) => {
                  update(data);
                  setWillUpdate(true);
                }}
                // fetchedData={fetchedData}
                headers={["Issue no", "Received by","Receive date","Received price", "Received qty"]}
                // row={["deviceName", "ipAddress", "branchId", "isActive"]}
              />
            </SetupContainer>
          </Box> */}
        </div>
      );
    };
export default ChangePasswordUser;
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Box from "../../../setupPage/SharedComponents/Box";
import SetupAutoClompleteMultiSelect from "../../../setupPage/SharedComponents/SetupAutoClompleteMultiSelect";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
import AdminPageDataTable from "../SharedComponent/AdminPageDataTable";
import SubmitBtn from "../../../setupPage/SharedComponents/SubmitBtn";
import Swal from "sweetalert2";
import SetupAutoComplete from "../../../setupPage/SharedComponents/SetupAutoComplete";
import SetupInput from "../../../setupPage/SharedComponents/SetupInput";
import HistoryTable from "../../../setupPage/SharedComponents/HistoryTable";
import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";

const AgentCost = () => {
  const backendUrl = process.env.REACT_APP_API_URL;

  const [fetchedData, setFetchedData] = useState(null);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(`${backendUrl}TransportAgentCost`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // parcel number handle.
  const [parcelDetails, setParcelDetails] = useState(null);

  const parcelNumberDetailsHandle = (e) => {
    let parcelNumberArray = e?.map((item) => item.percelNumber);

    if (e.length === 0) return setParcelDetails(null);

    axios
      .post(
        `${backendUrl}SenderDetails/GetPickupAndDeliveryPoint`,
        parcelNumberArray
      )

      .then((res) => {
        setParcelDetails(res.data);
        // if (res.status === 200) {
        //   Swal.fire({
        //     title: `Box Assigned Successfully`,
        //     icon: "success",
        //   });
        //   setRefetch((prev) => !prev);
        //   setFormData(initialValue)
        //   setWillUpdate(false);
        // }
      })
      .catch((err) => console.log(err.message));
  };
  // input fields handle.
  const initialValue = {
    id: 0,
    parcelNo: "",
    pickUpPoint: "",
    deliveryPoint: "",
    transportAgentId: "",
    cost: "",
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const[formDataArray,setFormDataArray]=useState(null)
  const{handleDataReload}=useContext(contextProvider)
  const formHandle = (e) => {
    e.preventDefault();

    console.log({ ...formData });

    axios
      .post(`${backendUrl}TransportAgentCost`,formDataArray)

      .then((res) => {
        if (res.status === 201) {
          
          Swal.fire({
            title: `Transport Agent Assigned Successful`,
            icon: "success",
          });
          setRefetch((prev) => !prev);
          setFormData(initialValue);
          setParcelDetails(null);
          handleDataReload()
        }
      })
      .catch((err) => console.log(err.message));
  };

  // data post handle.
  const postHandle = () => {
    axios
      .post(
        `${backendUrl}SenderDetails/GetPickupAndDeliveryPoint`,
        parcelDetails
      )

      .then((res) => {
        setParcelDetails(res.data);
        if (res.status === 200) {
          Swal.fire({
            title: `Agent Cost Assigned Successfully`,
            icon: "success",
          });
        }
      })
      .catch((err) => console.log(err.message));
  };


  const update = (data) => {
    setFormData({ ...formData, ...data });
  };

 const formDataObjectArrayHandle=(parcelDetails,agentDetails)=>{


if(agentDetails.cost.length!==0 && agentDetails.transportAgentId.length!==0&&parcelDetails){
const finalArray=parcelDetails?.map(item=>{
  return {
    "id": 0,
    "parcelNo": item.parcelOrderId,
    "pickUpPoint": item.pickpupPointAddress,
    "deliveryPoint": item.deliveryPointAddress,
    "transportAgentId": agentDetails.transportAgentId,
    "cost": agentDetails.cost
  }
})
setFormDataArray(finalArray)
}


  }

  return (
    <div>
      <Box top>
        <SetupTittle text="Transport Agent Assigning" />
        <SetupContainer>
          <form onSubmit={formHandle} className="">
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupAutoComplete
                valueUpdate={(_, value) => {
                  if(!value) update({cost:""})
                  if (value) {
                   
                    axios
                      .get(
                        `${backendUrl}TransportAgentCost/${value?.id}`)

                      .then((res) => {
                        console.log(res.data,"This isssssssss responsessssssssssssss.")
                        update({cost:res?.data?.cost,pickUpPoint:res.data?.pickUpPoint,deliveryPoint:res.data?.deliveryPoint,transportAgentId: value.id  });
                        const agentDetails={cost:res?.data?.cost||0,transportAgentId:value?.id}
                        formDataObjectArrayHandle(parcelDetails,agentDetails)
                      })
                      .catch((err) => console.log(err.message));

                 
                  }
                }}
                placeHolder="transport Agent Name"
                dataOf="transportAgent"
                allTimeValue={formData.transportAgentId}
              />

              <SetupInput
              disabled
                type="number"
                placeHolder="Cost"
                name="Cost"
                allTimeValue={formData.cost.toString()}
                // valueUpdate={(e) => update({ cost: parseInt(e.target.value) })}
              />

              <SetupAutoClompleteMultiSelect
                
                valueUpdate={(_, value) => {
                  const parcelNumber=[]
                  value?.forEach(item=>parcelNumber.push(item.percelNumber))
                  if(parcelNumber.length!==0){
                    axios
                    .post(
                      `${backendUrl}SenderDetails/GetPickupAndDeliveryPoint`,
                      parcelNumber
                    )

                    .then((res) => {
                      setParcelDetails(res.data);
                      const agentDetails={cost:formData.cost,transportAgentId:formData.transportAgentId}
                      const parcelDetails=res.data
                        formDataObjectArrayHandle(parcelDetails,agentDetails)
                      update({
                        parcelNo: value?.percelNumber,
                        // deliveryPoint: res.data[0]?.deliveryPointAddress,
                        // pickUpPoint: res.data[0]?.pickpupPointAddress,
                      });
                    })
                    .catch((err) => console.log(err.message));
                  }
                }}
                placeHolder="Parcel Number"
                dataOf="parcelNumber"
                allTimeValue={formData.parcelNo}
              />

              {/* <SetupAutoComplete
                valueUpdate={(_, value) => {
                  if (!value) return setParcelDetails(null);
                  update({parcelNo: value.percelNumber})
                  axios
                    .post(
                      `${backendUrl}SenderDetails/GetPickupAndDeliveryPoint`,
                      [value.percelNumber]
                    )

                    .then((res) => {
                      console.log(res.data,"parcccceeeeeeeellllllNnnnnnnnnnnnnuuuuuuummmmber.")
                      setParcelDetails(res.data);
                      // update({
                      //   parcelNo: value.percelNumber,
                      //   // deliveryPoint: res.data[0]?.deliveryPointAddress,
                      //   // pickUpPoint: res.data[0]?.pickpupPointAddress,
                      // });
                    })
                    .catch((err) => console.log(err.message));
                }}
                placeHolder="Parcel Number"
                dataOf="parcelNumber"
                allTimeValue={formData.parcelNo}
              /> */}

              
            </div>
            <div className="mt-4">
              {parcelDetails && (
                <AdminPageDataTable
                  // clearActiveRow={!willUpdate ? true : false}
                  // editCallBack={(data) => {
                  //   console.log(data)
                  //   update(data);
                  //   setWillUpdate(true);
                  // }}
                  fetchedData={parcelDetails}
                  headers={[
                    "delivery Point",
                    "parcel number",
                    "pickpup Point",
                  ]}
                  row={[
                    "deliveryPointAddress",
                    "parcelOrderId",
                    "pickpupPointAddress",
                  ]}
                  // extraFields={["branchId"]}
                />
              )}
            </div>

            <div className={"flex items-center gap-4"}>
              <SubmitBtn text="Save" />
            </div>
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            notEditAble
            // editCallBack={(data) => {
            //   update(data);
            //   setWillUpdate(true);
            // }}
            fetchedData={fetchedData}
            headers={[
              "parcel No",
              "transport Agent Name",
              "pickup Point",
              "delivery Point",
              "cost",
            ]}
            row={[
              "parcelNo",
              "transportAgentId",
              "pickUpPoint",
              "deliveryPoint",
              "cost",
            ]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default AgentCost;

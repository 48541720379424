import React, { useContext, useEffect, useState } from "react";
import SetupTittle from "../SharedComponents/SetupTittle";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupInput from "../SharedComponents/SetupInput";
import SetupRadio from "../SharedComponents/SetupRadio";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import axios from "axios";
import SetupDropDown from "../SharedComponents/SetupDropdown";
import HistoryTable from "../SharedComponents/HistoryTable";
import Box from "../SharedComponents/Box";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";

const OfferDetails = () => {
  let demoData = [
    {
      id: 1,
      discuntedOfferID: 1,
      discountedDate: "2024-07-08T00:00:00",
      discountedPercentage: 10,
      discountedAmount: 0,
      branchId: 0,
      branchId: "",
    },
  ];

  const backendUrl = process.env.REACT_APP_API_URL;
const [fetchedData, setFetchedData] = useState(demoData);
  useEffect(() => {
    axios
      .get(`${backendUrl}AdditionalCost`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl]);

  // input fields handle.
  const initialValue = {
    id: "",
    discuntedOfferID: "",
    discountedDate: "",
    discountedPercentage: "",
    discountedAmount: "",
    branchId: "",
    branchId:""
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };
  const { handleDataReload } = useContext(contextProvider);
  const formHandle = (e) => {
    e.preventDefault();
    console.log({ ...formData });

    axios
      .post(`${backendUrl}AdditionalCost`, { ...formData })

      .then((res) => {
        console.log(res);
        setWillUpdate(false);
        handleDataReload();
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <div>
      <Box top>
        <SetupTittle text="Discounted offer details" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupDropDown
                options={["item-1", "item-2"]}
                placeHolder="Discounted offer name"
                name="companyId"
              ></SetupDropDown>
              <SetupInput
                name="discountedDate"
                type="date"
                placeHolder="Discounted date"
                allTimeValue={formData.discountedDate}
                valueUpdate={(e) => update({ discountedDate: e.target.value })}
              />
              <SetupInput
                name="discountedPercentage"
                type="number"
                placeHolder="Discounted percentage"
                allTimeValue={formData.discountedPercentage}
                valueUpdate={(e) =>
                  update({ discountedPercentage: e.target.value })
                }
              />
              <SetupInput
                name="discountedAmount"
                type="number"
                placeHolder="Discounted amount"
                allTimeValue={formData.discountedAmount}
                valueUpdate={(e) =>
                  update({ discountedAmount: e.target.value })
                }
              />

              <SetupDropDown // need to change
                dataOf="branch"
                allTimeValue={formData.branchId}
                valueUpdate={(e) => update({ branchId: e.target.value })}
                placeHolder="Branch"
                name="branchId"
              ></SetupDropDown>
            </div>
            <SubmitBtn text={willUpdate ? "Update" : "Create"} />
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            editCallBack={(data) => {
              update(data);
              setWillUpdate(true);
            }}
            fetchedData={fetchedData}
            headers={[
              "Discounted offer name",
              "Discounted date",
              "Discounted percentage",
              "Discounted amount",
              "Branch",
            ]}
            row={[
              "discuntedOfferID",
              "discountedDate",
              "discountedPercentage",
              "discountedAmount",
              "branchId",
            ]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default OfferDetails;

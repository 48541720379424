import React from "react";
import { useNavigate } from "react-router-dom";

const EmployeePrivateRoute = ({ children }) => {
    const move=useNavigate()
  const localdata = localStorage.getItem("sundorbonBookingUserDetails");
  const localStorageObj = JSON.parse(localdata);
  console.log(localStorageObj);


  if (localStorageObj?.Type !== "Employee") {
    return children;
  } else {
    return (
      <div className="flex justify-center items-center min-h-screen flex-col gap-3">
        <h1 className="text-4xl font-semibold">You Are Not Authorized.</h1>
        <button className="btn btn-primary" onClick={()=>move("/")}>Home</button>
      </div>
    );
  }
};

export default EmployeePrivateRoute;

import React, { useContext, useEffect, useState } from "react";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";
import formateText from "../../../utils/Formate";
import { Autocomplete, Box, TextField } from "@mui/material";

const SetupAutoCompleteMultLIne = ({
  placeHolder,
  name,
  type,
  allTimeValue,
  valueUpdate,
  options,
  readOnly,
  dataOf,
  
}) => {
  const configuration = {};
  if (options) {
    configuration.defaultValue = options[0];
  }

  const { boxName, agent, AgentRequisition } = useContext(contextProvider);


  //autocomplete options configuration.
  const optionsConfiguration = () => {
    if (dataOf === "agentRequisition") {
        
      return AgentRequisition;
    }
    if (dataOf === "boxName") {
      return boxName;
    }
    if (dataOf === "agent") {
      return agent;
    }

    return [];
  };

  const labelsConfiguratio = () => {
    if (dataOf === "boxName") {
      return (lebel) => lebel.dimensionName;
    }
    if (dataOf === "agent") {
      return (lebel) => lebel.name;
    }
    if (dataOf === "agentRequisition") {
      return (lebel) => lebel.recordSerialNo;
    }
  };

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

useEffect(()=>{
  if(allTimeValue===""){
    setValue(null);
    setInputValue("");
  }
},[allTimeValue])

  return (
    <div className=" w-full min-h-20 ">
      <label className=" font-semibold" htmlFor={placeHolder}>
        {formateText(placeHolder)}
      </label>

      <div className="mt-3">
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            valueUpdate(event,newValue)
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={labelsConfiguratio()}
          sx={{
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.MuiAutocomplete-fullWidth": {
              border: "3px solid #83430D",
              height: "50px",
              borderRadius: "8px",
            },
          }}
          // {...configuration}
          disablePortal
          id="combo-box-demo"
          options={optionsConfiguration()||[]}
          fullWidth={true}


          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <Box
                key={key}
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...optionProps}
              >
                <div>
                    <h1 className="font-bold">Record Serial Number: {option.recordSerialNo}</h1>
                    <div className="flex items-center gap-2">
                    <h1 className="text-sm text-gray-400"><span className="font-semibold text-gray-400">Agent Name:</span> {option.agentName}</h1>
                    <h1 className="text-sm text-gray-400"><span className="font-semibold text-gray-400">Sub-branch Name:</span> {option.subBranchName}</h1>
                    </div>
                </div>
                
              </Box>
            );
          }}




          renderInput={(params) => (
            <TextField
              placeholder={"Select One"}
              inputProps={{ ...params.inputProps, readOnly: readOnly }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
              {...params}
            />
          )}
        />
      </div>
    </div>
  );
};

export default SetupAutoCompleteMultLIne;

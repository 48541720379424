import React, { useState } from "react";
import Box from "../../../setupPage/SharedComponents/Box";
import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import SubmitBtn from "../../../setupPage/SharedComponents/SubmitBtn";
import SetupDropDown from "../../../setupPage/SharedComponents/SetupDropdown";
import SetupInput from "../../../setupPage/SharedComponents/SetupInput";
import SetupAutoComplete from "../../../setupPage/SharedComponents/SetupAutoComplete";

const Pay = () => {
  const [agent, setAgent] = useState(null);
  console.log(agent);
  return (
    <>
      <Box top>
        <SetupTittle text="Payment" />
        <SetupContainer>
          <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
            <SetupAutoComplete
              options={[{ label: "Moving box", id: 1 }]}
              placeHolder="Select agent"
              dataOf="agent"
              valueUpdate={(_, value) => setAgent(value)}
            />
          </div>

          {agent && (
            <div className="grid mt-4 lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <div className="bg-[#e8e6d4] p-2 w-full min-h-[350px] rounded-lg">
                <h1 className="text-3xl text-center font-medium">
                  Agent Details
                </h1>
                <div className="px-16 mt-16">
                  <h1 className="flex items-center justify-between">
                    <span className="font-normal text-gray-500 text-lg">
                      Name
                    </span>{" "}
                    <span className="font-semibold">{agent?.name}</span>
                  </h1>
                  <h1 className="flex items-center justify-between">
                    <span className="font-normal text-gray-500 text-lg">
                      Address
                    </span>{" "}
                    <span className="font-semibold">{agent?.address}</span>
                  </h1>
                  <h1 className="flex items-center justify-between">
                    <span className="font-normal text-gray-500 text-lg">
                      Phone
                    </span>{" "}
                    <span className="font-semibold">{agent?.mobileNo}</span>
                  </h1>
                  <h1 className="flex items-center justify-between">
                    <span className="font-normal text-gray-500 text-lg">
                      Commission Percentage
                    </span>{" "}
                    <span className="font-semibold">{agent?.comissionPercentage} %</span>
                  </h1>
                  <h1 className="flex items-center justify-between">
                    <span className="font-normal text-gray-500 text-lg">
                      Fixed Commission Amount
                    </span>{" "}
                    <span className="font-semibold">{agent?.fixedCommisionAmount} Tk</span>
                  </h1>
                </div>
              </div>
            </div>
          )}

          {/* <div className="grid mt-4 lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
            <SetupInput name="startDate" type="date" placeHolder="Start Date" />

            <SetupInput name="endDate" type="date" placeHolder="End Date" />
          </div>

          <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
            <div className="bg-[#e8e6d4] mt-4 p-2 w-full min-h-[350px] rounded-lg">
              <h1 className="text-3xl text-center font-medium">
                Payment Details
              </h1>
              <div className="px-16 mt-16">
                <h1 className="flex items-center justify-between">
                  <span className="font-normal text-gray-500 text-lg">
                    Payment Method
                  </span>{" "}
                  <span className="font-semibold">Card</span>
                </h1>
                <h1 className="flex items-center justify-between">
                  <span className="font-normal text-gray-500 text-lg">
                    Card Name
                  </span>{" "}
                  <span className="font-semibold">Visa</span>
                </h1>
                <h1 className="flex items-center justify-between">
                  <span className="font-normal text-gray-500 text-lg">
                    Transection Id
                  </span>{" "}
                  <span className="font-semibold">02145246352</span>
                </h1>
                <h1 className="flex items-center justify-between">
                  <span className="font-normal text-gray-500 text-lg">
                    Transection Date
                  </span>{" "}
                  <span className="font-semibold">12/5/2024</span>
                </h1>
                <div className="mt-4">
                  <h1 className="flex items-center justify-between">
                    <span className="font-normal text-gray-500 text-lg">
                      Paid
                    </span>{" "}
                    <span className="font-semibold">200 Tk</span>
                  </h1>
                  <h1 className="flex items-center justify-between">
                    <span className="font-normal text-gray-500 text-lg">
                      Due
                    </span>{" "}
                    <span className="font-semibold">100 Tk</span>
                  </h1>
                  <div className="border-b-2 border-[#83420da9]"></div>
                  <h1 className="flex items-center justify-between">
                    <span className="font-normal text-gray-500 text-lg">
                      Total
                    </span>{" "}
                    <span className="font-semibold">300 Tk</span>
                  </h1>
                </div>
              </div>
            </div>
          </div> */}
        </SetupContainer>
      </Box>
    </>
  );
};

export default Pay;

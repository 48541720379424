import React from 'react';
import AsideNav from './asideNav/AsideNav';
import { Outlet } from 'react-router-dom';

const SetupPage = () => {

    return (
      
          <div className='flex flex-col lg:flex-row bg-[#FFFDE7] items-start min-h-screen gap-8'>
          <div className='lg:w-[16%] w-full lg:h-[80vh] lg:rounded-[36px] sticky top-0 lg:top-[10%] left-5 bg-[#424242]'>
          <AsideNav></AsideNav>
          </div>
          <div className="w-full pt-[100px] lg:px-[120px] min-h-screen">
          <Outlet/>
          </div>
        </div>
     
    );
};

export default SetupPage;
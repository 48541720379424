import React, { useContext, useEffect, useState } from "react";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupTittle from "../SharedComponents/SetupTittle";
import Box from "../SharedComponents/Box";
import SetupInput from "../SharedComponents/SetupInput";
import SetupDropDown from "../SharedComponents/SetupDropdown";
import SetupRadio from "../SharedComponents/SetupRadio";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import HistoryTable from "../SharedComponents/HistoryTable";
import axios from "axios";
import SetupTextArea from "../SharedComponents/SetupTextArea";
import Swal from "sweetalert2";
import GetLocalStorageUserId from "../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";

const WeightSetup = () => {
  
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(`${backendUrl}Weight`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl,refetch]);

  // input fields handle.
  const initialValue = {
    companyId: 0,
    weightDescription: "",
    minimumWeight: "",
    maximumWeight: "",
    measurementUnitId: "",
    cost: "",
    isActive: "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    branchId: "",
    branchName: "string",
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };
  const { handleDataReload } = useContext(contextProvider);
  const formHandle = (e) => {
    e.preventDefault();
    console.log({ ...formData });

    if (!willUpdate) {
      axios
        .post(`${backendUrl}Weight`, { ...formData })

        .then((res) => {
          console.log(res)
          if (res.status === 201) {
            Swal.fire({
              title: `Weight Created Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
          
        })
        .catch((err) => console.log(err.message));
    }

    if (willUpdate) {
      console.log(formData)
      axios
        .put(`${backendUrl}Weight/${formData.id}`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 204) {
            Swal.fire({
              title: `Weight Updated Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err.message));
    }

  };

  return (
    <div>
      <Box top>
        <SetupTittle text="Weight" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
               <SetupInput
                name="weightDescription"
                type="text"
                placeHolder="Weight description"
                allTimeValue={formData.weightDescription}
                valueUpdate={(e) =>
                  update({ weightDescription: e.target.value })
                }
              />
              {/* <SetupDropDown
                dataOf="company"
                allTimeValue={formData.companyId}
                valueUpdate={(e) => update({ companyId: e.target.value })}
                placeHolder="Company"
                name="branchId"
              ></SetupDropDown> */}
             
              <SetupInput
                name="MaximumWeight"
                type="number"
                placeHolder="Maximum weight(kg)"
                allTimeValue={formData.maximumWeight}
                valueUpdate={(e) => update({ maximumWeight: e.target.value })}
              />
              <SetupInput
                name="MinimumWeight"
                type="number"
                placeHolder="Minimum weight(kg)"
                allTimeValue={formData.minimumWeight}
                valueUpdate={(e) => update({ minimumWeight: e.target.value })}
              />
              <SetupDropDown
                dataOf="measurementUnit"
                allTimeValue={formData.measurementUnitId}
                valueUpdate={(e) =>
                  update({ measurementUnitId: e.target.value })
                }
                placeHolder="Measurement unit"
                name="MeasurementUnitId"
              ></SetupDropDown>

              <SetupInput
                name="Cost"
                type="number"
                placeHolder="Cost"
                allTimeValue={formData.cost}
                valueUpdate={(e) => update({ cost: e.target.value })}
              />

              <SetupDropDown // need to change
                dataOf="branch"
                allTimeValue={formData.branchId}
                valueUpdate={(e) => update({ branchId: e.target.value })}
                placeHolder="Branch"
                name="branchId"
              ></SetupDropDown>
              <SetupRadio
                name="isActive"
                placeHolder="Weight status?"
                allTimeValue={formData.isActive}
                valueUpdate={(data) => update({ isActive: data })}
              />
            </div>
            
            <SubmitBtn text={willUpdate ? "Update" : "Create"} />
            <SubmitBtn
              onClick={() => {
                setWillUpdate(false);
                update(initialValue);
              }}
              styleClass={!willUpdate && "hidden"}
              text="Cancel"
            />
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            editCallBack={(data) => {
              update(data);
              setWillUpdate(true);
            }}
            fetchedData={fetchedData}
            headers={[
              "Weight description",
              "Company",
              "Maximum weight(kg)",
              "Minium weight(kg)",
              "Measurement unit",
              "Cost",
              "Branch",
              "Status",
            ]}
            row={[
              "weightDescription",
              "companyId",
              "maximumWeight",
              "minimumWeight",
              "measurementUnitId",
              "cost",
              "branchName",
              "isActive",
            ]}
            extraFields={["branchId"]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default WeightSetup;

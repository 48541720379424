import React, { useContext, useRef, useState } from "react";
import Box from "../../../setupPage/SharedComponents/Box";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
import barcodeImg from "../../../../assets/images/dashboard/barcode.png";
import SetupDropDown from "../../../setupPage/SharedComponents/SetupDropdown";
import SubmitBtn from "../../../setupPage/SharedComponents/SubmitBtn";
import { useReactToPrint } from "react-to-print";
import { ReactBarcode } from "react-jsbarcode";
import { Autocomplete, TextField } from "@mui/material";
import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";
import AgentBoxAssign from "../AgentBoxAssign/AgentBoxAssign";

const PrintBoxSerialBarCode = () => {
  // print handle.
  const contentToPring = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "booking barcode",
    removeAfterPrint: true,
  });

  // generate barcode.
  const initialValue = {
    barcode: "",
  };

  const [formData, setFormData] = useState(initialValue);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };

  const { parcelNumber,agetntBoxAssign } = useContext(contextProvider);
console.log(agetntBoxAssign)
  // parcel number handle.
  const [barcodeNumber, setBarcodeNumber] = useState(null);
  const ParcelNumberHandle = (event, value, reason) => {
    value = parseInt(value);
    const barcodeNumber = parcelNumber?.find(
      (item) => item.percelNumber === value
    )?.barcode;
    setBarcodeNumber(barcodeNumber);
    setShowBarcode(false);
  };

  // barcode handle.
  const autoCompleteHandle=(_,value)=>{
   
    setBarcodeNumber(value?.boxSerialNo)
  }

  // barcode generate btn handle.
  const [showBarcode, setShowBarcode] = useState(false);
  return (
    <div>
      <Box top>
        <SetupTittle text="Print Box Serial Barcode" />
        <SetupContainer>
          <div className="flex justify-center">
            <div className="flex w-full items-center justify-center flex-col gap-8">
              <div className="flex items-center justify-center w-full  lg:flex-row flex-col gap-4">
                <div className="lg:w-[500px] w-full">
                  <Autocomplete
                    onChange={autoCompleteHandle}
                    options={agetntBoxAssign || []}
                    getOptionLabel={(option) => option.barcodeSerialNoWithAgentName}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Box Serial Number" />
                    )}
                  />
                </div>
                <div className="flex gap-x-2">
                  <button
                    onClick={() => setShowBarcode(true)}
                    className=" btn bg-[#544642] text-white text-lg "
                  >
                    Generate Barcode
                  </button>
                  <button
                    onClick={() =>
                      handlePrint(null, () => contentToPring.current)
                    }
                    className={`${
                      showBarcode && barcodeNumber
                        ? "btn bg-[#544642] text-white text-lg"
                        : "hidden"
                    }`}
                  >
                    Print
                  </button>
                </div>
              </div>

              <div className="w-full flex justify-center items-center overflow-x-auto ">
                <div
                  className={`${
                    showBarcode && barcodeNumber
                      ? "block overflow-x-auto"
                      : "hidden"
                  }`}
                  ref={contentToPring}
                >
                  <ReactBarcode value={barcodeNumber ? barcodeNumber : 0} />
                </div>
                {/* <div className={`${formData.barcode===""?"hidden":" py-9 bg-[#a09f973d]  rounded-lg flex flex-col justify-center items-center mx-auto"}`}>
               
                
                
                <SubmitBtn   text="Print"></SubmitBtn>
              </div> */}
              </div>
            </div>
          </div>
        </SetupContainer>
      </Box>
    </div>
  );
};

export default PrintBoxSerialBarCode;

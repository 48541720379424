import React, { useContext, useEffect, useState } from "react";
import Box from "../../../setupPage/SharedComponents/Box";
import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import SubmitBtn from "../../../setupPage/SharedComponents/SubmitBtn";
import SetupDropDown from "../../../setupPage/SharedComponents/SetupDropdown";
import SetupInput from "../../../setupPage/SharedComponents/SetupInput";
import HistoryTable from "../../../setupPage/SharedComponents/HistoryTable";
import SetupAutoComplete from "../../../setupPage/SharedComponents/SetupAutoComplete";
import SetupTextArea from "../../../setupPage/SharedComponents/SetupTextArea";
import axios from "axios";
import Swal from "sweetalert2";
import SetupAutoCompleteMultLIne from "../../../setupPage/SharedComponents/SetupAutoCompleteMultLIne";
import formateText from "../../../../utils/Formate";
import { EntryNumberGenarator } from "../../../sharedComponents/CommonMethods";
import GetLocalStorageUserId from "../../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";

const Issue = () => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [issueNumber, setIssueNumber] = useState(null);

  useEffect(() => {
    // fetch("/demo.json")
    //   .then((res) => res.json())
    //   .then((data) => setFetchedData(data));
    axios
      .get(`${backendUrl}Issue/GetAllIssue`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
    axios
      .get(`${backendUrl}Issue/GetNextIssueNo`)
      .then((res) => setIssueNumber(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // get local storage data.
  const localdata = localStorage.getItem("sundorbonBookingUserDetails");
  const localStorageObj = JSON.parse(localdata);

  // input fields handle.
  const initialValue = {
    issueDate: "",
    issueNo: 0,
    issuedQty: "",
    dimensionId: "",
    agentRequisitionNo: "",
    issuedPrice: "",
    issuedBy: localStorageObj?.Id || "",
    remarks: "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    boxName: "string",
    recordSerialNo: "string",
  };

  const [formData, setFormData] = useState(initialValue);

  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    console.log(data,"first data.")
    setFormData({ ...formData, ...data });
  };

  useEffect(() => {
    if (issueNumber) update({ recordSerialNo: EntryNumberGenarator(issueNumber,"SBUK-SI-")});
  }, [issueNumber]);

  const{handleDataReload}=useContext(contextProvider)
  const formHandle = (e) => {
    e.preventDefault();
    formData.issueDate = new Date(formData.issueDate);
    console.log({ ...formData });

    axios
      .post(`${backendUrl}Issue`, { ...formData })

      .then((res) => {
        if (res.status === 201) {
          Swal.fire({
            title: `Booking Issued Successfully `,
            icon: "success",
          });
          setRefetch((prev) => !prev);
          setFormData(initialValue);
          setWillUpdate(false);
          handleDataReload()
        }
      })
      .catch((err) => console.log(err.message));
  };

  const boxNameHandle = (e) => {
    // update({ dimensionId: e})

    if (!e) return update({ issuedPrice: "",dimensionId:"" });
    axios
      .get(`${backendUrl}Dimension/GetDimensionPriceById/${e}`)

      .then((res) => {
        
        update({ issuedPrice: res.data,dimensionId:e });
      })
      .catch((err) => console.log(err.message));
  };

  const[agentBoxDetails,setAgentBoxDetails]=useState(null)
  


  useEffect(()=>{
    axios
      .get(`${backendUrl}AgentBoxAssign/AgentBoxAssignDetailsByAgentId/${localStorageObj?.Id}`)
      .then((res) => {
        console.log(res.data,"this is response of agentbox assigndetailsssssssssss.")
        
        const currentMonth=[]
        const previousMonth=[]
        res.data?.forEach(item=>{
          const{boxAssignPreviousMonth,limitPreviousMonth,...rest}=item
          currentMonth.push(rest)
        })
        res.data?.forEach(item=>{
          const{boxAssignCurrentMonth,limitCurrentMonth,currentStock,issueQty,...rest}=item
          previousMonth.push(rest)
        })
        setAgentBoxDetails({currentMonth,previousMonth})
      
      })
      .catch((err) => console.log(err.message));
  },[])

  const TableConfig = {
    "agent Name":"agentName",
    "sub Branch Name":"subBranchName",
      "box Name":"boxName",
      "box Serial No":"boxSerialNo",
      "box Qty":"boxQty",
     
    }

  // const tableData = [
  //   {
  //     header: "box Name",
  //     property: "boxName",
  //   },
  //   {
  //     header: "agent Name",
  //     property: "agentName",
  //   },
  //   {
  //     header: "agent Id",
  //     property: "agentId",
  //   },
  //   {
  //     header: "box Serial No",
  //     property: "boxSerialNo",
  //   },
  //   {
  //     header: "box Qty",
  //     property: "boxQty",
  //   },
   
  // ];
  return ( 
    <div>
      <Box top>
        <SetupTittle text="Issue" />
        <SetupContainer>
          <form onSubmit={formHandle} className="">
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              {/* <SetupInput
                type="number"
                placeHolder="Requisition no"
                name="RequisitionNo"
                // allTimeValue={formData.itemName}
                // valueUpdate={(e) => update({ itemName: e.target.value })}
              /> */}
              <SetupInput
                type="date"
                placeHolder="Issue date"
                name="IssueDate"
                allTimeValue={formData.issueDate}
                valueUpdate={(e) => update({ issueDate: e.target.value })}
              />
              <SetupInput
                disabled
                type="text"
                placeHolder="issue serial Number"
                name="IssueDate"
                allTimeValue={formData.recordSerialNo}
                // valueUpdate={(e) => update({ issueNumber: e.target.value })}
              />

              {/* <SetupAutoComplete
                options={[{ label: "55", id: 1 }]}
                readOnly
                placeHolder="Issue Number"
              /> */}
              <SetupAutoCompleteMultLIne // need to update.
                readOnly
                dataOf="agentRequisition"
                placeHolder="Requisition Serial Number"
                valueUpdate={(_, value) => {
                    update({ agentRequisitionNo: value?.requisitionNo });
                }}
                allTimeValue={formData.agentRequisitionNo}
              />

              {/* <SetupAutoComplete
                dataOf="agent"
                valueUpdate={(_, value) => {
                  if (value) update({ issuedBy: value?.userId });
                }}
                placeHolder="Issued By"
              /> */}
              <SetupInput
                disabled
                type="text"
                placeHolder="Issued By"
                name="IssueDate"
                allTimeValue={localStorageObj?.Name || ""}
              />

              <SetupAutoComplete
                dataOf="boxName"
                valueUpdate={(_, value) => {
                  boxNameHandle(value?.id);
                }}
                allTimeValue={formData.dimensionId}
                placeHolder="Box Name"
              />
              <SetupInput
                type="number"
                placeHolder="Issued Quantity"
                name="IssuedQty"
                allTimeValue={formData.issuedQty}
                valueUpdate={(e) => update({ issuedQty: e.target.value })}
              />
              <SetupInput
                disabled
                type="number"
                placeHolder="Issued Price"
                name="IssuedPrice"
                allTimeValue={formData.issuedPrice}
                // valueUpdate={(e) => update({ issuedPrice: e.target.value })}
              />
              {/* <SetupDropDown // need to change
                // dataOf="branch"
                // allTimeValue={formData.branchId}
                // valueUpdate={(e) => update({ branchId: e.target.value })}
                placeHolder="Issued by"
                name="IssuedBy"
              ></SetupDropDown> */}

              <SetupTextArea
                placeHolder="Remarks"
                name="boxName"
                allTimeValue={formData.remarks}
                valueUpdate={(e) => update({ remarks: e.target.value })}
              />
            </div>

            <div className="flex items-center gap-4 ">
              <SubmitBtn text="Save" />
              <SubmitBtn
              styleClass={formData?.agentRequisitionNo?"":"hidden"}
                onClick={() =>
                  document.getElementById("my_modal_3").showModal()
                }
                type="button"
                text="History"
              />
            </div>
          </form>
        </SetupContainer>
      </Box>

      <dialog id="my_modal_3" className="modal">
        <div className="modal-box lg:max-w-[60%] lg:h-[650px]"> 
          <form className=" mb-4" method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>

          <h1 className="lg:font-bold font-medium text-lg lg:text-xl lg:mb-6 mb-3">Current Month</h1>
           <HistoryTable
            
            notSearchAble
            notEditAble
            // clearActiveRow={!willUpdate ? true : false}
            // editCallBack={(data) => {
            //   console.log(data)
            //   update(data);
            //   setWillUpdate(true);
            // }}
            fetchedData={agentBoxDetails?.currentMonth}
            headers={["agent Name","box Name","box Assign Current Month","limit Current Month","current Stock","issue quantity"]}
            row={["agentName","boxName","boxAssignCurrentMonth","limitCurrentMonth","currentStock","issueQty"]}
          />

          <h1 className="lg:font-bold font-medium text-lg lg:text-xl lg:mb-6 mb-3 lg:mt-9 mt-5">Previous Month</h1>
           <HistoryTable
            
            notSearchAble
            notEditAble
            // clearActiveRow={!willUpdate ? true : false}
            // editCallBack={(data) => {
            //   console.log(data)
            //   update(data);
            //   setWillUpdate(true);
            // }}
            fetchedData={agentBoxDetails?.previousMonth}
            headers={["agent Name","box Name","box Assign Previous Month","limit Previous Month"]}
            row={["agentName","boxName","boxAssignPreviousMonth","limitPreviousMonth"]}
          />





          
          {/* {tableData&&agentBoxDetails&&tableData?.map((item, idx) => {
              return (
                <div
                  className={`py-2 ${
                    idx % 2 === 0 ? "bg-white" : "bg-gray-100"
                  }`}
                  key={idx}
                >
                  <div className="px-3 flex justify-between items-center">
                    {" "}
                    <span className="">{formateText(item.header)}</span>{" "}
                    <span className="font-bold">
                      {agentBoxDetails[item.property]} 
                    </span>
                  </div>
                </div>
              );
            })} */}
        </div>
      </dialog>

      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <div className="flex justify-end mb-4"></div>
          <HistoryTable
            // editCallBack={(data) => {
            //   update(data);
            //   setWillUpdate(true);
            // }}
            notEditAble
            fetchedData={fetchedData}
            headers={[
              "Issue Date",
              "Issue Number",
              "Requisition Number",
              "Issued By",
              "Box Name",
              "Issued Quantity",
              "Issued Price",
              "Remarks",
            ]}
            row={[
              "issueDate",
              "issueNumber",
              "agentRequisitionNumber",
              "issuedBy",
              "dimensionId",
              "issuedQty",
              "issuedPrice",
              "remarks",
            ]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default Issue;

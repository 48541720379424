import React, { useContext, useEffect, useState } from "react";
import Box from "../../../setupPage/SharedComponents/Box";
import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import SubmitBtn from "../../../setupPage/SharedComponents/SubmitBtn";
import SetupDropDown from "../../../setupPage/SharedComponents/SetupDropdown";
import SetupInput from "../../../setupPage/SharedComponents/SetupInput";
import SetupAutoComplete from "../../../setupPage/SharedComponents/SetupAutoComplete";
import SetupTextArea from "../../../setupPage/SharedComponents/SetupTextArea";
import HistoryTable from "../../../setupPage/SharedComponents/HistoryTable";
import SetupRadio from "../../../setupPage/SharedComponents/SetupRadio";
import axios from "axios";
import Swal from "sweetalert2";
import GetLocalStorageUserId from "../../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";
const AgentBoxAssign = () => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState(null);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(`${backendUrl}AgentBoxAssign`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // get local storage data.
  const localdata = localStorage.getItem("sundorbonBookingUserDetails");
  const localStorageObj = JSON.parse(localdata);

  // input fields handle.
  const initialValue = {
    agentId: "",
    agentName: "",
    dimensionId: "",
    boxName: "",
    boxSerialNo: "",
    boxQty: "",
    isActive: "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    subBranchId: "",
    subBranchName: "",
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };
  const { handleDataReload } = useContext(contextProvider);
  const formHandle = (e) => {
    e.preventDefault();
    formData.requisitionDate = new Date(formData.requisitionDate);
    console.log({ ...formData });

    axios
      .post(`${backendUrl}AgentBoxAssign`, {
        ...formData,
      })

      .then((res) => {
        if (res.status === 201) {
          Swal.fire({
            title: `Agent Box Assigned Successfully`,
            icon: "success",
          });
          setRefetch((prev) => !prev);
          setFormData(initialValue);
          setWillUpdate(false);
          handleDataReload();
        }
      })
      .catch((err) => console.log(err.message));
  };

  const defaultProps = {
    options: [],
    getOptionLabel: (option) => option.title,
  };
  return (
    <div>
      <Box top>
        <SetupTittle text="Agent Box Assign" />
        <SetupContainer>
          <form onSubmit={formHandle} className="">
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              {/* <SetupDropDown // need to change
                // dataOf="branch"
                // allTimeValue={formData.branchId}
                // valueUpdate={(e) => update({ branchId: e.target.value })}
                placeHolder="Agent Name"
                name="IssuedBy"
              ></SetupDropDown> */}

              <SetupAutoComplete
                valueUpdate={(_, value) => {
                  if (!value) return update({ issuedPrice: "" });
                  if (value) update({ agentId: value.id });
                }}
                placeHolder="Agent Name"
                dataOf="agent"
                allTimeValue={formData.agentId}
              />
              <SetupAutoComplete
                valueUpdate={(_, value) => {
                  if (!value) return update({ dimensionId: "" });
                  if (value) update({ dimensionId: value.id });
                }}
                dataOf="boxName"
                placeHolder="Box Name"
                allTimeValue={formData.dimensionId}
              />

              {/* <SetupDropDown // need to change
                // dataOf="branch"
                // allTimeValue={formData.branchId}
                // valueUpdate={(e) => update({ branchId: e.target.value })}
                placeHolder="Box Name"
                name="IssuedBy"
                dataOf="boxName"
              ></SetupDropDown> */}

              <SetupInput
                type="number"
                placeHolder="Box Quantity"
                name="AgentName"
                allTimeValue={formData.boxQty}
                valueUpdate={(e) => update({ boxQty: e.target.value })}
              />
              <SetupAutoComplete
                valueUpdate={(_, value) => {
                  if (!value) return update({ subBranchId: "" });
                  if (value) update({ subBranchId: value.id });
                }}
                placeHolder="Sub-Branch"
                dataOf="subBranch"
                allTimeValue={formData.subBranchId}
              />
              <SetupRadio
                name="isActive"
                placeHolder="Box assign status?"
                allTimeValue={formData.isActive}
                valueUpdate={(data) => update({ isActive: data })}
              />
            </div>

            <SubmitBtn text="save" />
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            notEditAble
            // editCallBack={(data) => {
            //   update(data);
            //   setWillUpdate(true);
            // }}
            fetchedData={fetchedData}
            headers={[
              "Agent Name",
              "Box Name",
              "Box Quantity",
              "Sub-Branch",
              "Status",
            ]}
            row={[
              "agentId",
              "dimensionId",
              "boxQty",
              "subBranchName",
              "isActive",
            ]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default AgentBoxAssign;

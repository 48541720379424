import React, { useContext, useEffect, useState } from 'react';
import { contextProvider } from '../SetupPagesContextApi/SetupPagesContextApi';
import formateText from '../../../utils/Formate';
import { Autocomplete, TextField } from '@mui/material';

const SetupAutoClompleteMultiSelect = ({
    placeHolder,
    name,
    type,
    allTimeValue,
    valueUpdate,
    options,
    readOnly,
    dataOf,
  }) => {
    const configuration = {};
    if (options) {
      configuration.defaultValue = options[0];
    }
  
    const { boxName,agent,AgentRequisition,scanningPerson,
      parcelNumber,
      city,
      country,
      employee, } = useContext(contextProvider);
  
    //autocomplete options configuration.
    const optionsConfiguration = () => {
      if (dataOf === "employee") {
        return employee;
      }
      if (dataOf === "country") {
        return country;
      }
      if (dataOf === "city") {
        return city;
      }
      if (dataOf === "scanningPerson") {
        return scanningPerson;
      }
      if (dataOf === "parcelNumber") {
        return parcelNumber;
      }
      if (dataOf === "agentRequisition") {
        return AgentRequisition;
      }
      if (dataOf === "boxName") {
        return boxName;
      }
      if (dataOf === "agent") {
        return agent;
      }
  
      return []
  
      
    };
  

    const labelsConfiguratio = () => {
      if (dataOf === "employee") {
        return (lebel) => lebel.userName;
      }
      if (dataOf === "city") {
        return (lebel) => lebel.name;
      }
      if (dataOf === "country") {
        return (lebel) => lebel.name;
      }
      if (dataOf === "scanningPerson") {
        return (lebel) => lebel.scanningPersonName;
      }
      if (dataOf === "parcelNumber") {
        return (lebel) => lebel.percelNumber.toString();
      }
      if (dataOf === "boxName") {
        return (lebel) => lebel.dimensionName;
      }
      if (dataOf === "agent") {
        return (lebel) => lebel.name;
      }
      if (dataOf === "agentRequisition") {
        return (lebel) => lebel.agentName;
      }
    };
  
  
    const [value, setValue] = useState([]);
    const [inputValue, setInputValue] = useState([]);
  
  useEffect(()=>{

    if(allTimeValue?.length===0){
      setValue([]);
      setInputValue([]);
    }
  },[allTimeValue])
  
  
    return (
      <div className=" w-full min-h-20 ">
        <label className=" font-semibold" htmlFor={placeHolder}>
          {formateText(placeHolder)}
        </label>
  
        <div className="mt-3">
          <Autocomplete
          multiple
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            valueUpdate(event,newValue)
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
          
            setInputValue(newInputValue);
          }}
          
          getOptionLabel={labelsConfiguratio()||[]}
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.MuiAutocomplete-fullWidth": {
                border: "3px solid #83430D",
                height: "50px",
                borderRadius: "8px",
              },
            }}
            // {...configuration}
            disablePortal
            id="combo-box-demo"
            options={optionsConfiguration()|| []}
            fullWidth={true}
            renderInput={(params) => (
              <TextField
              
              placeholder={"Select One"}
                inputProps={{ ...params.inputProps, readOnly: readOnly }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
                {...params}
              />
            )}
          />
        </div>
  
      </div>
    );
  };

export default SetupAutoClompleteMultiSelect;
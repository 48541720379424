import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Box from "../../../setupPage/SharedComponents/Box";
import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import SetupInput from "../../../setupPage/SharedComponents/SetupInput";
import SetupDropDown from "../../../setupPage/SharedComponents/SetupDropdown";
import SetupRadio from "../../../setupPage/SharedComponents/SetupRadio";
import SubmitBtn from "../../../setupPage/SharedComponents/SubmitBtn";
import HistoryTable from "../../../setupPage/SharedComponents/HistoryTable";
import SetupTextArea from "../../../setupPage/SharedComponents/SetupTextArea";
import { LuTicket } from "react-icons/lu";
import DashboardPageTitle from "../SharedComponents/DashboardPageTitle";
import AgencyDashboardInput from "../../AgentDashboard/SharedComponent/AgencyDashboardInput";
import { Autocomplete, TextField } from "@mui/material";
import { LiaSortAmountUpAltSolid } from "react-icons/lia";
import { MdOutlineTextFields } from "react-icons/md";
import { MdCallReceived } from "react-icons/md";
import AutoCompleteBottomBorder from "../../SharedComponents/AutoCompleteBottomBorder";
import PaymentHistoryTable from "../SharedComponents/PaymentHistoryTable";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { EntryNumberGenarator } from "../../../sharedComponents/CommonMethods";
import GetLocalStorageUserId from "../../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";
import "./Receive.css"
const Receive = () => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [receiveNumber, setReceiveNumber] = useState(null);

  useEffect(() => {
    axios
      .get(`${backendUrl}Receive/GetAllReceive`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
    axios
      .get(`${backendUrl}Receive/GetNextReceiveNo`)
      .then((res) => setReceiveNumber(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // get local storage data.
  const localdata = localStorage.getItem("sundorbonBookingUserDetails");
  const localStorageObj = JSON.parse(localdata);

  // input fields handle.
  const initialValue = {
    receiveDate: "",
    receiveNo: 0,
    issueNo: "",
    dimensionId: "",
    receivedQty: "",
    receivedPrice: "",
    receivedBy: localStorageObj?.Id || "",
    remarks: "",
    agentId: localStorageObj?.Id || "",
    agentName: localStorageObj?.Name || "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    boxName: "string",
    recordSerialNo: "",
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };

  useEffect(() => {
    if (receiveNumber) 
    if (receiveNumber) update({ recordSerialNo:EntryNumberGenarator(receiveNumber,"SBUK-SR-") });
  }, [receiveNumber]);

  const{handleDataReload}=useContext(contextProvider)
  const formHandle = (e) => {
    e.preventDefault();
    if (!formData.issueNo) return toast.error("Please Select Issue No.");
    if (!formData.dimensionId) return toast.error("Please Select Box Name.");

    formData.receiveDate = new Date(formData.receiveDate);
    console.log({ ...formData });

    axios
      .post(`${backendUrl}Receive`, { ...formData })

      .then((res) => {
        console.log(res.data);
        if (res.status === 201) {
          Swal.fire({
            title: `Assigned Successfully`,
            icon: "success",
          });
          setRefetch((prev) => !prev);
          setFormData(initialValue);
          setWillUpdate(false);
          handleDataReload()
        }
      })
      .catch((err) => console.log(err.message));
  };

  const defaultProps = {
    options: [],
    getOptionLabel: (option) => option.title,
  };

  return (
    <div className="">
      <Box top>
        <DashboardPageTitle text="Receive" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <AgencyDashboardInput
                type="date"
                iconType="date"
                placeHolder="Receive Date"
                allTimeValue={formData.receiveDate}
                valueUpdate={(e) => update({ receiveDate: e })}
              />
              <AgencyDashboardInput
                disabled
                type="text"
                iconType="receive"
                placeHolder="Receive Serial Number"
                allTimeValue={formData.recordSerialNo}
                valueUpdate={(e) => e}
              />

              {/* <AgencyDashboardInput
                disabled
                type="number"
                iconType="hash"
                placeHolder="record Serial No"
                allTimeValue={formData.recordSerialNo}
                valueUpdate={(e) => e}
              /> */}

              <div className="w-full flex items-end">
                <div className="min-h-4 text-[#83430D] text-2xl p-2 shadow-xl">
                  <LuTicket />
                </div>
                <AutoCompleteBottomBorder
                  valueUpdate={(_, data) =>{
                    if(data){
                      const array=data.split("~")
                      update({ issueNo: parseInt(array[0]) })
                    }
                   
                  }}
                  dataOf="issueNumber"
                  readOnly
                  allTimeValue={formData.issueNo}
                  placeholder="Issue Number"
                />
              </div>

              <AgencyDashboardInput
                disabled
                type="text"
                iconType="receiver"
                placeHolder="Receiver Name"
                allTimeValue={localStorageObj?.Name || ""}
                valueUpdate={(e) => e}
              />

              <div className="w-full flex items-end">
                <div className="min-h-4 text-[#83430D] text-2xl p-2 shadow-xl">
                  <MdOutlineTextFields />
                </div>
                <AutoCompleteBottomBorder
                  dataOf="boxName"
                  placeholder="Box Name"
                  valueUpdate={(_, data) => update({ dimensionId: data?.id })}
                  allTimeValue={formData.dimensionId}
                />
              </div>

              <AgencyDashboardInput
                type="number"
                iconType="quantity"
                placeHolder="Received Quantity"
                allTimeValue={formData.receivedQty}
                valueUpdate={(e) => update({ receivedQty: e })}
              />
              <AgencyDashboardInput
                type="number"
                iconType="price"
                placeHolder="Received Price"
                allTimeValue={formData.receivedPrice}
                valueUpdate={(e) => update({ receivedPrice: e })}
              />
              <AgencyDashboardInput
                type="textArea"
                iconType="remark"
                placeHolder="Remark"
                allTimeValue={formData.remarks}
                valueUpdate={(e) => update({ remarks: e })}
              />
            </div>

            <SubmitBtn
              page="agentDashboard"
              text={willUpdate ? "Update" : "Save"}
            />
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupContainer>
          <SetupTittle text="history" />

          <PaymentHistoryTable // editCallBack={(data) => {
            //   update(data);
            //   setWillUpdate(true);
            // }}
            notEditAble
            fetchedData={fetchedData}
            headers={[
              "receive date",
              "receive number",
              "Issue number",
              "box name",
              "receive quantity",
              "Received Price",
              "remark",
            ]}
            row={[
              "receiveDate",
              "receiveNumber",
              "issueNumber",
              "dimensionId",
              "receivedQty",
              "receivedPrice",
              "remarks",
            ]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default Receive;

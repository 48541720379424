import React, { useContext, useEffect, useState } from "react";
import SetupTittle from "../SharedComponents/SetupTittle";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupInput from "../SharedComponents/SetupInput";
import SetupRadio from "../SharedComponents/SetupRadio";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import axios from "axios";
import SetupDropDown from "../SharedComponents/SetupDropdown";
import HistoryTable from "../SharedComponents/HistoryTable";
import Box from "../SharedComponents/Box";
import GetLocalStorageUserId from "../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";

const Function = () => {
  let demoData = [
    {
      id: "string",
      screenId: "string",
      functionId: "Id_1",
      isActive: true,
      creatorId: "creator_id_1",
      creationDate: "2024-07-09T17:10:02.887",
      modifierId: "creator_id_1",
      modificationDate: "2024-07-09T17:10:02.887",
    },
  ];

  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState(demoData);
  useEffect(() => {
    axios
      .get(`${backendUrl}Function`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl]);

  // input fields handle.
  const initialValue = {
    id: "",
    screenId: "",
    functionId: "",
    isActive: "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    branchId: "",
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };
  const { handleDataReload } = useContext(contextProvider);
  const formHandle = (e) => {
    e.preventDefault();
    console.log({ ...formData });

    axios
      .post(`${backendUrl}Function`, { ...formData })

      .then((res) => {
        console.log(res);
        setWillUpdate(false);
        handleDataReload();
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <div>
      <Box top>
        <SetupTittle text="Function" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupDropDown
                dataOf="function"
                allTimeValue={formData.companyId}
                valueUpdate={(e) => update({ companyId: e.target.value })}
                placeHolder="Function name"
                name="companyId"
              ></SetupDropDown>

              <SetupDropDown // need to change
                dataOf="branch"
                allTimeValue={formData.branchId}
                valueUpdate={(e) => update({ branchId: e.target.value })}
                placeHolder="Branch"
                name="branchId"
              ></SetupDropDown>

              <SetupRadio
                name="isActive"
                placeHolder="Fuction status?"
                allTimeValue={formData.isActive}
                valueUpdate={(data) => update({ isActive: data })}
              />
            </div>
            <SubmitBtn text="Create" />
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            editCallBack={(data) => {
              update(data);
              setWillUpdate(true);
            }}
            fetchedData={fetchedData}
            headers={["Function name", "Branch", "Status"]}
            row={["functionId", "branchId", "isActive"]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default Function;

import React, { useContext, useEffect, useState } from "react";
import formateText from "../../../utils/Formate";
import { Autocomplete, TextField } from "@mui/material";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";

const SetupAutoComplete = ({
  placeHolder,
  name,
  type,
  allTimeValue,
  valueUpdate,
  options,
  readOnly,
  dataOf,
  queryField,
}) => {
  const configuration = {};
  if (options) {
    configuration.defaultValue = options[0];
  }

  const {
    boxName,
    agent,
    AgentRequisition,
    parcelOrderNo,
    scanningPoint,
    parcelStatus,
    scanningPerson,
    parcelNumber,
    city,
    country,
    employee,
    subBranch,
    OnlyParcelNumber,
    routes,weight,additionalCost,cargoTypes,extraPackaging,itemType,Pickup,VATConfiguration,transportAgent,paymentMethod,parcelNumberWithRecord,GetAllAdminParcel
  } = useContext(contextProvider);


  //autocomplete options configuration.
  const optionsConfiguration = () => {
   
    if (dataOf === "GetAllAdminParcel") {
      return GetAllAdminParcel;
    }
    if (dataOf === "parcelNumberWithRecord") {
      return parcelNumberWithRecord;
    }
    if (dataOf === "paymentMethod") {
      return paymentMethod;
    }
    if (dataOf === "transportAgent") {
      return transportAgent;
    }
    if (dataOf === "VATConfiguration") {
      return VATConfiguration;
    }
    if (dataOf === "Pickup") {
      return Pickup;
    }
    if (dataOf === "itemType") {
      return itemType;
    }
    if (dataOf === "extraPackaging") {
      return extraPackaging;
    }
    if (dataOf === "cargoTypes") {
      return cargoTypes;
    }
    if (dataOf === "additionalCost") {
      return additionalCost;
    }
    if (dataOf === "weight") {
      return weight;
    }
    if (dataOf === "routes") {
      return routes;
    }
    if (dataOf === "employee") {
      return employee;
    }
    if (dataOf === "country") {
      return country;
    }
    if (dataOf === "city") {
      return city;
    }
    if (dataOf === "scanningPerson") {
      return scanningPerson;
    }
    if (dataOf === "OnlyParcelNumber") {
      return OnlyParcelNumber;
    }
    if (dataOf === "parcelNumber") {
      return parcelNumber;
    }
    if (dataOf === "parcelStatus") {
      return parcelStatus;
    }
    if (dataOf === "scanningPoint") {
      return scanningPoint;
    }
    if (dataOf === "agentRequisition") {
      return AgentRequisition;
    }
    if (dataOf === "boxName") {
      return boxName;
    }
    if (dataOf === "agent") {
      return agent;
    }
    if (dataOf === "parcelOrderNo") {
      return parcelOrderNo;
    }
    if (dataOf === "subBranch") {
      return subBranch;
    }

    return [];
  };

  const labelsConfiguratio = () => {
   
    if (dataOf === "GetAllAdminParcel") {
      return (lebel) => lebel.recordSerialNoWithParcelNo;
    }
    if (dataOf === "parcelNumberWithRecord") {
      return (lebel) => lebel.parcelOderNo;
    }
    if (dataOf === "paymentMethod") {
      return (lebel) => lebel.name;
    }
    if (dataOf === "transportAgent") {
      return (lebel) => lebel.transportAgentName;
    }
    if (dataOf === "VATConfiguration") {
      return (lebel) => lebel.configurationDescription;
    }
    if (dataOf === "Pickup") {
      return (lebel) => lebel.pickUpDescription;
    }
    if (dataOf === "itemType") {
      return (lebel) => lebel.name;
    }
    if (dataOf === "extraPackaging") {
      return (lebel) => lebel.packagingDescription;
    }
    if (dataOf === "cargoTypes") {
      return (lebel) => lebel.cargoTypeName;
    }
    if (dataOf === "additionalCost") {
      return (lebel) => lebel.additionaCostName;
    }
    if (dataOf === "weight") {
      return (lebel) => lebel.weightDescription;
    }
    if (dataOf === "routes") {
      return (lebel) => lebel.routeName;
    }
    if (dataOf === "employee") {
      return (lebel) => lebel.userName;
    }
    if (dataOf === "city") {
      return (lebel) => lebel.name;
    }
    if (dataOf === "country") {
      return (lebel) => lebel.name;
    }
    if (dataOf === "scanningPerson") {
      return (lebel) => lebel.scanningPersonName;
    }
    if (dataOf === "parcelStatus") {
      return (lebel) => lebel.parcelStatusName;
    }
    if (dataOf === "OnlyParcelNumber") {
      return (lebel) => lebel.toString();
    }
    if (dataOf === "parcelNumber") {
      return (lebel) => lebel.percelNumber.toString();
    }
    if (dataOf === "scanningPoint") {
      return (lebel) => lebel.scanningPointName;
    }
    if (dataOf === "boxName") {
      return (lebel) => lebel.dimensionName;
    }
    if (dataOf === "agent") {
      return (lebel) => lebel.name;
    }
    if (dataOf === "agentRequisition") {
      return (lebel) => lebel.agentName;
    }
    if (dataOf === "subBranch") {
      return (lebel) => lebel.subBranchName;
    }
  };

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  // console.log(value,inputValue,"form autocomplete.",allTimeValue)

  useEffect(() => {
    if (allTimeValue === "") {
      setValue(null);
      setInputValue("");
      return;
    }
    if (queryField) {
      const currentObj = optionsConfiguration()?.find(
        (item) => item[queryField] === allTimeValue
      );
      setValue(currentObj);
      // setInputValue("sdfsdfsdf");
    }
  }, [allTimeValue, dataOf]);

  return (
    <div className=" w-full min-h-20 ">
      <label className=" font-semibold" htmlFor={placeHolder}>
        {formateText(placeHolder)}
      </label>

      <div className="mt-3">
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            valueUpdate(event, newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={labelsConfiguratio()}
          sx={{
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.MuiAutocomplete-fullWidth": {
              border: "3px solid #83430D",
              height: "50px",
              borderRadius: "8px",
            },
          }}
          // {...configuration}
          disablePortal
          id="combo-box-demo"
          options={optionsConfiguration() || []}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              required
              placeholder={"Select One"}
              inputProps={{ ...params.inputProps, readOnly: readOnly }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
              {...params}
            />
          )}
        />
      </div>
    </div>
  );
};

export default SetupAutoComplete;

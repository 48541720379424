import React, { useContext, useEffect, useState } from "react";
import SetupTittle from "../SharedComponents/SetupTittle";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupInput from "../SharedComponents/SetupInput";
import SetupRadio from "../SharedComponents/SetupRadio";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import axios from "axios";
import SetupDropDown from "../SharedComponents/SetupDropdown";
import HistoryTable from "../SharedComponents/HistoryTable";
import Box from "../SharedComponents/Box";
import Swal from "sweetalert2";
import GetLocalStorageUserId from "../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";

const Offer = () => {
  
   
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios.get(`${backendUrl}DiscountedOffer`)
    .then(res=>setFetchedData(res.data))
    .catch(err=>console.log(err.message))
  }, [backendUrl,refetch]);

  // input fields handle.
  const initialValue = {
    id: "",
    routeId: "",
    cargoId: "",
    startDate: "",
    endDate: "",
    discontDescription: "",
    isActive: "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    branchId:""
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };

  console.log(formData, "Form data.");
  const { handleDataReload } = useContext(contextProvider);
  const formHandle = (e) => {
    e.preventDefault();
    console.log({ ...formData });

    if (!willUpdate) {
      axios
        .post(`${backendUrl}DiscountedOffer`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            Swal.fire({
              title: `Submission Successful`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err.message));
    }

    if (willUpdate) {
      console.log(formData);
      axios
        .put(`${backendUrl}DiscountedOffer/${formData.id}`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 204) {
            Swal.fire({
              title: `Updated Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err.message));
    }


  };

  return (
    <div>
      <Box top>
        <SetupTittle text="Discounted offer" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupDropDown
                dataOf="route"
                allTimeValue={formData.routeId}
                valueUpdate={(e) => update({ routeId: e.target.value })}
                placeHolder="Route name"
                name="companyId"
              ></SetupDropDown>
              <SetupDropDown
                dataOf="cargoName"
                allTimeValue={formData.cargoId}
                valueUpdate={(e) => update({ cargoId: e.target.value })}
                placeHolder="Cargo name"
                name="companyId"
              ></SetupDropDown>

              <SetupInput
                type="date"
                placeHolder="Start date"
                allTimeValue={formData.startDate}
                valueUpdate={(e) => {
                  update({ startDate: new Date(e.target.value) });
                }}
              />
              <SetupInput
                name="endDate"
                type="date"
                placeHolder="End date"
                allTimeValue={formData.endDate}
                valueUpdate={(e) =>
                  update({ endDate: new Date(e.target.value) })
                }
              />

              <SetupInput
                name="discountDescription"
                type="text"
                placeHolder="Discount name"
                allTimeValue={formData.discontDescription}
                valueUpdate={(e) =>
                  update({ discontDescription: e.target.value })
                }
              />
              <SetupDropDown // need to change
               dataOf="branch"
               allTimeValue={formData.branchId}
               valueUpdate={(e) => update({ branchId: e.target.value })}
               placeHolder="Branch"
               name="branchId"
              ></SetupDropDown>
              <SetupRadio
                name="isActive"
                placeHolder="Discount offer status?"
                allTimeValue={formData.isActive}
                valueUpdate={(data) => update({ isActive: data })}
              />
            </div>
            <SubmitBtn text={willUpdate ? "Update" : "Create"} />
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            editCallBack={(data) => {
              update(data);
              setWillUpdate(true);
            }}
            fetchedData={fetchedData}
            headers={[
              "Route name",
              "Cargo name",
              "Start date",
              "End date",
              "Discount name",
              "Branch",
              "Status",
            ]}
            row={[
              "routeId",
              "cargoId",
              "startDate",
              "endDate",
              "discontDescription",
              "branchName",
              "isActive",
            ]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default Offer;

import React, { useContext, useEffect, useState } from "react";
import SetupTittle from "../SharedComponents/SetupTittle";
import Box from "../SharedComponents/Box";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupInput from "../SharedComponents/SetupInput";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import ToggleBtn from "../SharedComponents/ToggleBtn";
import SetupRadio from "../SharedComponents/SetupRadio";
import HistoryTable from "../SharedComponents/HistoryTable";
import SetupAutoComplete from "../SharedComponents/SetupAutoComplete";
import axios from "axios";
import SetupDropDown from "../SharedComponents/SetupDropdown";
import Swal from "sweetalert2";
import GetLocalStorageUserId from "../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";

const Branch = () => {


  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState([]);
  console.log(fetchedData)
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(`${backendUrl}Branch`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // input fields handle.
  const initialValue = {
    branchName: "",
    companyId: 0,
    branchDiscountPercentage: "",
    branchDiscountAmount: "",
    addressLine: "",
    isActive: "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    subBranchId :"",
    subBranchName: ""
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };
  const{handleDataReload}=useContext(contextProvider)
  const formHandle = (e) => {
    e.preventDefault();
    console.log({ ...formData });


    if (!willUpdate) {
      console.log(formData);
      axios
        .post(`${backendUrl}branch`, { ...formData })

        .then((res) => {
          console.log(res, "data posted.");
          if (res.status === 201) {
            Swal.fire({
              title: `Branch Created Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload()
          }
        })
        .catch((err) => console.log(err.message));
    }

    if (willUpdate) {
      console.log(formData.id, "Form data,::");
      console.log(formData, "Form data.:::");
      axios
        .put(`${backendUrl}branch/${formData.id}`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 204) {
            Swal.fire({
              title: `Branch Updated Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload()
          }
        })
        .catch((err) => console.log(err.message));
    }


  };
  return (
    <div>
      <Box top>
        <SetupTittle text="Branch" />
        <SetupContainer>
          <form onSubmit={formHandle} className="">
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupInput
                type="text"
                placeHolder="Branch name"
                allTimeValue={formData.branchName}
                valueUpdate={(e) => update({ branchName: e.target.value })}
              />
              <SetupInput
                type="text"
                placeHolder="address Line"
                allTimeValue={formData.addressLine}
                valueUpdate={(e) => update({ addressLine: e.target.value })}
              />
              <SetupInput
                type="number"
                placeHolder="branch Discount Percentage"
                allTimeValue={formData.branchDiscountPercentage}
                valueUpdate={(e) => update({ branchDiscountPercentage: e.target.value })}
              />
              <SetupInput
                type="number"
                placeHolder="branch Discount Amount"
                allTimeValue={formData.branchDiscountAmount}
                valueUpdate={(e) => update({ branchDiscountAmount: e.target.value })}
              />
              {/* <SetupDropDown
                dataOf="company"
                allTimeValue={formData.companyId}
                valueUpdate={(e) => update({ companyId: e.target.value })}
                placeHolder="Company name"
                name="companyId"
              ></SetupDropDown> */}
              <SetupAutoComplete
                valueUpdate={(_, value) => {

                  update({ subBranchId: value?.id });
                }}
                placeHolder="Sub-Branch"
                dataOf="subBranch"
                allTimeValue={formData?.subBranchId}
                queryField='id'
              />
              <SetupRadio
                name="isActive"
                placeHolder="Branch status?"
                allTimeValue={formData.isActive}
                valueUpdate={(data) => update({ isActive: data })}
              />
            </div>
            <SubmitBtn text={willUpdate ? "Update" : "Create"} />
            <SubmitBtn
              onClick={() => {
                setWillUpdate(false);
                update(initialValue);
              }}
              styleClass={!willUpdate && "hidden"}
              text="Cancel"
            />
          </form>
        </SetupContainer>
      </Box>

      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            editCallBack={(data) => {
              update(data);
              setWillUpdate(true);
            }}
            fetchedData={fetchedData}
            headers={["Branch name", "Address Line", "Discount Percentage", "Discount Amount", "Company Name", "Sub-Branch", "Status"]}
            row={["branchName", "addressLine", "branchDiscountPercentage", "branchDiscountAmount", "companyId", "subBranchName", "isActive"]}
            extraFields={["subBranchId"]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default Branch;

import React from "react";
import delivered from "../../../../assets/images/dashboard/icon/delivered.png";
import pending from "../../../../assets/images/dashboard/icon/pending.png";
import paid from "../../../../assets/images/dashboard/icon/paid.png";
import formateText from "../../../../utils/Formate";
const UserBookingRemaining = ({ id, name, value }) => {
  const icon = [delivered, pending, paid];
  return (
    <div className="bg-gradient-to-tr min-h-[160px] from-[#E6F7F3] to-[#FAEBE9] rounded-lg shadow-xl w-full p-3">
      <div className="flex w-full justify-between items-start gap-11">
        <div>
          <h1 className="text-lg font-medium">{formateText(name)}</h1>
          <h1 className="text-6xl mt-3 font-semibold">{value}</h1>
        </div>
        <img className="w-[50px] object-contain" src={icon[id - 1]} alt="" />
      </div>
      <div
        className={
          name === "Paid" ? "hidden" : "flex items-center gap-8 mt-3"
        }
      >
        <span className="font-bold">{(value / 50) * 100}%</span>
        <div className="relative w-full border border-b-8 border-gray-200 rounded-full">
          <div
            style={{ width: `${(value / 50) * 100}%` }}
            className="absolute top-0 left-0 border border-b-4 border-green-600 rounded-full"
          ></div>
        </div>
        <span className="font-bold">{value}/50</span>
      </div>
    </div>
  );
};

export default UserBookingRemaining;

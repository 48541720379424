import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import GetLocalStorageUserId from "../../sharedComponents/GetLocalStorageuserId";
export const contextProvider = createContext();
const SetupPagesContextApi = ({ children }) => {
  // dummy data.

  // reload the data.
  const [dataReload, setDataReload] = useState(false);

  // fetch data form db to turn table field id to name.

  const [companys, setCompanys] = useState(null);
  const [branchs, setBranchs] = useState(null);
  const [routes, setRoutes] = useState(null);
  const [functions, setFunctions] = useState(null);
  const [cargoTypes, setCargoTypes] = useState(null);
  const [measurementUnits, setMeasurementUnits] = useState(null);
  const [devices, setDevices] = useState(null);
  const [parcelNumber, setParcelNumber] = useState(null);
  const [currentUserBarcodeParcelnumber, setcurrentUserBarcodeParcelnumber] =
    useState(null);
  const [parcelNumberAgent, setParcelNumberAgent] = useState(null);
  const [parcelCount, setParcelCount] = useState(null);
  const [parcelCountDimension, setParcelCountDimension] = useState(null);
  const [boxName, setBoxName] = useState(null);
  const [agent, setAgent] = useState(null);
  const [agetntBoxAssign, setagetntBoxAssign] = useState(null);
  const [issueNumber, setIssueNumber] = useState(null);
  const [AgentRequisition, setAgentRequisition] = useState(null);
  const [measurementUnit, setMeasurementUnit] = useState(null);
  const [device, setDevice] = useState(null);
  const [parcelOrderNo, setParcelOrderNo] = useState(null);
  const [scanningPoint, setScanningPoint] = useState(null);
  const [parcelStatus, setParcelStatus] = useState(null);
  const [scanningPerson, setScanningPerson] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [subBranch, setSubBranch] = useState(null);
  const [OnlyParcelNumber, setOnlyParcelNumber] = useState(null);
  const [weight, setWeight] = useState(null);
  const [additionalCost, setAdditionalCost] = useState(null);
  const [extraPackaging, setExtraPackaging] = useState(null);
  const [itemType, setItemType] = useState(null);
  const [Pickup, setPickup] = useState(null);
  const [VATConfiguration, setVATConfiguration] = useState(null);
  const [transportAgent, setTransportAgent] = useState(null);
  const [allUser, setAllUser] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [currentUserParcelNumber, setCurrentUserParcelNumber] = useState(null);
  const [parcelNumberWithRecord, setparcelNumberWithRecord] = useState(null);
  const [GetAllAgentParcel, setGetAllAgentParcel] = useState(null);
  const [GetAllAdminParcel, setGetAllAdminParcel] = useState(null);

  // when online.
  const gettingDataObj = [
    {
      url: `Parcel/GetAllParcel`,
      setterFunc: setGetAllAdminParcel,
    },
    {
      url: `Parcel/GetAllAgentParcel`,
      setterFunc: setGetAllAgentParcel,
    },
    {
      url: `Payment/GetAllPayments`,
      setterFunc: setparcelNumberWithRecord,
    },
    {
      url: `ParcelNumbersWithBarcode/AgentParcelBarcodes/${GetLocalStorageUserId()}`,
      setterFunc: setcurrentUserBarcodeParcelnumber,
    },
    {
      url: `Parcel/GetAgentParcelByAgentId/${GetLocalStorageUserId()}`,
      setterFunc: setCurrentUserParcelNumber,
    },
    {
      url: "PaymentTypeMethod",
      setterFunc: setPaymentMethod,
    },
    {
      url: "User/GetAllUsers",
      setterFunc: setAllUser,
    },
    {
      url: "TransportAgent",
      setterFunc: setTransportAgent,
    },
    {
      url: "VATConfiguration",
      setterFunc: setVATConfiguration,
    },
    {
      url: "Pickup",
      setterFunc: setPickup,
    },
    {
      url: "ItemType",
      setterFunc: setItemType,
    },
    {
      url: "ExtraPackaging",
      setterFunc: setExtraPackaging,
    },
    {
      url: "AdditionalCost",
      setterFunc: setAdditionalCost,
    },
    {
      url: "Weight",
      setterFunc: setWeight,
    },
    {
      url: "Parcel/GetAllParcelNo",
      setterFunc: setOnlyParcelNumber,
    },
    {
      url: "User/GetAllEmployee",
      setterFunc: setEmployee,
    },
    {
      url: "City",
      setterFunc: setCity,
    },
    {
      url: "Country",
      setterFunc: setCountry,
    },
    {
      url: "Device",
      setterFunc: setDevice,
    },
    {
      url: "Parcel/GetAllScanningPerson",
      setterFunc: setScanningPerson,
    },
    {
      url: "ParcelStatus",
      setterFunc: setParcelStatus,
    },
    {
      url: "ScanningPoint",
      setterFunc: setScanningPoint,
    },
    {
      url: "MeasurementUnit",
      setterFunc: setMeasurementUnit,
    },
    {
      url: "AgentRequisition/GetAllAgentRequisitionWithAgentInfo",
      setterFunc: setAgentRequisition,
    },
    {
      url: "Issue/GetAllIssueNo",
      setterFunc: setIssueNumber,
    },
    {
      url: "AgentBoxAssignment/GetAllAgentBoxAssignment",
      setterFunc: setagetntBoxAssign,
    },
    {
      url: "Agent",
      setterFunc: setAgent,
    },
    {
      url: "Dimension",
      setterFunc: setBoxName,
    },
    {
      url: "ParcelNumbersWithBarcode/ParcelBarcodes",
      setterFunc: setParcelNumber,
    },
    {
      url: "ParcelNumbersWithBarcode/AgentParcelBarcodes",
      setterFunc: setParcelNumberAgent,
    },
    {
      url: "ParcelBookingInformation/Parcelcounts",
      setterFunc: setParcelCount,
    },
    {
      url: "ParcelBookingInformation/Parcelcounts/dimensions",
      setterFunc: setParcelCountDimension,
    },
    {
      url: "company",
      setterFunc: setCompanys,
    },
    {
      url: "branch",
      setterFunc: setBranchs,
    },
    {
      url: "Route",
      setterFunc: setRoutes,
    },
    {
      url: "Function",
      setterFunc: setFunctions,
    },
    {
      url: "CargoType",
      setterFunc: setCargoTypes,
    },
    // {
    //   // url: "CargoType",
    //   setterFunc: setMeasurementUnits,
    // },
    {
      url: "Device",
      setterFunc: setDevices,
    },
    {
      url: "SenderDetails/GetAllParcelNo",
      setterFunc: setParcelOrderNo,
    },
    {
      url: "SenderDetails/GetAllParcelNo",
      setterFunc: setParcelOrderNo,
    },
    {
      url: "SubBranch",
      setterFunc: setSubBranch,
    },
  ];

  useEffect(() => {
    const backendUrl = process.env.REACT_APP_API_URL;
    gettingDataObj.forEach((item) => {
      axios
        .get(`${backendUrl + item.url}`)
        .then((res) => item.setterFunc(res.data))
        .catch((err) => console.log(err.message));
    });
  }, [dataReload]);

  const handleDataReload = () => {
    setDataReload((prev) => !prev);
  };

  const isAllDataFetched = // true    if not online.
    branchs && functions && companys && cargoTypes && routes ? true : false; // when online
  const data = {
    companys,
    isAllDataFetched,
    branchs,
    routes,
    functions,
    measurementUnits,
    devices,
    cargoTypes,
    parcelNumber,
    parcelCount,
    parcelCountDimension,
    parcelNumberAgent,
    boxName,
    agent,
    agetntBoxAssign,
    issueNumber,
    AgentRequisition,
    measurementUnit,
    device,
    parcelOrderNo,
    scanningPoint,
    parcelStatus,
    scanningPerson,
    city,
    country,
    employee,
    subBranch,
    OnlyParcelNumber,
    weight,
    additionalCost,
    extraPackaging,
    itemType,
    Pickup,
    VATConfiguration,
    currentUserParcelNumber,
    transportAgent,
    allUser,
    paymentMethod,
    parcelNumberWithRecord,
    handleDataReload,
    GetAllAgentParcel,
    GetAllAdminParcel,
    currentUserBarcodeParcelnumber,
  };

  return (
    <contextProvider.Provider value={data}>{children}</contextProvider.Provider>
  );
};

export default SetupPagesContextApi;

import React, { useContext, useEffect, useState } from "react";
import Box from "../SharedComponents/Box";
import SetupTittle from "../SharedComponents/SetupTittle";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupInput from "../SharedComponents/SetupInput";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import SetupRadio from "../SharedComponents/SetupRadio";
import SetupDropDown from "../SharedComponents/SetupDropdown";
import axios from "axios";
import HistoryTable from "../SharedComponents/HistoryTable";
import Swal from "sweetalert2";
import GetLocalStorageUserId from "../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";

const Shiping = () => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(`${backendUrl}ShippingService`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // input fields handle.
  const initialValue = {
    id: 0,
    routeId: "",
    cargoId: "",
    isExpressService: "",
    serviceName: "",
    days: "",
    shippingServiceAmount: "",
    shippingServiceAmountPercentage: "",
    isActive: "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    branchId: "",
    branchName: "string",
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };
  const { handleDataReload } = useContext(contextProvider);
  const formHandle = (e) => {
    e.preventDefault();

    if (!willUpdate) {
      axios
        .post(`${backendUrl}ShippingService`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            Swal.fire({
              title: `Shiping Service Created Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err.message));
    }

    if (willUpdate) {
      console.log(formData);
      axios
        .put(`${backendUrl}ShippingService/${formData.id}`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 204) {
            Swal.fire({
              title: `Shiping Service Updated Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err.message));
    }
  };

  return (
    <div>
      <Box top>
        <SetupTittle text="Shiping service"></SetupTittle>
        <SetupContainer>
          <form onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-x-[128px] lg:gap-y-[43px] gap-1">
              <SetupInput
                type="text"
                placeHolder="Shiping Service Name"
                allTimeValue={formData.serviceName}
                valueUpdate={(e) => update({ serviceName: e.target.value })}
              />
              <SetupInput
                type="number"
                placeHolder="Shiping Service Amount"
                allTimeValue={formData.shippingServiceAmount}
                valueUpdate={(e) =>
                  update({ shippingServiceAmount: parseInt(e.target.value) })
                }
              />
              <SetupInput
                type="number"
                placeHolder="Shiping Days"
                allTimeValue={formData.days}
                valueUpdate={(e) => update({ days: parseInt(e.target.value) })}
              />
              <SetupInput
                type="number"
                placeHolder="Shiping Service Amount Percentage"
                allTimeValue={formData.shippingServiceAmountPercentage}
                valueUpdate={(e) =>
                  update({
                    shippingServiceAmountPercentage: parseInt(e.target.value),
                  })
                }
              />

              <SetupDropDown
                name="routeName"
                placeHolder="Route name"
                dataOf="route"
                allTimeValue={formData.routeId}
                valueUpdate={(e) =>
                  update({ routeId: parseInt(e.target.value) })
                }
              ></SetupDropDown>
              <SetupDropDown
                name="cargoName"
                dataOf="cargoName"
                allTimeValue={formData.cargoId}
                valueUpdate={(e) =>
                  update({ cargoId: parseInt(e.target.value) })
                }
                placeHolder="Cargo name"
              ></SetupDropDown>
              <SetupDropDown // need to change
                dataOf="branch"
                allTimeValue={formData.branchId}
                valueUpdate={(e) =>
                  update({ branchId: parseInt(e.target.value) })
                }
                placeHolder="Branch"
                name="branchId"
              ></SetupDropDown>
              <SetupRadio
                yesNO
                name="isExpressService"
                placeHolder="is Express Service?"
                allTimeValue={formData.isExpressService}
                valueUpdate={(data) => update({ isExpressService: data })}
              />
              <SetupRadio
                name="isActive"
                placeHolder="Shiping service status?"
                allTimeValue={formData.isActive}
                valueUpdate={(data) => update({ isActive: data })}
              />
            </div>
            <div className="text-start">
              <SubmitBtn text={willUpdate ? "Update" : "Create"} />
              <SubmitBtn
                onClick={() => {
                  setWillUpdate(false);
                  update(initialValue);
                }}
                styleClass={!willUpdate && "hidden"}
                text="Cancel"
              />
            </div>
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            editCallBack={(data) => {
              update(data);
              setWillUpdate(true);
            }}
            fetchedData={fetchedData}
            headers={[
              "Shiping Service Name",
              "Shiping Service Amount",
              "Shiping Service Amount Percentage",
              "Route name",
              "Cargo name",
              "Shiping Days",
              "Branch",
              "Express Service",
              "Status",
            ]}
            row={[
              "serviceName",
              "shippingServiceAmount",
              "shippingServiceAmountPercentage",
              "routeId",
              "cargoId",
              "days",
              "branchName",
              "isExpressService",
              "isActive",
            ]}
            extraFields={["branchId"]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default Shiping;

import React, { useContext, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";
import Box from "../../../setupPage/SharedComponents/Box";
import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import { Autocomplete, TextField } from "@mui/material";
import { ReactBarcode } from "react-jsbarcode";
import DashboardPageTitle from "../../UserDashboard/SharedComponents/DashboardPageTitle";

const PrintBarCodeAgency = () => {
  // print handle.
  const contentToPring = useRef(null);
  console.log(contentToPring);
  const handlePrint = useReactToPrint({
    documentTitle: "booking barcode",
    removeAfterPrint: true,
  });

  // generate barcode.
  const initialValue = {
    barcode: "",
  };

  const [formData, setFormData] = useState(initialValue);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };

  const { currentUserBarcodeParcelnumber } = useContext(contextProvider);

  // parcel number handle.
  const [Parcels, setParcels] = useState([]);

  const ParcelNumberHandle = (event, value, reason) => {
    console.log(value);
    setParcels(value);
    setShowBarcode(false);
  };

  // barcode generate btn handle.
  const [showBarcode, setShowBarcode] = useState(false);
  return (
    <div>
      <Box top>
        <DashboardPageTitle text="Print Barcode" />
        <SetupContainer>
          <div className="flex justify-center">
            <div className="flex flex-col justify-center items-center w-full gap-8">
              <div className="flex items-center gap-x-4">
                <div className="w-[500px] flex lg:gap-2">
                  <Autocomplete
                    multiple
                    onChange={ParcelNumberHandle}
                    options={currentUserBarcodeParcelnumber || []}
                    getOptionLabel={(option) => option?.recordSerialNoWithParcelNo?option?.recordSerialNoWithParcelNo:"Not Available"}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Parcel number" />
                    )}
                  />
                  {/* <TextField value={quantity} onChange={(e)=>setQuantity(e.target.value)} id="outlined-basic" label="Quantity" variant="outlined" /> */}
                </div>
                <div className="flex  gap-x-2">
                  <button
                    onClick={() => setShowBarcode(true)}
                    className=" btn bg-[#544642] text-white text-lg "
                  >
                    Generate Barcode
                  </button>
                  <button
                    onClick={() =>
                      handlePrint(null, () => contentToPring.current)
                    }
                    className={`${
                      showBarcode && Parcels
                        ? "btn bg-[#544642] text-white text-lg"
                        : "hidden"
                    }`}
                  >
                    Print
                  </button>
                </div>
              </div>

              <div ref={contentToPring}>
                <div
                  className={`${
                    showBarcode && Parcels
                      ? " overflow-x-auto justify-center flex gap-3 flex-col items-center"
                      : "hidden"
                  }`}
                >
                  
                  {Parcels?.map((item, idx) => {
                    return (
                      <div className=" inline-flex justify-center flex-col items-center bg-white print-content">
                        <ReactBarcode className=" " value={item.barcode} />
                        <h1 className="text-base  font-semibold text-center inline-block ">
                          {item.routeName}, Pr No: {item.percelNumber}
                        </h1>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </SetupContainer>
      </Box>
    </div>
  );
};

export default PrintBarCodeAgency;

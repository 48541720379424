import React from "react";
import { FaCheck } from "react-icons/fa6";
import formateText from "../../../../utils/Formate";
const VerticalProgressBar = () => {
  return (
    <div className=" relative text-gray-700 flex flex-col lg:flex-row justify-center gap-9 items-start">
      <div className='lg:w-[84%]  h-[735px] lg:h-0 top-0 left-[85px]  border-l-2 lg:border-t-2 border-black absolute lg:top-[55px] lg:left-[120px] z-10'></div>
      {/* single steps. */}
      <div className="flex justify-center text-center flex-row lg:flex-col lg:items-center items-start gap-3 bg-transparent">
        <div className=" font-semibold text-lg text-center flex gap-2 flex-col lg:w-max w-[60px]">
          May 22 18:52
        </div>
        <div className="w-[30px] h-[30px] relative z-20 rounded-full bg-blue-600 text-white flex justify-center items-center text-xl">
          <FaCheck />
        </div>
        <div className="font-semibold text-sm w-[260px] lg:w-auto ">
          <h1 className="text-xl font-bold h-[60px]">Delivered</h1>
          <p className="mt-3 w-[max-content]">
            Your package has been delivered.
          </p>
          <button className="text-blue-600">Tap here to share a review</button>
          <h1 className=" font-bold text-black">[Thakurgaon]</h1>
        </div>
      </div>
      <div className="flex justify-center text-center flex-row lg:flex-col lg:items-center items-start gap-3 ">
        <div className=" font-semibold text-lg  text-center lg:w-max w-[60px]">May 22 18:29</div>
        <div className="w-[30px] h-[30px] rounded-full relative z-20 bg-gray-400"></div>
        <div className="font-semibold text-sm w-[260px] lg:w-auto">
          <h1 className="text-xl font-bold h-[60px]">Out for Delivery</h1>
          <p className="mt-3">
            {formateText("Our delivery partner BD-DEX will attempt to deliver your package today")} <span className=" font-bold">{formateText("[Thakurgaon]")}</span>
          </p>
        </div>
      </div>
      <div className="flex justify-center text-center flex-row lg:flex-col lg:items-center items-start gap-3">
        <div className=" font-semibold text-lg text-center lg:w-max w-[60px]">May 12 17:29</div>
        <div className="w-[30px] h-[30px] rounded-full relative z-20 bg-gray-400"></div>
        <div className="font-semibold text-sm w-[260px] lg:w-auto">
          <h1 className="text-xl font-bold h-[60px]">Shipped</h1>
          <p className="mt-3">
            {formateText("Your package is on the way to our last mile hub with tracking number DEX-BDN-CB-2205478 from where to will be delivered to you [Dhaka - North]")}
          </p>
          <h1 className=" font-bold text-black">[Thakurgaon]</h1>
        </div>
      </div>
      <div className="flex justify-center text-center flex-row lg:flex-col lg:items-center items-start gap-3">
        <div className=" font-semibold text-lg text-center lg:w-max w-[60px]">May 6 9:29</div>
        <div className="w-[30px] h-[30px] rounded-full relative z-20 bg-gray-400"></div>
        <div className=" font-semibold text-sm w-[260px] lg:w-auto">
          <h1 className="text-xl font-bold h-[60px] ">
            {formateText("Riched our Logistics Facility")}
          </h1>
          <p className="mt-3">
          {formateText("Your package has arrived at our logistic facility from where it willbe sent to the last mile hub")}
            
            <span className=" font-bold text-black">[Dhaka - North]</span>
          </p>
        </div>
      </div>
      <div className="flex justify-center text-center flex-row lg:flex-col lg:items-center items-start gap-3">
        <div className=" font-semibold text-lg text-center lg:w-max w-[60px]">May 20 18:29</div>
        <div className="w-[30px] h-[30px] rounded-full relative z-20 bg-gray-400"></div>
        <div className=" font-semibold text-sm w-[260px] lg:w-auto">
          <h1 className="text-xl font-bold h-[60px]">
            Handover To Delivery Partner
          </h1>
          <p className="mt-3">
            {formateText("Your package is being sent to our warehouse from where it'll be shipped to you.")}
          </p>
        </div>
      </div> 
    </div>
  );
};

export default VerticalProgressBar;

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Box from "../../../setupPage/SharedComponents/Box";
import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import SetupInput from "../../../setupPage/SharedComponents/SetupInput";
import SetupDropDown from "../../../setupPage/SharedComponents/SetupDropdown";
import SetupRadio from "../../../setupPage/SharedComponents/SetupRadio";
import SubmitBtn from "../../../setupPage/SharedComponents/SubmitBtn";
import HistoryTable from "../../../setupPage/SharedComponents/HistoryTable";
import SetupTextArea from "../../../setupPage/SharedComponents/SetupTextArea";
import DashboardPageTitle from "../SharedComponents/DashboardPageTitle";
import AgencyDashboardInput from "../../AgentDashboard/SharedComponent/AgencyDashboardInput";
import { LiaSortAmountUpAltSolid } from "react-icons/lia";
import { Autocomplete, TextField } from "@mui/material";
import { GoGitPullRequest } from "react-icons/go";
import { GoArrowUpRight } from "react-icons/go";
import AutoCompleteBottomBorder from "../../SharedComponents/AutoCompleteBottomBorder";
import { MdOutlineTextFields } from "react-icons/md";
import PaymentHistoryTable from "../SharedComponents/PaymentHistoryTable";
import { Container } from "postcss";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { EntryNumberGenarator } from "../../../sharedComponents/CommonMethods";
import GetLocalStorageUserId from "../../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";
import "./Requisiton.css"
const Requisition = () => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [requisitionNumber, setRequisition] = useState(null);

  useEffect(() => {
    axios
      .get(`${backendUrl}AgentRequisition/GetAllAgentRequisition`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));

    axios
      .get(`${backendUrl}AgentRequisition/GetNextRequisitionNo`)
      .then((res) => setRequisition(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // get local storage data.
  const localdata = localStorage.getItem("sundorbonBookingUserDetails");
  const localStorageObj = JSON.parse(localdata);
  console.log(localStorageObj?.Id);
  // input fields handle.
  const initialValue = {
    requisitionDate: "",
    requisitionNo: 0,
    dimensionId: "",
    requestedQty: "",
    remarks: "",
    agentId: localStorageObj?.Id || "",
    agentName: localStorageObj?.Name || "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    boxName: "string",
    recordSerialNo: "string",
    subBranchName: "string",
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);
  console.log(formData);
  const update = (data) => {
    setFormData({ ...formData, ...data });
  };

  useEffect(() => {
    if (requisitionNumber)
      update({
        recordSerialNo: EntryNumberGenarator(requisitionNumber, "SBUK-AR-"),
      });
  }, [requisitionNumber]);
  const { handleDataReload } = useContext(contextProvider);
  const formHandle = (e) => {
    e.preventDefault();
    if (!formData.dimensionId) return toast.error("Please Select Box Name.");
    formData.requisitionDate = new Date(formData.requisitionDate);
    console.log({ ...formData });

    axios
      .post(`${backendUrl}AgentRequisition`, { ...formData })

      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            title: `Requisition Successful `,
            icon: "success",
          });
          setRefetch((prev) => !prev);
          setFormData(initialValue);
          setWillUpdate(false);
          handleDataReload();
        }
      })
      .catch((err) => console.log(err.message));
  };

  const defaultProps = {
    options: [],
    getOptionLabel: (option) => option.title,
  };

  return (
    <div>
      <Box top>
        <DashboardPageTitle text="Requisition" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <AgencyDashboardInput
                type="date"
                iconType="date"
                placeHolder="Requisition Date"
                allTimeValue={formData.requisitionDate}
                valueUpdate={(e) => update({ requisitionDate: e })}
              />

              {/* <div className="w-full flex items-end">
                <div className="min-h-4 text-[#83430D] text-2xl p-2 shadow-xl">
                  <GoArrowUpRight />
                </div>
                <AutoCompleteBottomBorder
                  valueUpdate={(_, data) =>
                    update({ requisitionNo: data?.label })
                  }
                  dataOf="reqNumber"
                  readOnly
                  placeholder="Requisition Number"
                />
              </div> */}

              <AgencyDashboardInput
                disabled
                type="text"
                iconType="requisition"
                placeHolder="Requisition Serial Number"
                allTimeValue={formData.recordSerialNo}
                valueUpdate={(e) => e}
              />

              {/* <AgencyDashboardInput
              disabled
                type="number"
                iconType="hash"
                placeHolder="record Serial No"
                allTimeValue={formData.recordSerialNo}
                valueUpdate={(e) => e}
              /> */}

              <div className="w-full flex items-end">
                <div className="min-h-4 text-[#83430D] text-2xl p-2 shadow-xl">
                  <MdOutlineTextFields />
                </div>
                <AutoCompleteBottomBorder
                  valueUpdate={(_, data) => update({ dimensionId: data?.id })}
                  dataOf="boxName"
                  placeholder="Box Name"
                  allTimeValue={formData.dimensionId}
                />
              </div>

              <AgencyDashboardInput
                type="number"
                iconType="quantity"
                placeHolder="Requested Quantity"
                allTimeValue={formData.requestedQty}
                valueUpdate={(e) => update({ requestedQty: e })}
              />

              <AgencyDashboardInput
                type="textArea"
                iconType="remark"
                placeHolder="Remark"
                allTimeValue={formData.remarks}
                valueUpdate={(e) => update({ remarks: e })}
              />
            </div>
            <div className="lg:mt-[35px]"></div>

            <SubmitBtn
              page="agentDashboard"
              text={willUpdate ? "Update" : "Save"}
            />
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupContainer>
          <SetupTittle text="history" />
          <div className="text-end mb-6"></div>
          <PaymentHistoryTable // editCallBack={(data) => {
            //   update(data);
            //   setWillUpdate(true);
            // }}
            notEditAble
            fetchedData={fetchedData}
            headers={[
              "Requisition date",
              "box name",
              "Requisition quantity",
              "Requisition number",
              "remark",
            ]}
            row={[
              "requisitionDate",
              "dimensionId",
              "requestedQty",
              "recordSerialNo",
              "remarks",
            ]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default Requisition;

import { useEffect, useState } from "react";
import "./Bookings.css";
import { useMultistepForm } from "../../hooks/MultiStepForm/useMultiStepForm";
import SenderForm from "./components/SenderForm/SenderForm";
import ReceiverForm from "./components/ReceiverForm/ReceiverForm";
import ParcelForm from "./components/ParcelForm/ParcelForm";
import { BsArrowRight } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import PaymentMethod from "./components/PaymentMethod/PaymentMethod";
import ProgressBar from "./components/ProgressBar/ProgressBar";
import PickupForm from "./components/PickupForm/PickupForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShippingInformation from "./components/ShippingInformation/ShippingInformation";
import axios from "axios";
import Finalize from "./components/Finalize/Finalize";
import WhereToGo from "./components/WhereToGo/WhereToGo";
import Swal from "sweetalert2";
import { useHref, useNavigate } from "react-router-dom";
import Payment from "./components/Payment/Payment";
import GetLocalStorageUserId from "../sharedComponents/GetLocalStorageuserId";

const apiAddress = process.env.REACT_APP_API_URL;

const Bookings = ({ tittle }) => {
  // get local storage data.
  const localdata = localStorage.getItem("sundorbonBookingUserDetails");
  const localStorageObj = JSON.parse(localdata);
  console.log(localStorageObj);
  const move = useNavigate();
  const INITIAL_DATA = {
    route: "",
    senderName: "",
    senderEmail: "",
    senderMobileNumber: "",
    senderCountry: "",
    senderLandmark: "",
    pickupLandmark: "",
    senderCity: "",
    pickupCity: "",
    pickupCountry: "",
    senderAddress: "",
    receiverName: "",
    receiverEmail: "",
    receiverMobileNumber: "",
    receiverCountry: "",
    receiverLandmark: "",
    receiverCity: "",
    receiverAddress: "",
    productType: "",
    numberOfItems: "",
    parcelContent: "",
    shipmentTime: "",
    itemDescription: "",
    productValue: "",
    packaging: "",
    fragileItems: "",
    preset: "A4 Envelope",
    additionalInfo: "",
    productWeight: "1",
    productLength: "12",
    productWidth: "9",
    productHeight: "1",
    paymentTypeId: "",
    productGroup: "",
    shipmentType: "",
    IsScheduled: "",
    scheduledDate: {},
    pickupLocation: "",
    pickupWeight: "",
    pickupInstructions: "",
    pickupTimeFrom: "",
    pickupTimeTo: "",
    cargoType: "",
    shipmentArrivalDate: {},
    selectedShipmentArrivalDate: "",
    scheduledDateToggleValue: "",
    pickupTimeFromToggleValue: "",
    pickupTimeToToggleValue: "",
    pickupAsSenderCheckToggle: false,
  };

  const [data, setData] = useState(INITIAL_DATA);
  console.dir(data);
  function updateFields(fields) {
    setData((prev) => {
      return { ...prev, ...fields };
    });
  }
  console.log(tittle);
  const [transitCost, setTransitCost] = useState([]);

  console.log(transitCost);
  const [arrivalDate, setArrivalDate] = useState({});
  // fetching booking form data.
  const [arrivalDateInfo, setArivalDateInfo] = useState([]);

  const [fetchedFormData, setfetchedFormData] = useState({});
  const formKeys = fetchedFormData;

  useEffect(() => {
    /// fetching arrival date info.
    // axios.get("./dummyData.json").then((res) => setArivalDateInfo(res.data));

    // api get request url and state seter functions and ulrs.
    const getFormData = [
      {
        url: "PaymentTypeMethod",
        key: "paymentMethod",
      },
      {
        url: "Country",
        key: "country",
      },
      {
        url: "city",
        key: "city",
      },
      {
        url: "ProductType",
        key: "ProductType",
      },
      {
        url: "ParcelContent",
        key: "ParcelContent",
      },
      {
        url: "Prohibiteditem",
        key: "Prohibiteditem",
      },
      {
        url: "Cargotype",
        key: "Cargotype",
      },
      {
        url: "Route",
        key: "Route",
      },
      {
        url: "itemType",
        key: "itemType",
      },
    ];

    // Fetched form data and set to state.

    getFormData?.forEach(({ url, key }) => {
      axios
        .get(apiAddress + url)
        .then((res) => {
          formKeys[key] = res.data;
          return setfetchedFormData({ ...formKeys });
        })
        .catch((err) => {
          console.log(err.message);
        });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // api response data from shipment arrival date page.

  const [pickUpCost, setPickUpCost] = useState(0);
  const [routeCost, setRouteCost] = useState(0);
  const [itemTypeCost, setItemTypeCost] = useState(0);
  const [weightCost, setWeightCost] = useState(0);
  const [dimensionCost, setDimensionCost] = useState(0);
  const [extraPackagingCost, setExtraPackagingCost] = useState(0);
  const [cargoCost, setCargoCost] = useState(0);
  const [shippingServicePercentage, setShippingServicePercentage] = useState(0);
  const [shippingServiceAmount, setShippingServiceAmount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);
  const [vatAmountPercentage, setVatAmountPercentage] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [discountedOfferId, setDiscountedOfferId] = useState(null);
  const [shippingServiceId, setShippingServiceId] = useState(null);
  const [dimensionId, setDimensionId] = useState(null);
  const [weightId, setWeightId] = useState(null);

  // parcel barcode number.
  const [bookingBarcodeNumber, setBookingBarcodeNumber] = useState(null);

  const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    useMultistepForm([
      <WhereToGo
        {...data}
        updateFields={updateFields}
        fetchedFormData={fetchedFormData}
      />,
      <SenderForm
        {...data}
        fetchedFormData={fetchedFormData}
        updateFields={updateFields}
      />,
      <ReceiverForm
        {...data}
        fetchedFormData={fetchedFormData}
        updateFields={updateFields}
      />,
      <ParcelForm
        {...data}
        fetchedFormData={fetchedFormData}
        updateFields={updateFields}
      />,
      <PickupForm
        {...data}
        fetchedFormData={fetchedFormData}
        updateFields={updateFields}
      />,
      <ShippingInformation
        {...data}
        fetchedFormData={fetchedFormData}
        updateFields={updateFields}
        transitCost={transitCost}
      />,
      <Finalize
        {...data}
        bookingBarcodeNumber={bookingBarcodeNumber}
        fetchedFormData={fetchedFormData}
        transitCost={transitCost}
        updateFields={updateFields}
      />,
      <PaymentMethod
        fetchedFormData={fetchedFormData}
        {...data}
        updateFields={updateFields}
      />,
      <Payment/>
    ]);

  const validateFields = () => {
    const isManualFilled =
      data.productWeight &&
      data.productLength &&
      data.productWidth &&
      data.productHeight;
    if (!data.preset && !isManualFilled) {
      toast.error("Please select a preset or fill in all the fields.");
      return false;
    }
    // if (isManualFilled) {
    //     updateFields({ preset: '' }); // Reset preset if manual fields are filled
    // }
    const { scheduledDate, IsScheduled, pickupTimeTo, pickupTimeFrom } = data;

    if (
      Object.keys(scheduledDate).length <= 0 &&
      currentStepIndex === 4 &&
      IsScheduled === "yes"
    ) {
      toast.error("Please select scheduled date.");
      return false;
    } else if (
      pickupTimeFrom === "" &&
      currentStepIndex === 4 &&
      IsScheduled === "yes"
    ) {
      toast.error("Please select From time.");
      return false;
    } else if (
      pickupTimeTo === "" &&
      currentStepIndex === 4 &&
      IsScheduled === "yes"
    ) {
      toast.error("Please select scheduled To time.");
      return false;
    } else if (IsScheduled === "" && currentStepIndex === 4) {
      toast.error("You have to select at least one option.");
      return false;
    }
    return true;
  };

  function formatDate(dateObj) {
    // Helper function to get the month number from the month name
    function getMonthNumber(monthName) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return months.indexOf(monthName);
    }

    // Extract and format the year, month, and day
    let year = parseInt(dateObj?.year?.trim());
    let month = getMonthNumber(dateObj?.month); // Zero-based month index for Date object
    let day = parseInt(dateObj?.day);

    // Set a default time (12:11:39.027) or you can set it as per your requirement
    let hours = 12;
    let minutes = 11;
    let seconds = 39;
    let milliseconds = 27;

    // Create the Date object
    let date = new Date(
      Date.UTC(year, month, day, hours, minutes, seconds, milliseconds)
    );

    // Convert to ISO 8601 format and return
    return date?.toISOString();
  }

  function combineDateAndTime(dateStr, timeStr) {
    // Extract year, month, and day from the date string
    const [year, month, day] = dateStr.split("-").map(Number);

    // Extract hours and minutes from the time string
    const [hours, minutes] = timeStr.split(":").map(Number);

    // Create the Date object with the provided date and time
    const date = new Date(Date.UTC(year, month - 1, day, hours, minutes));

    // Convert to ISO 8601 format and return
    return date?.toISOString();
  }

  function formatDateToString(dateObj) {
    // Helper function to get the month number from the month name
    function getMonthNumber(monthName) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return months.indexOf(monthName) + 1;
    }

    // Extract and format the year, month, and day
    let year = dateObj?.year?.trim();
    let month = getMonthNumber(dateObj.month)?.toString()?.padStart(2, "0");
    let day = dateObj?.day?.padStart(2, "0");

    // Combine into the desired format
    return `${year}-${month}-${day}`;
  }

  // get booking page url.
  const url = useHref();
  console.log(url);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (currentStepIndex === 3 || currentStepIndex === 4)
      if (!validateFields()) return;

    if (!isLastStep) return next();

    const {
      senderName,
      senderEmail,
      senderAddress,
      senderLandmark,
      senderMobileNumber,
      receiverName,
      receiverEmail,
      receiverAddress,
      receiverLandmark,
      receiverMobileNumber,
      itemDescription,
      additionalInfo,
      pickupTimeFrom,
      pickupTimeTo,
      pickupInstructions,
      productValue,
    } = data;

    const routingTypeId = parseInt(data.route);
    const senderCityId = parseInt(data.senderCity);
    const senderCountryId = parseInt(data.senderCountry);
    const receiverCityId = parseInt(data.receiverCity);
    const receiverCountryId = parseInt(data.receiverCountry); // district is the country
    const itemTypeId = parseInt(data.productType);
    const isExtraPackaging = data.packaging === "Yes" ? true : false;
    const isPickUp = data.IsScheduled === "yes" ? true : false;
    const pickUpDate =
      data.IsScheduled === "yes" ? formatDate(data.scheduledDate) : "2024-07-24";
    const pickUpCityId =
      data.IsScheduled === "yes" ? parseInt(data.pickupCity) : 0;
    const pickUpCountryId =
      data.IsScheduled === "yes" ? parseInt(data.pickupCountry) : 0;
    const pickUpLandmark = data.senderLandmark;
    const pickUpAdditionalAddressInfo = data.senderAddress;
    const itemCategoryId = parseInt(data.productGroup);
    const cargoTypeId = parseInt(data.cargoType);
    const fromTime =
      data.IsScheduled === "yes"
        ? combineDateAndTime(
            formatDateToString(data.scheduledDate),
            pickupTimeFrom
          )
        : "2024-07-24";
    const toTime =
      data.IsScheduled === "yes"
        ? combineDateAndTime(
            formatDateToString(data.scheduledDate),
            pickupTimeTo
          )
        : "2024-07-24";

    try {
      const requestBody = {
        routingTypeId,
        routeCost,
        senderName,
        senderEmail,
        senderMobileNo: senderMobileNumber,
        senderCountryId,
        senderCityId,
        senderLandMark: senderLandmark,
        senderAdditionalAddressInfo: senderAddress,
        receiverName,
        receiverEmail,
        receiverMobileNo: receiverMobileNumber,
        receiverCountryId,
        receiverCityId,
        receiverLandMark: receiverLandmark,
        receiverAdditionalAddressInfo: receiverAddress,
        itemCategoryId,
        itemTypeId,
        itemTypeCost,
        dimensionId,
        dimensionCost,
        weightId,
        weightCost,
        uniqItemDescription: itemDescription,
        itemValue: productValue,
        isExtraPackaging,
        extraPackagingCost,
        parcelAdditionalInfo: additionalInfo,
        isPickup: isPickUp,
        pickupDate: pickUpDate,
        fromTime,
        toTime,
        pickUpCountryId,
        pickUpCityId,
        pickUpLandMark: pickUpLandmark,
        pickUpAdditionalAddressInfo,
        parcelPickUpInstractions: pickupInstructions,
        pickupCost: pickUpCost,
        cargoTypeId,
        cargoCost,
        subTotal: totalCost,
        vaT_TaxParcentage: vatAmountPercentage,
        vaT_TaxAmount: vatAmount,
        orderPayableAmount: totalCost,
        shippingServiceId,
        shippingServicePercentage,
        shippingServiceAmount,
        discountPercentage,
        discountedOfferId,
        discountAmount,
        creatorId: GetLocalStorageUserId(),
        modifierId: GetLocalStorageUserId(),
        isActive: true,
        barcode: "string",
        isAgent:
          tittle === "Agency Booking" && localStorageObj.Type ? true : false,
        agentId:
          localStorageObj?.Id && tittle === "Agency Booking"
            ? parseInt(localStorageObj?.Id)
            : 0,
        recordSerialNo: "SBUK-AR-00000000014",
        branchId:1,
        paymentTypeMethodId:parseInt(data?.paymentTypeId)

      };

      // setBookingBarcodeNumber(62456875)
      console.log(requestBody);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}TransitionCost`,
        requestBody
      );
      console.log(response.data);

      if (response.status === 200) {
        Swal.fire({
          title: `${response.data.message}`,
          html:
            url === "/agency-bookings" || url === "/admin-bookings"
              ? ""
              : `
    <div className="font-bold text-3xl text-center">${
      url === "/agency-bookings" || url === "/admin-bookings"
        ? "Booking created successfully."
        : "We have created a account for you"
    }</div>
    <div className="${
      url === "/agency-bookings" || url === "/admin-bookings"
        ? "hidden"
        : "block"
    }">Your User ID: <b>${response.data.userId}</b></div>
    <div className="${
      url === "/agency-bookings" || url === "/admin-bookings"
        ? "hidden"
        : "block"
    }">Your Password: <b>${response.data.password}</b></div>
  `,
          icon: "success",
        }).then(() => {
          if (url === "/agency-bookings") {
            move("/agent-dashboard");
          }
          if (url === "/admin-bookings") {
            move("/admin-dashboard");
          }
        });

        // set booking barcode number.
        setBookingBarcodeNumber(response.number); // need to change according api response.
      }
    } catch (error) {
      console.log(error);
    }
  };

  const stepNames = [
    "Route",
    "Sender",
    "Receiver",
    "Parcel",
    "Pickup",
    "Shipment",
    "Review",
    "Payment Method",
    "Pay",
  ];

  useEffect(() => {
    const getTransitionCost = async () => {
      const {
        route,
        productLength,
        productWeight,
        productHeight,
        productWidth,
        cargoType,
        packaging,
        IsScheduled,
        productType,
        selectedShipmentArrivalDate,
      } = data;

      const routingTypeId = parseInt(route);
      const parcelLength = parseFloat(productLength);
      const parcelWidth = parseFloat(productWidth);
      const parcelHeight = parseFloat(productHeight);
      const parcelWeight = parseFloat(productWeight);
      const cargoTypeId = parseInt(cargoType);
      const isExtraPackaging = packaging === "Yes" ? true : false;
      const isPickUp = IsScheduled === "yes" ? true : false;
      const itemTypeId = parseInt(productType);

      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}TransitionCost?RoutingTypeId=${routingTypeId}&ParcelLength=${parcelLength}&ParcelWidth=${parcelWidth}&ParcelHeight=${parcelHeight}&ParcelWeight=${parcelWeight}&CargoTypeId=${cargoTypeId}&IsExtraPackaging=${isExtraPackaging}&IsPickUp=${isPickUp}&ItemTypeId=${itemTypeId}&ShipmentArrivalDate=${selectedShipmentArrivalDate}`
        );
        // TransitionCost?RoutingTypeId=1&ParcelLength=23&ParcelWidth=232&ParcelHeight=54&ParcelWeight=34&CargoTypeId=1&IsExtraPackaging=false&IsPickUp=true&ItemTypeId=1&ShipmentArrivalDate=2024-5-5
        console.log(result.data,"this is ressssssssssulllllllttttttttt.");
        
        setTransitCost(result?.data);
        setPickUpCost(result?.data[0]?.pickUpCost);
        setRouteCost(result?.data[0]?.routeCost);
        setItemTypeCost(result?.data[0]?.itemTypeCost);
        setWeightCost(result?.data[0]?.weightCost);
        setDimensionCost(result?.data[0]?.dimensionCost);
        setDimensionId(result?.data[0]?.dimensionId);
        setWeightId(result?.data[0]?.weightCostId);
        setExtraPackagingCost(result?.data[0]?.extraPackagingCost);
        setCargoCost(result?.data[0]?.cargoCost);
        setShippingServicePercentage(
          result?.data[0]?.shippingServicePercentage
        );
        setShippingServiceAmount(
          result?.data[0]?.shippingServicePercentageAmount
        );
        setShippingServiceId(result?.data[0]?.shippingServiceId);
        setDiscountPercentage(result?.data[0]?.discountPercentage);
        setDiscountAmount(result?.data[0]?.discountAmount);
        setDiscountedOfferId(result?.data[0]?.discountedOfferId);
        setVatAmount(result?.data[0]?.vatAmount);
        setVatAmountPercentage(result?.data[0]?.vatAmountPercentage);
        setTotalCost(result?.data[0]?.totalCost);
      } catch (error) {
        console.log(error);
      }
    };

    if (data.selectedShipmentArrivalDate) {
      getTransitionCost();
    }
  }, [data.selectedShipmentArrivalDate]);

  return (
    <div className="py-[70px] bookingContainer min-h-screen flex flex-col gap-11 justify-center items-center">
      <ToastContainer />
      <h1 className="text-[36px] font-semibold pageTittle px-4">{tittle}</h1>
      <div className="multiform-container px-9 bg-white shadow-none lg:shadow-2xl  lg:rounded-xl">
        <form onSubmit={onSubmit} className="pt-[200px] pb-[50px] relative">
          <div className="absolute top-[90px] left-0 w-full">
            <ProgressBar
              data={data}
              currentStepIndex={currentStepIndex}
              steps={steps}
              stepNames={stepNames}
            />
          </div>
          {/* step page container part. */}

          <div className=" lg:min-h-[300px] lg:px-5 flex justify-center items-center">
            {step}
          </div>

          {/* buttom part. */}
          <div
            className={`flex justify-between ${
              isFirstStep ? "flex-row-reverse" : "flex-row"
            } items-center mt-6 `}
          >
            {!isFirstStep && (
              <button // but-1
                type="button"
                onClick={back}
                className="bg-transparent border border-[#E57C22] font-bold text-[#E57C22] py-2 pl-2 pr-7 w-[8rem] rounded-lg flex items-center justify-between flex-row-reverse"
              >
                Back <BsArrowLeft className="text-2xl" />
              </button>
            )}

            <button // but-2
              type="submit"
              className="bg-transparent border border-[#E57C22] font-bold text-[#E57C22] py-2 pr-2 pl-7 w-[8rem] rounded-lg flex items-center justify-between"
            >
              {isLastStep ? "Finish" : "Next"}
              <BsArrowRight className="text-2xl" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Bookings;

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";

const SetupDropDown = ({
  placeHolder,
  name,
  valueUpdate,
  allTimeValue,
  dataOf,
  defaultValue
}) => {


  const [data, setData] = useState([]);

  const { companys, branchs, routes, functions, cargoTypes, parcelNumber,boxName,measurementUnit,device } =
    useContext(contextProvider);

    // static data for some drop down.

    const bookingUserType=["All","Agent"]

    // ..............

  useEffect(() => {
    if (dataOf === "device") setData(device);
    if (dataOf === "measurementUnit") setData(measurementUnit);
    if (dataOf === "company") setData(companys);
    if (dataOf === "route") setData(routes);
    if (dataOf === "function") setData(functions);
    if (dataOf === "cargoName") setData(cargoTypes);
    if (dataOf === "branch") setData(branchs);
    if (dataOf === "parcelNumber") setData(parcelNumber);
    if (dataOf === "bookingUserType") setData(bookingUserType);
    if(dataOf==="boxName") setData(boxName)
    
  }, [dataOf, companys, routes, functions, cargoTypes, parcelNumber, branchs,,measurementUnit,boxName]);


   // placeholder text formate.
   const formateText = (text) => {
    const wordArray = text?.split(" ");
    const formatedWordArray = wordArray?.map((item) => {
      const [firstL, ...rest] = item;
      rest.unshift(firstL.toUpperCase());
      return rest.join("");
    });
    return formatedWordArray?.join(" ")
  };

  return (
    <div className=" w-full ">
      <label className="  font-semibold" htmlFor={placeHolder}>
      {formateText(placeHolder)}
      </label>

      <select
      required
        value={allTimeValue}
        // defaultValue={defaultValue}
        onChange={valueUpdate}
        className={`w-full  border-2 lg:border-[3px] pl-2 ${placeHolder&&"mt-3"} border-[#83430D] h-12 outline-none rounded-lg bg-transparent`}
        id={placeHolder}
        name={name}
      >
        <option className="text-start" value={""} selected hidden  >
          Select one
        </option>
        {data?.map((item, idx) => {
          if (dataOf === "device")
            return (
              <option key={idx} value={item.id}>
                {item.deviceName}{" "}
              </option>
            );
          if (dataOf === "measurementUnit")
            return (
              <option key={idx} value={item.id}>
                {item.unitDescription}{" "}
              </option>
            );
          if (dataOf === "company")
            return (
              <option key={idx} value={item.id}>
                {item.name}{" "}
              </option>
            );
          if (dataOf === "route")
            return (
              <option key={idx} value={item.id}>
                {item.routeName}{" "}
              </option>
            );
          if (dataOf === "cargoName")
            return (
              <option key={idx} value={item.id}>
                {item.cargoTypeName}{" "}
              </option>
            );
          if (dataOf === "function")
            return (
              <option key={idx} value={item.id}>
                {item.cargoTypeName}{" "}
              </option>
            );
          if (dataOf === "parcelNumber")
            return (
              <option key={idx} value={item.barcode}>
                {item.percelNumber}
              </option>
            );
          if (dataOf === "bookingUserType")
            return (
              <option className="text-center" key={idx} value={item}>
                {item}
              </option>
            );
          if (dataOf === "branch")
            return (
              <option key={idx} value={item.id}>
                {item.branchName}
              </option>
            );
          if (dataOf === "boxName")
            return (
              <option key={idx} value={item.id}>
                {item.dimensionName}
              </option>
            );
        })}
      </select>
    </div>
  );
};

export default SetupDropDown;

import React, { useContext, useEffect, useState } from "react";
import HistoryTable from "../../../setupPage/SharedComponents/HistoryTable";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
import Box from "../../../setupPage/SharedComponents/Box";
import SubmitBtn from "../../../setupPage/SharedComponents/SubmitBtn";
import SetupAutoComplete from "../../../setupPage/SharedComponents/SetupAutoComplete";
import Swal from "sweetalert2";
import axios from "axios";
import AdminPageDataTable from "../SharedComponent/AdminPageDataTable";
import GetLocalStorageUserId from "../../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";
const ParcelSetupCost = () => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState(null);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(`${backendUrl}AgentBoxAssign`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // get local storage data.
  const localdata = localStorage.getItem("sundorbonBookingUserDetails");
  const localStorageObj = JSON.parse(localdata);

  // input fields handle.
  const initialValue = {
    agentId: "",
    agentName: "",
    dimensionId: "",
    boxName: "",
    boxSerialNo: "",
    boxQty: "",
    isActive: "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    subBranchId: "",
    subBranchName: "",
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };

  const{handleDataReload}=useContext(contextProvider)
  const formHandle = (e) => {
    e.preventDefault();
    formData.requisitionDate = new Date(formData.requisitionDate);
    console.log({ ...formData });

    axios
      .post(`${backendUrl}AgentBoxAssign`, {
        ...formData,
      })

      .then((res) => {
        if (res.status === 201) {
          Swal.fire({
            title: `Parcel Setup Cost Created Successfully`,
            icon: "success",
          });
          setRefetch((prev) => !prev);
          setFormData(initialValue);
          setWillUpdate(false);
          handleDataReload()
        }
      })
      .catch((err) => console.log(err.message));
  };

  // price table handle.
  const tableInitialValue = {
    "Route Cost": "",
    "Dimension Cost": "",
    "Weight Cost": "",
    "Additional Cost": "",
    "Cargo Type Cost": "",
    "Extra Packaging Cost": "",
    "Item Type Cost": "",
    "Pickup Cost": "",
    "Total":""
    // "Vat Configuration Cost": "",
  };

  const [tableData, setTableData] = useState(tableInitialValue);

  const updateTableData = (data) => {

const{Total,...rest}=tableData
    const totalnumberObjArray={...rest,...data}
    const TotalNumbersArray=Object.values(totalnumberObjArray)
    const totalNumberFilter=TotalNumbersArray.filter(item=>{
      if(!item) return false
      return true
    })

    const totalCost=totalNumberFilter.length!==0?totalNumberFilter.reduce((prev,current)=>prev+current):null

   
console.log(TotalNumbersArray , totalCost ,totalNumberFilter)


    setTableData({ ...tableData, ...data,Total:totalCost });
  };
  const getHeaders = () => {
    const currentHeaders = Object.keys(tableData);
    const filteredHeader = currentHeaders?.filter((item) => {
      if (tableData[item]) {
        return true;
      }
      return false;
    });

    return filteredHeader;
  };

  return (
    <div>
      <Box top>
        <SetupTittle text="Parcel Setup Cost" />
        <SetupContainer>
          <form onSubmit={formHandle} className="">
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <div className="flex items-end gap-2">
                <SetupAutoComplete
                  valueUpdate={(_, value) => {
                    updateTableData({ "Route Cost": value?.routeCost });
                  }}
                  placeHolder="Route Cost"
                  dataOf="routes"
                  allTimeValue={formData.subBranchId}
                />
                <SubmitBtn
                  styleClass="text-sm w-[110px] px-0"
                  routeing
                  route="/admin-dashboard/setup/route"
                  text="create new"
                />
              </div>

              <div className="flex items-end gap-2">
                <SetupAutoComplete
                  valueUpdate={(_, value) => {
                    updateTableData({ "Dimension Cost": value?.price });
                  }}
                  placeHolder="Dimension Cost"
                  dataOf="boxName"
                  allTimeValue={formData.subBranchId}
                />
                <SubmitBtn
                  styleClass="text-sm w-[110px] px-0"
                  routeing
                  route="/admin-dashboard/setup/dimension"
                  text="create new"
                />
              </div>

              <div className="flex items-end gap-2">
                <SetupAutoComplete
                  valueUpdate={(_, value) => {
                    updateTableData({ "Weight Cost": value?.cost });
                  }}
                  placeHolder="Weight cost"
                  dataOf="weight"
                  allTimeValue={formData.subBranchId}
                />
                <SubmitBtn
                  styleClass="text-sm w-[110px] px-0"
                  routeing
                  route="/admin-dashboard/setup/weight"
                  text="create new"
                />
              </div>

              <div className="flex items-end gap-2">
                <SetupAutoComplete
                  valueUpdate={(_, value) => {
                    updateTableData({ "Additional Cost": value?.cost });
                  }}
                  placeHolder="Additional Cost"
                  dataOf="additionalCost"
                  allTimeValue={formData.subBranchId}
                />
                <SubmitBtn
                  styleClass="text-sm w-[110px] px-0"
                  routeing
                  route="/admin-dashboard/setup/aditional-cost"
                  text="create new"
                />
              </div>

              <div className="flex items-end gap-2">
                <SetupAutoComplete
                  valueUpdate={(_, value) => {
                    updateTableData({ "Cargo Type Cost": value?.cargoCost });
                  }}
                  placeHolder="Cargo Type Cost"
                  dataOf="cargoTypes"
                  allTimeValue={formData.subBranchId}
                />
                <SubmitBtn
                  styleClass="text-sm w-[110px] px-0"
                  routeing
                  route="/admin-dashboard/setup/cargo-type"
                  text="create new"
                />
              </div>

              <div className="flex items-end gap-2">
                <SetupAutoComplete
                  valueUpdate={(_, value) => {
                    updateTableData({ "Extra Packaging Cost": value?.cost });
                  }}
                  placeHolder="Extra Packaging Cost"
                  dataOf="extraPackaging"
                  allTimeValue={formData.subBranchId}
                />
                <SubmitBtn
                  styleClass="text-sm w-[110px] px-0"
                  routeing
                  route="/admin-dashboard/setup/extra-packaging"
                  text="create new"
                />
              </div>

              <div className="flex items-end gap-2">
                <SetupAutoComplete
                  valueUpdate={(_, value) => {
                    updateTableData({ "Item Type Cost": value?.cost });
                  }}
                  placeHolder="Item Type Cost"
                  dataOf="itemType"
                  allTimeValue={formData.subBranchId}
                />
                <SubmitBtn
                  styleClass="text-sm w-[110px] px-0"
                  routeing
                  route="/admin-dashboard/setup/item-type"
                  text="create new"
                />
              </div>

              <div className="flex items-end gap-2">
                <SetupAutoComplete
                  valueUpdate={(_, value) => {
                    updateTableData({ "Pickup Cost": value?.cost });
                  }}
                  placeHolder="Pickup Cost"
                  dataOf="Pickup"
                  allTimeValue={formData.subBranchId}
                />
                <SubmitBtn
                  styleClass="text-sm w-[110px] px-0"
                  routeing
                  route="/admin-dashboard/setup/pickup"
                  text="create new"
                />
              </div>

              {/* <div className="flex items-end gap-2">
                <SetupAutoComplete
                  valueUpdate={(_, value) => {
                    updateTableData({
                      "Vat Configuration Cost": value?.configurationAmount,
                    });
                  }}
                  placeHolder="Vat Configuration Cost"
                  dataOf="VATConfiguration"
                  allTimeValue={formData.subBranchId}
                />
                <SubmitBtn
                  styleClass="text-sm w-[110px] px-0"
                  routeing
                  route="/admin-dashboard/setup/vat-configuration"
                  text="create new"
                />
              </div> */}
            </div>

            {/* <SubmitBtn text="save" /> */}
          </form>
        </SetupContainer>
      </Box>
      <Box top>
        {/* <SetupTittle text="History" /> */}
        <SetupContainer>
          <div className="my-11">
          <HistoryTable
            notSearchAble
            notEditAble
            // clearActiveRow={!willUpdate ? true : false}
            // editCallBack={(data) => {
            //   console.log(data)
            //   update(data);
            //   setWillUpdate(true);
            // }}
            fetchedData={[tableData]}
            headers={Object.keys(tableInitialValue)}
            row={Object.keys(tableInitialValue)}
          />
          </div>
        </SetupContainer>

        {/* <SetupContainer>
          {getHeaders()?.length !== 0 && (
            <div className="mt-4 lg:w-[40%] lg:mb-20">
              <div className={`py-2 bg-[#f5cfb5] `}>
                <div className="px-3 flex justify-between items-center">
                  <span className="font-bold text-lg">Cost Name</span>
                  <span className="font-bold text-lg">Amount</span>
                </div>
              </div>

              {getHeaders()?.map((item, idx) => {
                return (
                  <div
                    className={`py-2 ${
                      idx % 2 === 0 ? "bg-[#fcf4ef]" : "bg-[#fce8db]"
                    }`}
                    key={idx}
                  >
                    <div className="px-3 flex justify-between items-center">
                      {" "}
                      <span className="">{item}</span>{" "}
                      <span className="font-bold">{tableData[item]} £</span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </SetupContainer> */}
      </Box>
    </div>
  );
};

export default ParcelSetupCost;

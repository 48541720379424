import React, { useContext, useEffect, useState } from "react";
import SetupTittle from "../SharedComponents/SetupTittle";
import Box from "../SharedComponents/Box";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupInput from "../SharedComponents/SetupInput";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import ToggleBtn from "../SharedComponents/ToggleBtn";
import SetupRadio from "../SharedComponents/SetupRadio";
import HistoryTable from "../SharedComponents/HistoryTable";
import axios from "axios";
import SetupDropDown from "../SharedComponents/SetupDropdown";
import Swal from "sweetalert2";
import GetLocalStorageUserId from "../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";

const ProhibitedItem = () => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(`${backendUrl}ProhibitedItem`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // input fields handle.
  const initialValue = {
    id: 0,
    companyId: 0,
    itemName: "",
    isActive: "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    branchId: "",
    branchName: "string",
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };
  const { handleDataReload } = useContext(contextProvider);
  const formHandle = (e) => {
    e.preventDefault();
    console.log({ ...formData });

    if (!willUpdate) {
      axios
        .post(`${backendUrl}ProhibitedItem`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            Swal.fire({
              title: `Prohibited Item Created Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err.message));
    }

    if (willUpdate) {
      console.log(formData);
      axios
        .put(`${backendUrl}ProhibitedItem/${formData.id}`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 204) {
            Swal.fire({
              title: `Prohibited Item Updated Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err.message));
    }
  };

  return (
    <div>
      <Box top>
        <SetupTittle text="prohibited item" />
        <SetupContainer>
          <form onSubmit={formHandle} className="">
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupInput
                type="text"
                placeHolder="prohibited item name"
                allTimeValue={formData.itemName}
                valueUpdate={(e) => update({ itemName: e.target.value })}
              />
              {/* <SetupDropDown
                dataOf="company"
                allTimeValue={formData.companyId}
                valueUpdate={(e) =>
                  update({ companyId: parseInt(e.target.value) })
                }
                placeHolder="Company name"
                name="companyId"
              ></SetupDropDown> */}
              <SetupDropDown // need to change
                dataOf="branch"
                allTimeValue={formData.branchId}
                valueUpdate={(e) =>
                  update({ branchId: parseInt(e.target.value) })
                }
                placeHolder="Branch"
                name="branchId"
              ></SetupDropDown>
              <SetupRadio
                name="isActive"
                placeHolder="prohibited item status?"
                allTimeValue={formData.isActive}
                valueUpdate={(data) => update({ isActive: data })}
              />
            </div>
            <SubmitBtn text={willUpdate ? "Update" : "Create"} />
          </form>
        </SetupContainer>
      </Box>

      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            editCallBack={(data) => {
              update(data);
              setWillUpdate(true);
            }}
            fetchedData={fetchedData}
            headers={[
              "prohibited item name",
              "Company name",
              "Branch",
              "Status",
            ]}
            row={["itemName", "companyId", "branchName", "isActive"]}
            extraFields={["branchId"]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default ProhibitedItem;

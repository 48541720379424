import React, { useEffect, useRef, useState } from "react";
import "./asideNav.css";
import { Link, NavLink, Route, useLocation } from "react-router-dom";
import { LuLayoutDashboard } from "react-icons/lu";
import { GrConfigure } from "react-icons/gr";
import { FaChevronDown } from "react-icons/fa";
import { FaDirections } from "react-icons/fa";
import { MdDeliveryDining } from "react-icons/md";
import { RiProhibitedLine } from "react-icons/ri";
import { MdMergeType } from "react-icons/md";
import { FaCity } from "react-icons/fa6";
import { FaFlag } from "react-icons/fa";
import { MdOutlineDisplaySettings } from "react-icons/md";
import { HiUserGroup } from "react-icons/hi2";
import { MdOutlineDevices } from "react-icons/md";
import { RxDimensions } from "react-icons/rx";
import { MdLocalOffer } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";
import { LuPackagePlus } from "react-icons/lu";
import { TbCategory2 } from "react-icons/tb";
import { MdOutlineCategory } from "react-icons/md";
import { IoIosBarcode } from "react-icons/io";
import { FaSitemap } from "react-icons/fa6";
import { IoPerson } from "react-icons/io5";
import { FaMoneyBillWave } from "react-icons/fa6";
import { FaBarcode } from "react-icons/fa6";
import { LuPrinter } from "react-icons/lu";
import { MdOutlinePayments } from "react-icons/md";
import { MdCorporateFare } from "react-icons/md";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { FaWeightHanging } from "react-icons/fa6";
import { GrDeliver } from "react-icons/gr";
import { IoScan } from "react-icons/io5";
import { LuGitBranchPlus } from "react-icons/lu";
import { GoIssueClosed } from "react-icons/go";
import formateText from "../../../../utils/Formate";
import { BsBox } from "react-icons/bs";
import { RiLockPasswordLine } from "react-icons/ri";
import { IoTicket } from "react-icons/io5";
import { FaBoxOpen } from "react-icons/fa";
import { FaTruck } from "react-icons/fa6";
import { LuScanLine } from "react-icons/lu";
import { AiOutlineEuro } from "react-icons/ai";
import { FaCodeBranch } from "react-icons/fa";
import { MdOutlinePriceChange } from "react-icons/md";
import { TbRulerMeasure } from "react-icons/tb";
import { AiOutlineStock } from "react-icons/ai";
import { MdOutlinePayment } from "react-icons/md";
const AsideNav = () => {
  const Routes = [
    {
      to: "courier-current-stock",
      name: "Courier Current stock",
      icon: <AiOutlineStock />,
    },
    {
      to: "sub-branch",
      name: "Sub-Branch",
      icon: <FaCodeBranch />,
    },
    {
      to: "agent-transport",
      name: "Agent Transport",
      icon: <FaTruck />,
    },
    {
      to: "parcel-status",
      name: "Parcel Status",
      icon: <FaBoxOpen />,
    },
    {
      to: "route",
      name: "Routes",
      icon: <FaDirections />,
    },
    {
      to: "agent",
      name: "Agent",
      icon: <IoPerson />,
    },
    {
      to: "scanning-point",
      name: "Scanning Point",
      icon: <IoScan />,
    },
    {
      to: "city",
      name: "City",
      icon: <FaCity />,
    },
    {
      to: "country",
      name: "Country",
      icon: <FaFlag />,
    },
    {
      to: "aditional-cost",
      name: "Additional cost",
      icon: <FaMoneyBillWave />,
    },
    // {
    //   to: "barcode",
    //   name: "Barcode",
    //   icon: <FaBarcode />,
    // },
    // {
    //   to: "barcode-details",
    //   name: "Barcode details",
    //   icon: <IoIosBarcode />,
    // },

    {
      to: "prohibited-item",
      name: "Prohibited item",
      icon: <RiProhibitedLine />,
    },
    {
      to: "shiping",
      name: "Shiping service",
      icon: <MdDeliveryDining />,
    },
    {
      to: "cargo-type",
      name: "Cargo types",
      icon: <MdMergeType />,
    },
    // {
    //   to: "company",
    //   name: "Company",
    //   icon: <MdCorporateFare />,
    // },
    {
      to: "pickup",
      name: "Pickup",
      icon: <GrDeliver />,
    },
    {
      to: "measurement-unit",
      name: "Measurement unit",
      icon: <TbRulerMeasure />,
    },
    {
      to: "weight",
      name: "Weight",
      icon: <FaWeightHanging />,
    },
    {
      to: "vat-configuration",
      name: "Vat configuration",
      icon: <HiOutlineReceiptTax />,
    },

    // {
    //   to: "departmental-operation",
    //   name: "Def. operation",
    //   icon: <MdOutlineDisplaySettings />,
    // },
    // {
    //   to: "department",
    //   name: "Department",
    //   icon: <HiUserGroup />,
    // },
    {
      to: "device",
      name: "Device",
      icon: <MdOutlineDevices />,
    },
    {
      to: "dimension",
      name: "Dimension",
      icon: <RxDimensions />,
    },
    // {
    //   to: "offer",
    //   name: "Discounted offer",
    //   icon: <MdLocalOffer />,
    // },
    // {
    //   to: "offer-details",
    //   name: "Discounted offer details",
    //   icon: <BiSolidOffer />,
    // },
    {
      to: "extra-packaging",
      name: "Extra packaging",
      icon: <LuPackagePlus />,
    },
    {
      to: "item-category",
      name: "Item category",
      icon: <MdOutlineCategory />,
    },
    // {
    //   to: "function",
    //   name: "Function",
    //   icon: <TbCategory2 />,
    // },
    {
      to: "item-type",
      name: "Item type",
      icon: <FaSitemap />,
    },
    {
      to: "branch",
      name: "Branch",
      icon: <LuGitBranchPlus />,
    },
  ];

  Routes?.sort((a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });

  const [dropDown, setDropDown] = useState(false);

  const url = useLocation();

  useEffect(() => {
    if (url.pathname.includes("setup/")) {
      return setDropDown(true);
    }
    return setDropDown(false);
  }, [url]);

  const parentRoutes = (
    <>
      <NavLink
        end
        className="flex items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
        to={"/admin-dashboard"}
      >
        <LuLayoutDashboard />
        Dashboard
      </NavLink>
      <button
        onClick={() => setDropDown((prev) => !prev)}
        className={`flex justify-between pr-2  items-center w-full hover:bg-[#747474] ${
          dropDown || url.pathname.includes("setup/")
            ? "bg-[#747474] rounded-xl text-white"
            : "bg-transparent"
        } duration-100 hover:rounded-xl lg:py-3 pl-3 gap-16`}
      >
        <span className="flex items-center gap-3">
          <GrConfigure /> Set-up
        </span>{" "}
        <FaChevronDown
          className={`${dropDown ? "rotate-180" : ""} duration-300`}
        />
      </button>
      <ul
        className={` pl-6 text-sm ${
          dropDown ? "block" : "hidden"
        } duration-200`}
      >
        {Routes?.map((item, idx) => {
          return (
            <NavLink
              key={idx}
              className="flex items-center mt-1 hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3 text-lg"
              to={"setup/" + item.to}
            >
              {item.icon}
              {formateText(item.name)}
            </NavLink>
          );
        })}
      </ul>
      <NavLink
        end
        className="flex items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
        to={"issue"}
      >
        <GoIssueClosed />
        Issue
      </NavLink>
      {/* <NavLink
        end
        className="flex items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
        to={"pay"}
      >
        <MdOutlinePayment />
        Pay
      </NavLink> */}
      <NavLink
        end
        className="flex items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
        to={"payment"}
      >
        <MdOutlinePayments />
        Payment
      </NavLink>

      <NavLink
        className="flex items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
        to={"agent-box-ssign"}
      >
        <BsBox />
        Agent Box Assign
      </NavLink>

      <NavLink
        className="flex items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
        to={"parcel-status-scan"}
      >
        <LuScanLine />
        Parcel Status Scan
      </NavLink>
      <NavLink
        className="flex items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
        to={"parcel-setup-cost"}
      >
        <MdOutlinePriceChange />
        Parcel Setup Cost
      </NavLink>

      <NavLink
        className="flex items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
        to={"transport-agent-assigning"}
      >
        <AiOutlineEuro />
        Transport Agent Assigning
      </NavLink>

      {/* <NavLink
        className="flex items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
        to={"change-password"}
      >
        <RiLockPasswordLine />
        Change Password
      </NavLink> */}

      <NavLink
        className="flex items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
        to={"print-box-serial-barcode"}
      >
        <FaBarcode />
        Print Box Serial Barcode
      </NavLink>

      <NavLink
        className="flex items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
        to={"print-barcode"}
      >
        <LuPrinter />
        Print Barcode
      </NavLink>
      <Link
        className="flex lg:w-full items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
        to={"/admin-bookings"}
      >
        <IoTicket />
        Add Booking
      </Link>
    </>
  );

  // get local storage data.
  const localdata = localStorage.getItem("sundorbonBookingUserDetails");
  const Name = JSON.parse(localdata)?.Name || "";
  const Email = JSON.parse(localdata)?.Email || "";

  return (
    <div className="lg:w-[17%] lg:flex z-30 flex-col justify-start items-start lg:gap-12 lg:pb-11 overflow-hidden pt-11 w-full lg:h-[93vh] lg:rounded-[25px] sticky top-0 lg:top-[3%] left-5 bg-black text-white lg:bg-[#424242]">
      <h1 className="font-normal w-full  lg:text-[43px] text-4xl lg:mt-7  text-center  text-white">
        Admin
      </h1>
      <div className="w-full hidden text-center lg:block">
        <h1 className="text-xl font-semibold ">{Name}</h1>
        <h1 className="text-base font-semibold mt-1">{Email}</h1>
      </div>
      <ul className="w-full scrollbar lg:h-[81%] lg:overflow-y-auto lg:pr-5 lg:pl-3 lg:flex flex-col gap-y-1 lg:text-xl text-lg text-gray-400">
        {parentRoutes}
      </ul>
    </div>
  );
};

export default AsideNav;

import React, { useRef, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";

import "./finalize.css";
import CreateShipment from "./sub_componenets/CreateShipment";
import PaymentPage from "./sub_componenets/PaymentPage";
import { Link } from "react-router-dom";
import Barcode from "./sub_componenets/Barcode";
import { useReactToPrint } from "react-to-print";
const Finalize = ({ transitCost, bookingBarcodeNumber, ...bookingInfo }) => {
  const [page, setPage] = useState("Create Shipment");
  let paramitre = { ...bookingInfo, setPage };
  console.log("transit final: ", transitCost);

  const tabPageHandle = (param) => {
    setPage(param);
  };
  console.log(bookingBarcodeNumber, "booking bar-code number.");

  // print handle.
  const contentToPring = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "booking barcode",
    removeAfterPrint: true,
  });
  return (
    <div className="w-full my-4">
      <button
        onClick={() => tabPageHandle("Create Shipment")}
        type="button"
        className={` ${
          page === "Pay" ? "btn btn-primary rounded-full" : "hidden"
        }`}
      >
        <FaArrowLeft />
      </button>
      <div className="my-2 pl-1 font-bold text-3xl">
        {bookingBarcodeNumber ? "Bar code" : "Create shipment"}
      </div>

      {}
      <>
        {page === "Create Shipment" &&
          (bookingBarcodeNumber ? (
            <div ref={contentToPring}>
              <Barcode bookingBarcodeNumber={bookingBarcodeNumber}></Barcode>
            </div>
          ) : (
            <CreateShipment {...paramitre} transitCost={transitCost} />
          ))}
        {page === "Pay" && <PaymentPage {...paramitre} />}
      </>

      <div className="flex mt-4 items-center justify-start pl-1 gap-x-3">
        {/* <button
          onClick={() => tabPageHandle("Pay")}
          className="btn btn-primary bg-transparent  btn-sm text-black"
          type="button"
        >
          Pay
        </button>
        <button
          onClick={() => handlePrint(null, () => contentToPring.current)}
          className="btn btn-primary bg-transparent  btn-sm text-black"
          type="button"
        >
          Print
        </button> */}
        <button
          className="btn btn-primary bg-transparent  btn-sm text-black"
          type="button"
        >
          <Link to={"/"}>
            Cancel
            <Link />
          </Link>
        </button>
      </div>
    </div>
  );
};

export default Finalize;

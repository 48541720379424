import React, { useState } from "react";
import SetupTittle from "../SharedComponents/SetupTittle";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupInput from "../SharedComponents/SetupInput";
import SetupRadio from "../SharedComponents/SetupRadio";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import Box from "../SharedComponents/Box";
import axios from "axios";
import HistoryTable from "../SharedComponents/HistoryTable";
import GetLocalStorageUserId from "../../sharedComponents/GetLocalStorageuserId";

const Barcode = () => {


  let fetchedData=[
    {
      "id": 4,
      "additionaCostName": "string",
      "cost": 0,
      "isActive": true,
      "creatorId": "string",
      "creationDate": "2024-07-17T10:07:52.973",
      "modifierId": "string",
      "modificationDate": "2024-07-17T10:07:52.973"
    },
    {
      "id": 5,
      "additionaCostName": "string",
      "cost": 0,
      "isActive": true,
      "creatorId": "string",
      "creationDate": "2024-07-17T10:11:32.99",
      "modifierId": "string",
      "modificationDate": "2024-07-17T10:11:32.99"
    }
  ]

  // input fields handle.
  const initialValue={
    
      additionaCostName: "",
      cost: "",
      isActive:"",
      creatorId: GetLocalStorageUserId(),
      creationDate: new Date(),
      modifierId: GetLocalStorageUserId(),
      modificationDate: new Date()
  }
  
  const[formData,setFormData]=useState(initialValue)
  const[willUpdate,setWillUpdate]=useState(false)

  const update=(data)=>{
    setFormData({...formData,...data})
  }


  const formHandle=(e)=>{
    e.preventDefault()
    console.log({...formData})
    const backendUrl=process.env.REACT_APP_API_URL
    
    axios.post(`${backendUrl}BarcodeStatus`,{...formData})
    
    .then(res=>{
      console.log(res)
      setWillUpdate(false)
    }).catch(err=>console.log(err.message))
  }






  // const formHandle=(e)=>{
  //   e.preventDefault()
  //   const form=e.target
  //   const BarcodeNumber=form.barCodeNumber.value
  //   const UserId=form.userId.value
  //   const IsActive=form.isActive.value
  //   console.log({BarcodeNumber,UserId,IsActive})
  // }
    return (
        <div>
      <Box top>
        <SetupTittle text="Add a barcode" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupInput
                name="barCodeNumber"
                type="text"
                placeHolder="Bar code number"
              />
              <SetupInput
                name="userId"
                type="text"
                placeHolder="User id"
              />
              <SetupRadio
                name="isActive"
                options={["Yes", "No"]}
                placeHolder="Barcode status?"
              />
            </div>
            <SubmitBtn text="Create" />
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="History" />
        <SetupContainer>

          <HistoryTable editCallBack={(data)=>{
            update(data)
            setWillUpdate(true)
          }} fetchedData={fetchedData} row={["additionaCostName","cost","isActive"]} headers={["Name","Cost","Status"]} />

        </SetupContainer>
      </Box>
    </div>
    );
};

export default Barcode;
import React, { useContext, useEffect, useState } from "react";
import Box from "../../../setupPage/SharedComponents/Box";
import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import { BsFillBoxSeamFill } from "react-icons/bs";
import { BsFillBoxFill } from "react-icons/bs";
import HistoryTable from "../../../setupPage/SharedComponents/HistoryTable";
import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";

import envelop from "../../../../assets/Icons/email.png";
import bundle from "../../../../assets/Icons/bundle.png";
import box from "../../../../assets/Icons/box.png";

import { FaRegUser } from "react-icons/fa6";
import { FaUserTie } from "react-icons/fa6";
import SetupInput from "../../../setupPage/SharedComponents/SetupInput";
import SetupDropDown from "../../../setupPage/SharedComponents/SetupDropdown";
import axios from "axios";
import UserBookingRemaining from "../../UserDashboard/SharedComponents/UserBookingRemaining";
import AdminBookingCard from "../SharedComponent/AdminBookingCard";
import PieChartComponenet from "../../UserDashboard/SharedComponents/PieChartComponenet";
import BarChartComponent from "../../UserDashboard/SharedComponents/BarChartComponent";

const OverView = () => {
  const { parcelCount, parcelCountDimension } = useContext(contextProvider);

  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState(null);

  const [tableDate, setTableData] = useState(null);

  const [bookingUserTypeUrl, setBookingUserTypeUrl] = useState(
    "ParcelBookingInformation/ParcelBookingHistory"
  );
  useEffect(() => {
    axios
      .get(backendUrl + bookingUserTypeUrl)
      .then((res) => {
        setFetchedData(res.data);
        setTableData(res.data);
      })
      .catch((err) => console.log(err.message));
  }, [backendUrl, bookingUserTypeUrl]);

  const parcelCountName = (id) => {
    if (id === 0) return "User Parcel Count";
    if (id === 1) return "Agent Parcel Count";
    if (id === 2) return "Total Parcel Count";
  };

  const parcelBoxNameFormate = (name) => {
    return name.split("_").join(" ");
  };

  const parcelImageFormate = (name) => {
    if (name === "A4_Envelope") return envelop;
    if (name === "Small_Children_Applience") return bundle;
    if (name === "Moving_Box") return box;
  };

  // handle form sorting or searching handle.

  const dropDownHandle = (text) => {
    if (text === "All")
      setBookingUserTypeUrl("ParcelBookingInformation/ParcelBookingHistory");
    if (text === "Agent")
      setBookingUserTypeUrl(
        "ParcelBookingInformation/ParcelAgentBookingHistory"
      );
  };

  const TableSearchHandle = (e) => {
    e.preventDefault();
    const searchAbleText = e.target.searchText.value;
    if (!fetchedData) return;

    const isInclued = (values) => {
      for (let item of values) {
        if (item !== null && item !== undefined) {
          const preferedItem = item.toString().toLowerCase();
          const preferedSearchText = searchAbleText.toString().toLowerCase();
          if (preferedItem.includes(preferedSearchText)) return true;
        }
      }
      return false;
    };

    const filteredData = fetchedData.filter((item) => {
      const itemValuesArray = Object.values(item);
      return isInclued(itemValuesArray);
    });

    setTableData(filteredData);

    // const filteredData=fetchedData.filter(item=>item.senderName.toLowerCase().includes(searchAbleText.toLowerCase())||item.receiverName.toLowerCase().includes(searchAbleText.toLowerCase()))
    // setTableData(filteredData)
    // const filterEdData=fetchedData?.filter(item=>{

    //   const valueArray= Object.values(item)

    //   for(item of valueArray){
    //     if(item!==null|| item!==undefined){
    //       console.log(item.toString().toLowerCase().includes(searchAbleText.toLowerCase()))
    //       if(item.toString().toLowerCase().includes(searchAbleText.toLowerCase())){
    //         break
    //       }

    //     }

    //   }

    //   return false

    // })
  };
  const [searchText, setSearchText] = useState("");

  // clear search .
  const clearSearch = () => {
    setTableData(fetchedData);
    setSearchText("");
  };

  let piChartData = [
    { name: "Limit", value: 30, color: "#f05924" },
    { name: "Remaining", value: 20, color: "#27d99f" },
    { name: "going", value: 40, color: "#feca64" },
  ];

  return (
    <div>
      <Box top>
        {/* <SetupTittle text="Add a departmental operation" /> */}
        <SetupContainer>
          <div className="flex lg:items-start justify-center items-center lg:justify-between gap-7 lg:flex-row flex-col   ">
            <div className="w-full">
              <div className="flex gap-3 lg:gap-7 w-full lg:flex-wrap xl:flex-nowrap lg:mt-0 mt-5  lg:px-0 px-8 justify-center flex-col lg:flex-row  items-center">
                {parcelCount?.length > 0 &&
                  Object?.keys(parcelCount[0])?.map((item, idx) => {
                    return (
                      <AdminBookingCard
                        name={parcelCountName(idx)}
                        value={parcelCount[0][item]}
                      />
                    );
                  })}
              </div>
              <div className="mt-7">
                <BarChartComponent />
              </div>
            </div>
            <div className=" h-full">
              <PieChartComponenet
                limit={100}
                remaining={20}
                data={piChartData}
                tittle="Admin Booking"
              />
            </div>
          </div>

          {/* <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-6 lg:mt-16 lg:gap-20">
            {parcelCountDimension?.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="shadow-sm gap-4 py-5  min-h-52  rounded-lg bg-gradient-to-tr from-[#d6d4b6d8] to-[#cacac2] flex items-center flex-col"
                >
                  <div className=" flex justify-start items-center   flex-col">
                    <img
                      className="w-[50px] h-[40px] object-contain"
                      src={parcelImageFormate(item.boxName)}
                      alt="parcel icon"
                    />
                    <h1 className="font-bold mt-1">
                      {parcelBoxNameFormate(item.boxName)}
                    </h1>
                  </div>
                  <div className="w-[70%]">
                    <h1 className="flex items-center justify-between  w-full text-lg font-semibold">
                      <span className="flex items-center gap-2 font-semibold">
                        <FaRegUser className="text-2xl" /> User Parcel
                      </span>{" "}
                      <span>{item.userBoxParcelCount}</span>
                    </h1>
                    <h1 className="flex items-center justify-between  w-full text-lg font-semibold mt-3">
                      <span className="flex items-center gap-2 font-semibold">
                        <FaUserTie className="text-2xl" /> Agent Parcel
                      </span>{" "}
                      <span>{item.agentBoxParcelCount}</span>
                    </h1>
                    <div className="border-b-black w-full border mt-2"></div>
                    <h1 className="flex items-center justify-between  w-full text-lg font-semibold mt-1">
                      <span className="flex items-center gap-2 font-semibold">
                        Total Parcel
                      </span>{" "}
                      <span>{item.totalBoxParcelCount}</span>
                    </h1>
                  </div>
                </div>
              );
            })}
          </div> */}
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="Booking History" />
        <SetupContainer>
          <div className="flex gap-1 lg:gap-2 justify-end items-start flex-col lg:flex-row mb-3">
            <div className="lg:w-[20%] w-[50%]">
              <SetupDropDown
                dataOf="bookingUserType"
                allTimeValue=""
                defaultValue="All"
                valueUpdate={(e) => dropDownHandle(e.target.value)}
                name="branchId"
              ></SetupDropDown>
            </div>
            <form
              onSubmit={TableSearchHandle}
              className="lg:w-[30%] w-full flex items-center gap-x-2"
            >
              <input
                required
                className="w-1/2 lg:w-full border-2 lg:border-[3px] pl-2  border-[#83430D] h-12 outline-none rounded-lg bg-transparent"
                type="text"
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                name="searchText"
                placeholder="You text here"
              />
              <button
                className={`font-medium  text-lg bg-gradient-to-tr from-[#6D4C41] to-[#424242] rounded-[8px] h-[47px] px-6  text-white`}
              >
                Search
              </button>
              <button
                onClick={clearSearch}
                type="button"
                className={`font-medium  text-lg bg-gradient-to-tr from-[#6D4C41] to-[#424242] rounded-[8px] h-[47px] px-6  text-white`}
              >
                Clear
              </button>
            </form>
          </div>

          <HistoryTable
            noSearch
            // editCallBack={(data) => {
            //   update(data);
            //   setWillUpdate(true);
            // }}
            notEditAble
            fetchedData={tableDate}
            headers={[
              "Sender Name",
              "Sender E-mail",
              "Receiver Name",
              "Receiver E-mail",
              "Route Name",
              "Cargo Type",
              "Weight",
              "Dimension Name",
              "Dimension Cost",
              "Sub Total (₤)",
            ]}
            row={[
              "senderName",
              "senderEmail",
              "receiverName",
              "receiverEmail",
              "routeName",
              "cargoTypeName",
              "weightDescription",
              "dimensionName",
              "dimensionCost",
              "subTotal",
            ]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default OverView;


import React, { useContext, useEffect, useState } from "react";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
import PaymentHistoryTable from "../../UserDashboard/SharedComponents/PaymentHistoryTable";
import AgencyDashboardInput from "../SharedComponent/AgencyDashboardInput";
import AutoCompleteBottomBorder from "../../SharedComponents/AutoCompleteBottomBorder";
import axios from "axios";
import SubmitBtn from "../../../setupPage/SharedComponents/SubmitBtn";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";
import GetLocalStorageUserId from "../../../sharedComponents/GetLocalStorageuserId";
import DashboardPageTitle from "../../UserDashboard/SharedComponents/DashboardPageTitle";
import Box from "../../../setupPage/SharedComponents/Box";
import "./AgentPayment.css"
import { MdOutlineNumbers } from "react-icons/md";
const AgentPayment = () => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(`${backendUrl}Payment/GetAgentPayments/${GetLocalStorageUserId()}`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // input fields handle.
  const initialValue = {
    id: 1,
    paymentMethodId: "",
    paymentMethodName: "",
    parcelOderNo: "",
    orderAmount: "",
    description: "",
    paymentDate: "",
    paymentInvoiceNo: "",
    recordSerialNo: "", //
    parcelNoWithRecordSerialNo: "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);


  const update = (data) => {
    setFormData({ ...formData, ...data });
  };
  const { handleDataReload } = useContext(contextProvider);
  const formHandle = (e) => {
    e.preventDefault();
    formData.issueDate = new Date(formData.issueDate);
 
    if (!willUpdate) {
      axios
        .post(`${backendUrl}Payment/CreatePayment`, { ...formData })

        .then((res) => {
          if (res.status === 200)
            toast.error("Payment Already Completed For This Parcel Number.");
          if (res.status === 201) {
            Swal.fire({
              title: `Cash Payment Created Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err));
    }

    if (willUpdate) {
      console.log(formData);
      axios
        .put(`${backendUrl}Payment/CreatePayment/${formData.id}`, {
          ...formData,
        })

        .then((res) => {
          console.log(res);
          if (res.status === 204) {
            Swal.fire({
              title: `Additional Cost Updated Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err.message));
    }
  };

  return (
    <div>
      <Box top>
        <DashboardPageTitle text="Payment" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <AgencyDashboardInput
                type="date"
                iconType="date"
                placeHolder="Payment Date"
                allTimeValue={formData.paymentDate}
                valueUpdate={(e) => update({ paymentDate: e })}
              />

              <div className="w-full flex items-end">
                <div className="min-h-4 text-[#83430D] text-2xl p-2 shadow-xl">
                  <MdOutlineNumbers /> 
                </div>{" "}
                <AutoCompleteBottomBorder
                  valueUpdate={(_, value) => {
                    console.log(value, "this si valieee");
                    if (value?.parcelNo)
                      axios
                        .get(
                          `${backendUrl}ParcelDetails/GetParcelDetailsByParcelNo/${value?.parcelNo}`
                        )
                        .then((res) => {
                          console.log(
                            res.data,

                            "this is responsssssssssssse."
                          );
                          update({
                            parcelOderNo: res.data?.parcelOrderNo.toString(),
                            description: res.data?.description,
                            orderAmount: res.data?.orderAmount,
                            paymentMethodId: res.data?.paymentTypeMethodId,
                            paymentMethodName:
                              res.data?.paymentMethodName || "Not Available",
                            parcelNoWithRecordSerialNo:
                              value?.recordSerialNoWithParcelNo,
                            recordSerialNo: value?.recordSerialNo || "",
                            paymentInvoiceNo: "",
                          });
                        })
                        .catch((err) => console.log(err));
                    else
                      update({
                        parcelOderNo: "",
                        description: "",
                        orderAmount: "",
                        paymentMethodId: "",
                        paymentMethodName: "",
                      });
                  }}
                  dataOf="GetAllAgentParcel"
                  placeholder="Parcel Number"
                  allTimeValue={formData.parcelOderNo}
                  queryField="parcelOderNo"
                />
              </div>

              <AgencyDashboardInput
                disabled
                type="text"
                iconType="paymentMethod"
                placeHolder="payment Method"
                allTimeValue={formData.paymentMethodName}
                valueUpdate={(e) => e}
              />

              <AgencyDashboardInput
                disabled
                type="text"
                iconType="quantity"
                placeHolder="order Amount"
                allTimeValue={formData.orderAmount}
                valueUpdate={(e) => e}
              />

              <AgencyDashboardInput
                type="textArea"
                iconType="remark"
                placeHolder="description"
                allTimeValue={formData.description}
                valueUpdate={(e) => update({ description: e })}
              />

              {formData?.paymentMethodName !== "Cash" && (
                <AgencyDashboardInput
                  type="text"
                  iconType="remark"
                  placeHolder="payment Invoice Number"
                  allTimeValue={formData.paymentInvoiceNo}
                  valueUpdate={(e) =>
                    update({ paymentInvoiceNo: e })
                  }
                />
              )}
            </div>
            <SubmitBtn text={willUpdate ? "Update" : "Save"} />
            <SubmitBtn
              onClick={() => {
                setWillUpdate(false);
                update(initialValue);
              }}
              styleClass={!willUpdate && "hidden"}
              text="Cancel"
            />
          </form>
        </SetupContainer>
      </Box>

      <Box>
        <SetupContainer>
          <SetupTittle text="history" />
          <div className="text-end mb-6"></div>
          <PaymentHistoryTable // editCallBack={(data) => {
            //   update(data);
            //   setWillUpdate(true);
            // }}
            notEditAble
            fetchedData={fetchedData}
            headers={[
              "payment Date",
              "parcel order number",
              // "record Serial Number",
              // "payment Invoice No",
              "order Amount",
              "description",
              "payment Method Name",
            ]}
            row={[
              "paymentDate",
              "parcelNoWithRecordSerialNo",
              // "recordSerialNo",
              // "",
              "orderAmount",
              "description",
              "paymentMethodName",
            ]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default AgentPayment;

const formateText = (text) => {
    const wordArray = text?.split(" ");
    const formatedWordArray = wordArray?.map((item) => {
      const [firstL, ...rest] = item;
      rest?.unshift(firstL?.toUpperCase());
      return rest?.join("");
    });
    return formatedWordArray?.join(" ") 
  };

  export default formateText
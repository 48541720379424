import React from "react";
import { IoLocationSharp } from "react-icons/io5";
import { IoDocumentsOutline } from "react-icons/io5";
import { BsBox } from "react-icons/bs";
import { BsCreditCardFill } from "react-icons/bs";
import { RiCalendarEventFill } from "react-icons/ri";
import { GiMedicalPack } from "react-icons/gi";
import { FaTruckFast } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { BiSolidInfoCircle } from "react-icons/bi";
import { IoMdWarning } from "react-icons/io";
import { RxTriangleLeft } from "react-icons/rx";

import visaimg from "../../../../../assets/images/booking/visa.png";
import masterimg from "../../../../../assets/images/booking/master.png";
import discoverimg from "../../../../../assets/images/booking/discover.png";
import ameximg from "../../../../../assets/images/booking/amex.png";
import FormWrapper from "../../FormWrapper/FormWrapper";

const CreateShipment = ({ transitCost, fetchedFormData, ...bookingInfo }) => {
  console.log(transitCost)
  const { shipmentArrivalDate } = bookingInfo;

  const { city, country } = fetchedFormData;

  const getCountry = (param) => {
    console.log(param, country);
    const filteredCountry = country?.find(
      (item) => item.id === parseInt(param)
    );
    return filteredCountry?.name;
  };
  const getCity = (param) => {
    const filteredCountry = city?.find((item) => item.id === parseInt(param));
    return filteredCountry?.name;
  };

  // table heading.
  // const Table=["Cargo cost","Dimension cost","Weight cost","Extra packaging cost","Item Type cost","Pickup cost","Route cost","Shipping service cost","Discounted percentage price","After discounted cost","Vat","Total with vat","Total without vat"]
  const tableData = [
    {
      header: "Cargo Cost",
      property: "cargoCost",
    },
    {
      header: "Dimension Cost",
      property: "dimensionCost",
    },
    {
      header: "Weight Cost",
      property: "weightCost",
    },
    {
      header: "ExtraPackaging Cost",
      property: "extraPackagingCost",
    },
    {
      header: "ItemType Cost",
      property: "itemTypeCost",
    },
    {
      header: "PickUp Cost",
      property: "pickUpCost",
    },
    {
      header: "Route Cost",
      property: "routeCost",
    },
    {
      header: "Shipping Service Cost",
      property: "shippingServiceCost",
    },
    {
      header: "Discount Amount",
      property: "disCountedPercentageAmount",
    },
    {
      header: "After Discount Cost",
      property: "afterDiscountCost",
    },
    {
      header: "VAT",
      property: "vatAmount",
    },
    {
      header: "TotalCost With VAT",
      property: "totalCostWithVAT",
    },
    {
      header: "TotalCost Without VAT",
      property: "totalCostWithoutVAT",
    },
  ];

  return (
    <FormWrapper title="Cost Review">
       <div className="">
      <div className="  gap-y-[2px] glass-morphism lg:pl-2 py-4   text-black">
        <div className=" flex justify-center items-center  flex-col lg:flex-row py-2 pl-2">
          <div className="lg:w-1/2 flex flex-col justify-between items-start">
            <div className=" lg:w-1/5 flex items-start gap-x-2 ">
              <IoLocationSharp className="text-gray-400 text-2xl " />{" "}
              <span className="font-bold text-xl">From</span>
            </div>
            <div className="w-max font-medium text-base  pl-9">
              <h1>{bookingInfo?.senderName}</h1>
              <h1>{bookingInfo?.senderAddress}</h1>
              <h1>{getCity(bookingInfo?.senderCity)}</h1>
              <h1>{getCountry(bookingInfo?.senderCountry)}</h1>
              <h1>
                <span className="font-bold text-lg">E-mail:</span>{" "}
                {bookingInfo?.senderEmail}
              </h1>
              <h1>
                <span className="font-bold text-lg">Phone:</span>{" "}
                {bookingInfo?.senderMobileNumber}
              </h1>
            </div>
          </div>
          <div className="lg:w-1/2 flex flex-col justify-between items-start">
            <div className=" lg:w-1/5 flex items-start gap-x-2 ">
              <IoLocationSharp className="text-gray-400 text-2xl " />{" "}
              <span className="font-bold text-xl">To</span>
            </div>
            <div className="w-max font-medium text-base  pl-9">
              <h1>{bookingInfo?.receiverName}</h1>
              <h1>{bookingInfo?.receiverAddress}</h1>
              <h1>{getCity(bookingInfo?.receiverCity)}</h1>
              <h1>{getCountry(bookingInfo?.receiverCountry)}</h1>
              <h1>
                <span className="font-bold text-lg">E-mail:</span>{" "}
                {bookingInfo?.receiverEmail}
              </h1>
              <h1>
                <span className="font-bold text-lg">Phone:</span>{" "}
                {bookingInfo?.receiverMobileNumber}
              </h1>
            </div>
          </div>
        </div>

        {
          //     <div className="flex justify-center items-center py-2 pl-2">
          //     <div className="w-1/2 flex items-start justify-start gap-x-4">
          //         <IoDocumentsOutline className='text-gray-400 text-lg' />
          //         <div className="text-[10px] font-medium ">
          //             <span className=" font-semibold text-xs">Documents</span> Documents - general business <br />
          //             Declared Value {bookingInfo.productValue} BDT
          //         </div>
          //     </div>
          //     <div className='w-1/2 flex justify-between items-center'>
          //         <div className=" flex   w-3/5 items-start justify-start gap-x-1">
          //             <div className=" w-1/5"></div>
          //             <div className="  w-full text-[10px] font-medium ">
          //                 No Shipment Protection
          //             </div>
          //         </div>
          //         <div className=" pr-2">
          //             {/* <button type="button" className=' shadow-md font-medium px-2 py-1 text-xs bg-gray-500 text-gray-50 rounded-sm'>Edit</button> */}
          //         </div>
          //     </div>
          // </div>
        }

        <div className="flex justify-center items-center mt-3 py-2 pl-2">
          <div className="w-1/2 flex items-start justify-start gap-x-4">
            <BsBox className="text-gray-400 text-2xl" />
            <div className="text-base font-medium ">
              {`${bookingInfo?.preset} - (${bookingInfo?.productLength} x ${bookingInfo?.productWidth} x ${bookingInfo?.productHeight} in) - ${bookingInfo?.productWeight}kg `}
            </div>
          </div>
          <div className="w-1/2 flex justify-between items-center">
            <div className=" flex   w-3/5 items-start justify-start gap-x-1">
              <div className=" w-1/5"></div>
              <div className="  w-full text-[10px] font-medium "></div>
            </div>
            <div className=" pr-2">
              {/* <button type='button' className=' shadow-md font-medium px-2 py-1 text-xs bg-gray-500 text-gray-50 rounded-sm'>Edit</button> */}
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center py-2 pl-2">
          {/* <div className="w-1/2 flex items-start justify-start gap-x-4">
            <BsCreditCardFill className="text-gray-400 text-2xl" />
            <div className="text-base font-medium ">
              <span className="font-bold text-base">
                Transportation charges paid by
              </span>{" "}
              {bookingInfo?.paymentType}
            </div>
          </div> */}
          <div className="w-1/2 flex justify-between items-center">
            <div className=" flex   w-3/5 items-start justify-start gap-x-1">
              <div className=" w-1/5"></div>
              <div className="  w-full text-[10px] font-medium "></div>
            </div>
            <div className=" pr-2">
              {/* <button type="button" className=' shadow-md font-medium px-2 py-1 text-xs bg-gray-500 text-gray-50 rounded-sm'>Edit</button> */}
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center py-2 pl-2">
          <div className="w-1/2 flex items-start justify-start gap-x-4">
            <RiCalendarEventFill className="text-gray-400 text-2xl" />
            <div className="text-base font-medium ">
              <span className="font-bold text-base">Shipment Date</span>{" "}
              {`${shipmentArrivalDate?.month} ${shipmentArrivalDate?.date},${shipmentArrivalDate?.day}`}{" "}
              <br />
              {/* <span className='font-bold text-xs'>Shipment Cost</span> 124.91 USD */}
            </div>
          </div>
          <div className="w-1/2 flex justify-between items-center">
            <div className=" flex   w-3/5 items-start justify-start gap-x-1">
              <div className=" w-1/5"></div>
              {/* <div className="  w-full text-[10px] font-medium ">
                                <span className='font-bold text-xs'>Delivery Date</span> Mon, 20 May, 2024 <br />
                                <span className='font-bold text-xs'>Delivered By</span> End of Day
                            </div> */}
            </div>
            <div className=" pr-2">
              {/* <button type='button' className=' shadow-md font-medium px-2 py-1 text-xs bg-gray-500 text-gray-50 rounded-sm'>Edit</button> */}
            </div>
          </div>
        </div>

        {
          //     <div className="flex justify-center items-center py-2 pl-2">
          //     <div className="w-1/2 flex items-start justify-start gap-x-4">
          //         <GiMedicalPack className='text-gray-400 text-lg' />
          //         <div className="text-[10px] font-medium ">
          //             <span className='font-bold text-xs'>Optional Services</span> <br />
          //             GoGreen Plus <br /> Direct Signature
          //         </div>
          //     </div>
          //     <div className='w-1/2 flex justify-between items-center'>
          //         <div className=" flex   w-3/5 items-start justify-start gap-x-1">
          //             <div className=" w-1/5"></div>
          //             <div className="  w-full text-[10px] font-medium ">
          //             </div>
          //         </div>
          //         <div className=" pr-2">
          //             {/* <button type='button' className=' shadow-md font-medium px-2 py-1 text-xs bg-gray-500 text-gray-50 rounded-sm'>Edit</button> */}
          //         </div>
          //     </div>
          // </div>
        }

        {
          //      <div className="flex justify-center items-center py-2 pl-2">
          //      <div className="w-1/2 flex items-start justify-start gap-x-4">
          //          <FaTruckFast className='text-gray-400 text-lg' />
          //          <div className="text-[10px] font-medium ">
          //              <span className='font-bold text-xs'>No Pickup Requested</span>
          //          </div>
          //      </div>
          //      <div className='w-1/2 flex justify-between items-center'>
          //          <div className=" flex   w-3/5 items-start justify-start gap-x-1">
          //              <div className=" w-1/5"></div>
          //              <div className="  w-full text-[10px] font-medium text-gray-400">
          //              </div>
          //          </div>
          //          <div className=" pr-2">
          //              {/* <button type='button' className=' shadow-md font-medium px-2 py-1 text-xs bg-gray-500 text-gray-50 rounded-sm'>Edit</button> */}
          //          </div>
          //      </div>
          //  </div>
        }
      </div>

      <div className="  glass-morphism p-4 mt-4">
        <h1 className="font-bold py-3 text-xl">Shipment Cost Summary</h1>
        <div className="w-full mt-4 flex justify-end items-start">
          <div className=" w-full min-h-32 ">
            {/* <table className='w-max  text-sm'>
                                <tr className=' grid-cols-4  w-full '>
                                    <th className='text-start'>Currency</th>
                                     <th className='text-start'>Cargo Cost</th>
                                    <th className='text-center'>Dimension Cost</th>
                                    <th className='text-center'>Weight Cost</th>
                                    <th className='text-center'>ExtraPackaging Cost</th>
                                    <th className='text-center'>ItemType Cost</th>
                                    <th className='text-center'>PickUp Cost</th>
                                    <th className='text-center'>Route Cost</th>
                                    <th className='text-center'>Shipping Service Cost</th>
                                    <th className='text-center'>Discount Amount</th>
                                    <th className='text-center'>r</th>
                                    <th className='text-center text-red-500'>VAT</th>
                                    <th className='text-center'>TotalCost With VAT</th>
                                    <th className='text-end'>TotalCost Without VAT</th>
                                   
                                  
                                    
                                    <th className='text-end font-medium  py-1'>Discounted <br /> Rate</th>
                                </tr>
                                <tr className='hidden'>
                                    
                                    <td className='text-start  py-1'>{transitCost[0]?.cargoCost}</td>
                                    <td className='text-center py-1'>{transitCost[0]?.dimensionCost}</td>
                                    <td className='text-center py-1'>{transitCost[0]?.weightCost}</td>
                                    <td className='text-center py-1'>{transitCost[0]?.extraPackagingCost}</td>
                                    <td className='text-center py-1'>{transitCost[0]?.itemTypeCost}</td>
                                    <td className='text-center py-1'>{transitCost[0]?.pickUpCost}</td>
                                    <td className='text-center py-1'>{transitCost[0]?.routeCost}</td>
                                    <td className='text-center py-1'>{transitCost[0]?.shippingServiceCost}</td>
                                    <td className='text-center py-1'>{transitCost[0]?.disCountedPercentageAmount}</td>
                                    <td className='text-center py-1'>{transitCost[0]?.afterDiscountCost}</td>
                                    <td className='text-center py-1 text-red-500'>{transitCost[0]?.vatAmount}</td>
                                    <td className='text-center py-1'>{transitCost[0]?.totalCostWithVAT}</td>
                                    <td className='text-end py-1'>{transitCost[0]?.totalCostWithoutVAT}</td>
                                </tr>
                                <tr>
                                    <td className=''></td>
                                    <td className=''></td>
                                    <td className=''></td>
                                    <td className=''></td>
                                    <td className=''></td>
                                    <td className=''></td>
                                    <td className=''></td>
                                    <td className=''></td>
                                    <td className=''></td>
                                    <td className=''></td>
                                    <td className=''></td>
                                    <td className=''></td>
                                    <td className=''></td>
                                  
                                </tr>
                            </table> */}
            <div className={`py-2 bg-gray-100 `}>
              <div className="px-3 flex justify-between items-center">
               
                <span className="font-bold text-lg">Cost Name</span>
                <span className="font-bold text-lg">Amount</span>
              </div>
            </div>

            {tableData?.map((item, idx) => {
              return (
                <div
                  className={`py-2 ${
                    idx % 2 === 0 ? "bg-white" : "bg-gray-100"
                  }`}
                  key={idx}
                >
                  <div className="px-3 flex justify-between items-center">
                    {" "}
                    <span className="">{item.header}</span>{" "}
                    <span className="font-bold">
                      {transitCost[0][item.property]} £
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
      </FormWrapper>
  );
};

export default CreateShipment;


import { BarChart } from '@mui/x-charts/BarChart';
import React from "react";

const BarChartComponent = () => {

    const chartSetting = {
        xAxis: [
          {
            label: 'Cost (£)',
          },
        ],
       
        
      };
      
      const valueFormatter = (value) => `${value}£`;

    const dataset = [
        {
          london: 59,
          paris: 57,
          newYork: 86,
          seoul: 21,
          month: 'Small Applience',
        },
        {
          london: 50,
          paris: 52,
          newYork: 78,
          seoul: 28,
          month: 'A4 Envelope',
        },
        {
          london: 47,
          paris: 53,
          newYork: 106,
          seoul: 41,
          month: 'Moving Box',
        }
        
      ];
      
  return (
    <div className="shadow-xl w-full p-[20px]  h-[280px]  border-[#FE7F29] bg-gradient-to-tr from-[#E6F7F3] to-[#FAEBE9] rounded-[23px]">
      <BarChart
      margin={{left:100}}
        dataset={dataset}
        yAxis={[{ scaleType: "band", dataKey: "month" }]}
        series={[{ dataKey: "seoul", label: "Parcel Cost",color:"#FFA76A", valueFormatter }]}
        layout="horizontal"
        grid={{ vertical: true }}
        {...chartSetting}
      />
    </div>
  );
};

export default BarChartComponent;

import React, { useContext, useEffect, useState } from "react";
import SetupTittle from "../SharedComponents/SetupTittle";
import SetupContainer from "../SharedComponents/SetupContainer";
import SetupInput from "../SharedComponents/SetupInput";
import SetupRadio from "../SharedComponents/SetupRadio";
import SubmitBtn from "../SharedComponents/SubmitBtn";
import Box from "../SharedComponents/Box";
import SetupDropDown from "../SharedComponents/SetupDropdown";
import SetupAutoComplete from "../SharedComponents/SetupAutoComplete";
import axios from "axios";
import HistoryTable from "../SharedComponents/HistoryTable";
import Swal from "sweetalert2";
import GetLocalStorageUserId from "../../sharedComponents/GetLocalStorageuserId";
import { contextProvider } from "../SetupPagesContextApi/SetupPagesContextApi";

const Agent = () => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const [fetchedData, setFetchedData] = useState([]);
  console.log(fetchedData);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(`${backendUrl}Agent`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // get local storage data.
  const localdata = localStorage.getItem("sundorbonBookingUserDetails");
  const localStorageObj = JSON.parse(localdata);

  // input fields handle.
  const initialValue = {
    companyId: 0,
    id: 0,
    name: "",
    userId: GetLocalStorageUserId(),
    email: "",
    mobileNo: "",
    tin: "",
    bin: "",
    bankAccountInfo: "",
    comissionPercentage: "",
    fixedCommisionAmount: "",
    isActive: "",
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    branchName: "string",
    branchId: "",
    subBranchId: "",
    password: "",
    subBranchName: "string",
  };

  const [formData, setFormData] = useState(initialValue);
  const [willUpdate, setWillUpdate] = useState(false);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };
  const { handleDataReload } = useContext(contextProvider);
  const formHandle = (e) => {
    e.preventDefault();

    console.log(formData);
    if (!willUpdate) {
      axios
        .post(`${backendUrl}Agent`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            Swal.fire({
              title: `Agent Created Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          } else if (res.status === 200) {
            Swal.fire({
              title: res.data,
              icon: "error",
            });
          }
        })
        .catch((err) => console.log(err.message));
    }

    if (willUpdate) {
      axios
        .put(`${backendUrl}Agent/${formData.userId}`, { ...formData })

        .then((res) => {
          console.log(res);
          if (res.status === 204) {
            Swal.fire({
              title: `Agent Updated Successfully`,
              icon: "success",
            });
            setRefetch((prev) => !prev);
            setFormData(initialValue);
            setWillUpdate(false);
            handleDataReload();
          }
        })
        .catch((err) => console.log(err.message));
    }
  };
  console.log(formData);
  return (
    <div>
      <Box top>
        <SetupTittle text="Agent" />
        <SetupContainer>
          <form className="" onSubmit={formHandle}>
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              <SetupInput
                name="agentName"
                type="text"
                placeHolder="Agent name"
                allTimeValue={formData.name}
                valueUpdate={(e) => update({ name: e.target.value })}
              />
              <SetupInput
                name="address"
                type="text"
                placeHolder="Address"
                allTimeValue={formData.address}
                valueUpdate={(e) => update({ address: e.target.value })}
              />
              <SetupInput
                name="email"
                type="email"
                placeHolder="E-mail"
                allTimeValue={formData.email}
                valueUpdate={(e) => update({ email: e.target.value })}
              />
              <SetupInput
                name="mobileNumber"
                type="number"
                placeHolder="Mobile number"
                allTimeValue={formData.mobileNo}
                valueUpdate={(e) => update({ mobileNo: e.target.value })}
              />
              {!willUpdate && (
                <SetupInput
                  name="password"
                  type="password"
                  placeHolder="password"
                  allTimeValue={formData.password}
                  valueUpdate={(e) => update({ password: e.target.value })}
                />
              )}
              <SetupInput
                name="tin"
                type="text"
                placeHolder="Tin"
                allTimeValue={formData.tin}
                valueUpdate={(e) => update({ tin: e.target.value })}
              />
              <SetupInput
                name="bin"
                type="text"
                placeHolder="Bin"
                allTimeValue={formData.bin}
                valueUpdate={(e) => update({ bin: e.target.value })}
              />
              <SetupInput
                name="bankAccountInfo"
                type="text"
                placeHolder="Bank account info"
                allTimeValue={formData.bankAccountInfo}
                valueUpdate={(e) => update({ bankAccountInfo: e.target.value })}
              />
              <SetupInput
                name="comissionPercentage"
                type="number"
                placeHolder="Comission percentage"
                allTimeValue={formData.comissionPercentage}
                valueUpdate={(e) =>
                  update({ comissionPercentage: e.target.value })
                }
              />
              <SetupInput
                name="fixedCommissionAmount"
                type="number"
                placeHolder="Fixed Commission amount"
                allTimeValue={formData.fixedCommisionAmount}
                valueUpdate={(e) =>
                  update({ fixedCommisionAmount: e.target.value })
                }
              />
              {/* <SetupDropDown
                dataOf="company"
                allTimeValue={formData.companyId}
                valueUpdate={(e) => update({ companyId: e.target.value })}
                placeHolder="Company name"
                name="companyId"
              ></SetupDropDown> */}
              <SetupDropDown // need to change
                dataOf="branch"
                allTimeValue={formData.branchId}
                valueUpdate={(e) => update({ branchId: e.target.value })}
                placeHolder="Branch"
                name="branchId"
              ></SetupDropDown>
              <SetupAutoComplete
                valueUpdate={(_, value) => {
                  update({ subBranchId: value?.id });
                }}
                placeHolder="Sub-Branch"
                dataOf="subBranch"
                allTimeValue={formData?.subBranchId}
                queryField="id"
              />
              <SetupRadio
                name="isActive"
                placeHolder="Agent status?"
                allTimeValue={formData.isActive}
                valueUpdate={(data) => update({ isActive: data })}
              />
            </div>
            <SubmitBtn text={willUpdate ? "Update" : "Create"} />
            <SubmitBtn
              onClick={() => {
                setWillUpdate(false);
                update(initialValue);
              }}
              styleClass={!willUpdate && "hidden"}
              text="Cancel"
            />
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            clearActiveRow={!willUpdate ? true : false}
            editCallBack={(data) => {
              console.log(data, "This is UpdatedDataaaaaaaaaaaaaaaaaaaaaaa.");
              update(data);
              setWillUpdate(true);
            }}
            fetchedData={fetchedData}
            headers={[
              "Name",
              "Address",
              "E-mail",
              "Mobile number",
              "Tin",
              "Bin",
              "Bank account info",
              "Commission percentage",
              "Fixed Commission amount",
              "Company Name",
              "Branch",
              "Sub-Branch",
              "Status",
            ]}
            row={[
              "name",
              "address",
              "email",
              "mobileNo",
              "tin",
              "bin",
              "bankAccountInfo",
              "comissionPercentage",
              "fixedCommisionAmount",
              "companyId",
              "branchName",
              "subBranchName",
              "isActive",
            ]}
            extraFields={["branchId", "subBranchId", "userId"]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default Agent;

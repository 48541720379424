import React, { useContext, useEffect, useState } from "react";
import Box from "../../../setupPage/SharedComponents/Box";
import SetupTittle from "../../../setupPage/SharedComponents/SetupTittle";
import SetupContainer from "../../../setupPage/SharedComponents/SetupContainer";
import HistoryTable from "../../../setupPage/SharedComponents/HistoryTable";
import SetupAutoComplete from "../../../setupPage/SharedComponents/SetupAutoComplete";
import axios from "axios";
import SubmitBtn from "../../../setupPage/SharedComponents/SubmitBtn";
import Swal from "sweetalert2";
import SetupInput from "../../../setupPage/SharedComponents/SetupInput";
import { contextProvider } from "../../../setupPage/SetupPagesContextApi/SetupPagesContextApi";
import SetupRadio from "../../../setupPage/SharedComponents/SetupRadio";
import GetLocalStorageUserId from "../../../sharedComponents/GetLocalStorageuserId";
import { toast, ToastContainer } from "react-toastify";
import "./parcelStatusScan.css"
const ParcelStatusScan = () => {
  const backendUrl = process.env.REACT_APP_API_URL;

  const [fetchedData, setFetchedData] = useState(null);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    axios
      .get(`${backendUrl}BarcodeScan`)
      .then((res) => setFetchedData(res.data))
      .catch((err) => console.log(err.message));
  }, [backendUrl, refetch]);

  // input fields handle.
  const initialValue = {
    id: 1,
    parcelNo: "",
    barcodeNo: "",
    isActive: true,
    creatorId: GetLocalStorageUserId(),
    creationDate: new Date(),
    modifierId: GetLocalStorageUserId(),
    modificationDate: new Date(),
    barcodeScanId: 0,
    scanningPointId: "",
    scanningPersonId: "",
    parcelStatusId: "",
    scanningPointName: "",
    parcelStatusName: "",
    scanningPersonName: "",
  };

  const [formData, setFormData] = useState(initialValue);

  const update = (data) => {
    setFormData({ ...formData, ...data });
  };

  const [scanningPointAndPersonName, setScanningPointAndPersonName] =
    useState(null);
  // get local storage data.
  const localdata = localStorage.getItem("sundorbonBookingUserDetails");
  const localStorageObj = JSON.parse(localdata);

  const { scanningPoint, scanningPerson } = useContext(contextProvider);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${backendUrl}ScanningPoint/GetScanningPointByUserId/${
  //         localStorageObj?.Id || ""
  //       }`
  //     )

  //     .then((res) => {
  //       const userName = res.data?.userName;
  //       const scanningPointName = res.data?.scanningPointName;
  //       const scanningPointId = scanningPoint.find(
  //         (item) => item.scanningPointName === scanningPointName
  //       )?.id;
  //       const scanningPersonId = scanningPerson.find(
  //         (item) => item.userName === userName
  //       )?.userId;
  //       update({
  //         scanningPointId: scanningPointId,
  //         scanningPersonId: scanningPersonId,
  //       });

  //       setScanningPointAndPersonName({ userName, scanningPointName });
  //     })
  //     .catch((err) => console.log(err.message));
  // }, [scanningPoint, scanningPerson, refetch]);

  useEffect(() => {
    axios
      .get(
        `${backendUrl}ScanningPoint/GetScanningPointByUserId/${
          localStorageObj?.Id || ""
        }`
      )

      .then((res) => {
        const data = res.data;

        update({
          scanningPointName: data?.scanningPointName,
          parcelStatusName: data?.parcelStatusName,
          scanningPersonName: data?.userName,
          scanningPointId: data?.id,
          scanningPersonId: data?.userId,
          parcelStatusId: data?.parcelStatusId,
        });
      })
      .catch((err) => console.log(err.message));
  }, [refetch]);

  // form submithandle.

  const formHandle = (e) => {
    // e.preventDefault();
    
    console.log(formData);
    axios
      .post(`${backendUrl}BarcodeScan`, { ...formData })

      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          toast.success("Parcel Status Scan Created Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setFormData(initialValue);
          setRefetch((prev) => !prev);
        }
      })
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    if (formData.barcodeNo && formData.parcelNo) {
      formHandle();
    }
  }, [formData]);

  // handle barcode number.
  const handleBarcodeNumber = (number) => {
    update({ barcodeNo: number });
    console.log(number.length);
    if (number.length === 16) {
      axios
        .get(`${backendUrl}Parcel/CheckParcelBarcode/${number}`)
        .then((res) => {
          console.log(res.data,"ths is responssssssssssssssssse");
          if (res.data.barcodeStatus === "Barcode Matched.") {
            update({ barcodeNo: number, parcelNo: res.data?.parcelNo });
          }
          if (res.data.barcodeStatus === "Barcode already Scanned") {
            toast.warning("Barcode Already Scanned.", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          if (res.data.barcodeStatus === "Barcode does not exist") {
            toast.error("Barcode Doesn't Match.", {
              className:"customToast",
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          
        }).catch((err) => console.log(err.message));
    }
  };
  

  return (
    <div>
      <ToastContainer />
      <Box top>
        <SetupTittle text="Parcel Status Scan" />
        <SetupContainer>
          <form onSubmit={formHandle} className="">
            <div className="grid lg:grid-cols-2 gap-y-4 grid-cols-1 lg:gap-y-[35px] lg:gap-x-[125px]">
              {/* <SetupAutoComplete
            valueUpdate={(_, value) => {
              if (!value) return update({ parcelOrderId: "" });
              if (value) update({ parcelOrderId: value.id });
            }}
              placeHolder="Parcel Order Id"
              dataOf="parcelOrderNo"
              allTimeValue={formData.parcelOrderId}
            /> */}

              <SetupInput
                type="text"
                placeHolder="parcel barcode number"
                name="barcodeNumber"
                allTimeValue={formData?.barcodeNo}
                valueUpdate={(e) => handleBarcodeNumber(e.target.value)}
              />

              <SetupInput
                disabled
                type="text"
                placeHolder="Scanning Point Name"
                name="ScanningPointName"
                allTimeValue={formData?.scanningPointName}
                // valueUpdate={(e) => update({ cost: parseInt(e.target.value) })}
              />

              <SetupInput
                disabled
                type="text"
                placeHolder="Scanning Person Name"
                name="ScanningPersonName"
                allTimeValue={formData?.scanningPersonName}
                // valueUpdate={(e) => update({ cost: parseInt(e.target.value) })}
              />

              {/* <SetupAutoComplete
                valueUpdate={(_, value) => {
                  if (!value) return update({ scanningPointId: "" });
                  if (value) update({ scanningPointId: value.id });
                }}
                dataOf="scanningPoint"
                placeHolder="Scanning Point Name"
                allTimeValue={formData.scanningPointId}
              />
              <SetupAutoComplete
                valueUpdate={(_, value) => {
                  if (!value) return update({ scanningPersonId: "" });
                  if (value) update({ scanningPersonId: value.id });
                }}
                dataOf="scanningPerson"
                placeHolder="Scanning Person Name"
                allTimeValue={formData.scanningPersonId}
              /> */}

              <SetupInput
                disabled
                type="text"
                placeHolder="Parcel Status Name"
                name="parcelStatusName"
                allTimeValue={formData?.parcelStatusName}
                // valueUpdate={(e) => update({ cost: parseInt(e.target.value) })}
              />

              {/* <SetupAutoComplete
                valueUpdate={(_, value) => {
                  if (!value) return update({ parcelStatusId: "" });
                  if (value) update({ parcelStatusId: value.id });
                }}
                dataOf="parcelStatus"
                placeHolder="Parcel Status Name"
                allTimeValue={formData.parcelStatusId}
              /> */}

              {/* <SetupAutoComplete
                valueUpdate={(_, value) => {
                  if (value) {
                    axios
                      .get(`${backendUrl}Parcel/GetParcelInfoById/${value}`)

                      .then((res) => {
                        
                        update({barcodeNo:res.data?.barcode,parcelNo:value})
                      })
                      .catch((err) => console.log(err.message));
                  }
                }}
                dataOf="OnlyParcelNumber"
                placeHolder="Parcel Number"
                allTimeValue={formData.parcelStatusId}
              /> */}
              {/* <SetupRadio
                name="isActive"
                placeHolder="Parcel Status Scan?"
                allTimeValue={formData.isActive}
                valueUpdate={(data) => update({ isActive: data })}
              /> */}
            </div>

            {/* <SubmitBtn text="save" /> */}
          </form>
        </SetupContainer>
      </Box>
      <Box>
        <SetupTittle text="History" />
        <SetupContainer>
          <HistoryTable
            notEditAble
            // clearActiveRow={!willUpdate ? true : false}
            // editCallBack={(data) => {
            //   console.log(data)
            //   update(data);
            //   setWillUpdate(true);
            // }}
            fetchedData={fetchedData}
            headers={[
              "parcel No",
              "parcel Status name",
              "scanning Person name",
              "scanning Point name",
              "barcode No",
              "Status",
            ]}
            row={[
              "parcelNo",
              "parcelStatusName",
              "scanningPersonName",
              "scanningPointName",
              "barcodeNo",
              "isActive",
            ]}
          />
        </SetupContainer>
      </Box>
    </div>
  );
};

export default ParcelStatusScan;

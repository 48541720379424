import React from "react";
import logo from "../../../../assets/images/sundorban.png";
import { Link, NavLink } from "react-router-dom";
import "./asideNav.css";
import { LuLayoutDashboard } from "react-icons/lu";
import { IoTicket } from "react-icons/io5";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { MdOutlineCallReceived } from "react-icons/md";
import { LuPrinter } from "react-icons/lu";
import { RiLockPasswordLine } from "react-icons/ri";
import { MdOutlinePayments } from "react-icons/md";
import { LuScanLine } from "react-icons/lu";
const AsideNav = () => {
  // get local storage data.
  const localdata = localStorage.getItem("sundorbonBookingUserDetails");
  const Name = JSON.parse(localdata)?.Name || "";
  const Email = JSON.parse(localdata)?.Email || "";

  return (
    <div className="lg:w-[16%] z-30 lg:flex flex-col justify-start items-start lg:gap-12 lg:pb-11 overflow-hidden pt-11 w-full lg:h-[93vh] lg:rounded-[25px] sticky top-0 lg:top-[3%] left-5 bg-black text-white lg:bg-[#424242]">
      {/* top image and profile text info. */}
      <div className="relative hidden lg:w-full lg:flex justify-center items-center flex-row lg:flex-col">
        <img
          className="xl:w-[142px] lg:w-[100px] lg:inline-block hidden xl:h-[142px] lg:h-[100px] rounded-full"
          src={logo}
          alt="logo"
        />
        <div className="lg:mt-[54px] text-center hidden lg:block">
          <h1 className="text-xl font-semibold ">{Name}</h1>
          <h1 className="text-base font-semibold mt-1">{Email}</h1>
        </div>
      </div>
      {/* aside nav bar */}
      <ul className="lg:text-white lg:font-bold lg:w-full  overflow-y-auto   lg:text-[20px] lg:px-4 lg:flex items-end lg:items-center  justify-start lg:justify-start lg:flex-col  lg:gap-y-1">
        <NavLink
          end
          className="flex lg:w-full items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
          to={"/agent-dashboard"}
        >
          <LuLayoutDashboard />
          Dashboard
        </NavLink>

        <NavLink
          className="flex lg:w-full items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
          to={"requisition"}
        >
          <MdOutlineAddCircleOutline />
          Requisition
        </NavLink>

        <NavLink
          className="flex lg:w-full items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
          to={"receive"}
        >
          <MdOutlineCallReceived />
          Receive
        </NavLink>


        <NavLink
          className="flex lg:w-full items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
          to={"payment"}
        >
          <MdOutlinePayments />
          Payment
        </NavLink>


        <NavLink
          className="flex lg:w-full items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
          to={"parcel-status-scan"}
        >
          <LuScanLine />
          Parcel Status Scan
        </NavLink>





        {/* <NavLink
          className="flex lg:w-full items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
          to={"cash-payment"}
        >
          <MdOutlinePayments />
          Cash Payment
        </NavLink> */}

        {/* <NavLink
          className="flex lg:w-full items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
          to={"change-password"}
        >
          <RiLockPasswordLine />
          Change Password
        </NavLink> */} 

        <NavLink
          className="flex lg:w-full items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
          to={"print-barcode"}
        >
          <LuPrinter />
          Print Barcode
        </NavLink>

        <Link
          className="flex lg:w-full items-center hover:bg-[#747474] duration-100 hover:rounded-xl lg:py-3 pl-3 gap-3"
          to={"/agency-bookings"}
        >
          <IoTicket />
          Add Booking
        </Link>
      </ul>
    </div>
  );
};

export default AsideNav;

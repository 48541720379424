import React from 'react';
import { ReactBarcode } from 'react-jsbarcode';


const Barcode = ({bookingBarcodeNumber}) => {
   
    return (
        <div className=' flex justify-center items-center'>
             <ReactBarcode value={bookingBarcodeNumber} />
        </div>
    );
};

export default Barcode;